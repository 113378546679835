export const FETCH_GET_SHOPS_REQUEST = "FETCH_GET_SHOPS_REQUEST";
export const FETCH_GET_SHOPS_FAILURE = "FETCH_GET_SHOPS_FAILURE";
export const FETCH_GET_SHOPS_SUCCESS = "FETCH_GET_SHOPS_SUCCESS";

export const FETCH_REMOVE_SHOPS_REQUEST = "FETCH_REMOVE_SHOPS_REQUEST";
export const FETCH_REMOVE_SHOPS_FAILURE = "FETCH_REMOVE_SHOPS_FAILURE";
export const FETCH_REMOVE_SHOPS_SUCCESS = "FETCH_REMOVE_SHOPS_SUCCESS";

export const FETCH_CREATE_SHOP_REQUEST = "FETCH_CREATE_SHOP_REQUEST";
export const FETCH_CREATE_SHOP_FAILURE = "FETCH_CREATE_SHOP_FAILURE";
export const FETCH_CREATE_SHOP_SUCCESS = "FETCH_CREATE_SHOP_SUCCESS";

export const FETCH_UPDATE_SHOP_REQUEST = "FETCH_UPDATE_SHOP_REQUEST";
export const FETCH_UPDATE_SHOP_FAILURE = "FETCH_UPDATE_SHOP_FAILURE";
export const FETCH_UPDATE_SHOP_SUCCESS = "FETCH_UPDATE_SHOP_SUCCESS";
