import {
  FETCH_GET_CATEGORIES_REQUEST,
  FETCH_GET_CATEGORIES_FAILURE,
  FETCH_GET_CATEGORIES_SUCCESS,
  FETCH_REMOVE_CATEGORIES_REQUEST,
  FETCH_REMOVE_CATEGORIES_FAILURE,
  FETCH_REMOVE_CATEGORIES_SUCCESS,
  FETCH_CREATE_CATEGORY_REQUEST,
  FETCH_CREATE_CATEGORY_FAILURE,
  FETCH_CREATE_CATEGORY_SUCCESS,
  FETCH_UPDATE_CATEGORY_REQUEST,
  FETCH_UPDATE_CATEGORY_FAILURE,
  FETCH_UPDATE_CATEGORY_SUCCESS,
} from "./types";

export const getCategoriesRequest = () => {
  return { type: FETCH_GET_CATEGORIES_REQUEST };
};
export const removeCategoriesRequest = () => {
  return { type: FETCH_REMOVE_CATEGORIES_REQUEST };
};
export const createCategoryRequest = () => {
  return { type: FETCH_CREATE_CATEGORY_REQUEST };
};
export const updateCategoryRequest = () => {
  return { type: FETCH_UPDATE_CATEGORY_REQUEST };
};

export const getCategoriesFailure = error => {
  return { type: FETCH_GET_CATEGORIES_FAILURE, payload: error };
};
export const removeCategoriesFailure = error => {
  return { type: FETCH_REMOVE_CATEGORIES_FAILURE, payload: error };
};
export const createCategoryFailure = error => {
  return { type: FETCH_CREATE_CATEGORY_FAILURE, payload: error };
};
export const updateCategoryFailure = error => {
  return { type: FETCH_UPDATE_CATEGORY_FAILURE, payload: error };
};

export const getCategoriesSuccess = categoriesData => {
  return { type: FETCH_GET_CATEGORIES_SUCCESS, payload: categoriesData };
};
export const removeCategoriesSuccess = () => {
  return { type: FETCH_REMOVE_CATEGORIES_SUCCESS };
};
export const createCategorySuccess = () => {
  return { type: FETCH_CREATE_CATEGORY_SUCCESS };
};
export const updateCategorySuccess = () => {
  return { type: FETCH_UPDATE_CATEGORY_SUCCESS };
};
