import {
  getProductsRequest,
  removeProductsRequest,
  createProductRequest,
  updateProductRequest,
  getProductsFailure,
  removeProductsFailure,
  createProductFailure,
  updateProductFailure,
  getProductsSuccess,
  removeProductsSuccess,
  createProductSuccess,
  updateProductSuccess,
} from "./actionCreators";

const getProducts = api => filter => dispatch => {
  dispatch(getProductsRequest());
  api
    .getProducts(filter)
    .then(res => {
      console.log(res, "res getProducts");
      dispatch(getProductsSuccess(res.data));
    })
    .catch(err => {
      console.log(err, "err getProducts");
      dispatch(
        getProductsFailure({
          status: "error",
          text: `Возникла ошибка с кодом ${
            err.response ? err.response.status : "???"
          } `,
        }),
      );
    });
};

const createProduct = api => (productData, filter) => dispatch => {
  dispatch(createProductRequest());

  api
    .createProduct(productData)
    .then(res => {
      console.log(res, "res createProduct");
      dispatch(createProductSuccess());

      getProducts(api)(filter)(dispatch);
    })
    .catch(err => {
      console.log(err, "err createProduct");
      dispatch(
        createProductFailure({
          status: "error",
          text: `Возникла ошибка с кодом ${
            err.response ? err.response.status : "???"
          } `,
        }),
      );
    });
};

const updateProduct = api => (productData, filter) => dispatch => {
  dispatch(updateProductRequest());

  api
    .updateProduct(productData)
    .then(res => {
      console.log(res, "res updateProduct");
      dispatch(updateProductSuccess());

      getProducts(api)(filter)(dispatch);
    })
    .catch(err => {
      console.log(err, "err updateProduct");
      dispatch(
        updateProductFailure({
          status: "error",
          text: `Возникла ошибка с кодом ${
            err.response ? err.response.status : "???"
          } `,
        }),
      );
    });
};

const removeProducts = api => (products = [], filter) => dispatch => {
  dispatch(removeProductsRequest());
  api
    .removeProducts(products.map(product => product.id))
    .then(res => {
      console.log(res, "res removeProducts");
      dispatch(removeProductsSuccess());

      getProducts(api)(filter)(dispatch);
    })
    .catch(err => {
      console.log(err, "err removeProducts");
      dispatch(
        removeProductsFailure({
          status: "error",
          text: `Возникла ошибка с кодом ${
            err.response ? err.response.status : "???"
          } `,
        }),
      );
    });
};

export { getProducts, createProduct, updateProduct, removeProducts };
