import {
  getComplaintsRequest,
  updateComplaintRequest,
  getComplaintsFailure,
  updateComplaintFailure,
  getComplaintsSuccess,
  updateComplaintSuccess,
  massUpdateComplaintsRequest,
  massUpdateComplaintsSuccess,
  massUpdateComplaintsFailure,
} from "./actionCreators";

const getComplaints = api => filter => dispatch => {
  dispatch(getComplaintsRequest());
  api
    .getComplaints(filter)
    .then(res => {
      console.log(res, "res getComplaints");
      dispatch(getComplaintsSuccess(res.data));
    })
    .catch(err => {
      console.log(err, "res getComplaints");
      dispatch(
        getComplaintsFailure({
          status: "error",
          text: `Возникла ошибка с кодом ${
            err.response ? err.response.status : "???"
          } `,
        }),
      );
    });
};

const updateComplaint = api => (complaintData, filter) => dispatch => {
  dispatch(updateComplaintRequest());

  api
    .updateComplaint(complaintData)
    .then(res => {
      console.log(res, "res updateComplaint");
      dispatch(updateComplaintSuccess());

      getComplaints(api)(filter)(dispatch);
    })
    .catch(err => {
      console.log(err, "err updateComplaint");
      dispatch(
        updateComplaintFailure({
          status: "error",
          text: `Возникла ошибка с кодом ${
            err.response ? err.response.status : "???"
          } `,
        }),
      );
    });
};

const massUpdateComplaints = api => (
  complaints = [],
  status,
  filter,
) => dispatch => {
  dispatch(massUpdateComplaintsRequest());
  api
    .massUpdateComplaints({
      complaintsIds: complaints.map(complaint => complaint.id),
      status,
    })
    .then(res => {
      console.log(res, "res massUpdateComplaints");
      dispatch(massUpdateComplaintsSuccess());

      getComplaints(api)(filter)(dispatch);
    })
    .catch(err => {
      console.log(err, "err massUpdateComplaints");
      dispatch(
        massUpdateComplaintsFailure({
          status: "error",
          text: `Возникла ошибка с кодом ${
            err.response ? err.response.status : "???"
          } `,
        }),
      );
    });
};

export { getComplaints, updateComplaint, massUpdateComplaints };
