import React, { useState, useEffect } from "react";
import "./SearchInput.scss";
import { ReactComponent as SearchIcon } from "../../../assets/images/searchIcon.svg";
import { isArray } from "util";

const SearchInput = ({
  languageCode = "en",
  label,
  placeholder,
  onSelect,
  selectedValue = {},
  searchResults = [],
  searchRequest,
  requestStartSumbolIndex = 3,
}) => {
  let formattedSelectedValue = selectedValue ? selectedValue : {};

  const [searchValue, setSearchValue] = useState(
    formattedSelectedValue.name ? formattedSelectedValue.name : "",
  );

  const getTranslatedName = (name, languageCode) => {
    let translatedName = name;
    if (isArray(name) && name.length > 0) {
      let findedName = name.find(item => item.languageCode === languageCode);
      translatedName = findedName && findedName.data ? findedName.data : "";
    } else {
      translatedName = name || "";
    }

    return translatedName;
  };

  const formatAndSortSearchResults = (searchResults = [], languageCode) => {
    let formattedSearchResults = searchResults.map(item => {
      return {
        ...item,
        translatedName:
          getTranslatedName(item.name, languageCode) || item.username,
      };
    });
    formattedSearchResults.sort((a, b) => {
      if (a.translatedName > b.translatedName) {
        return 1;
      }
      if (a.translatedName < b.translatedName) {
        return -1;
      }
      return 0;
    });

    return formattedSearchResults;
  };

  useEffect(() => {
    console.log(searchValue, 'searchValue')
    if (formattedSelectedValue.name === "null") {
      onSelect({});
      setSearchValue("");
    } else {
      setSearchValue(
        getTranslatedName(formattedSelectedValue.name, languageCode) ||
          formattedSelectedValue.username,
      );
    }
  }, [selectedValue]);

  useEffect(() => {
    setSearchValue(
      getTranslatedName(formattedSelectedValue.name, languageCode) ||
        formattedSelectedValue.username,
    );
  }, [languageCode]);

  return (
    <div
      tabIndex={"1"}
      onBlur={() => {
        // Костыль что бы очистить список
        searchRequest("awdawdawdawldawdawdawdawdawdawdawd");
      }}
      className='SearchInput'
    >
      <div
        className='inputBlock'
        onClick={() => {
          if (!searchValue) {
            searchRequest("");
          }
        }}
      >
        <input
          value={searchValue !== undefined ? searchValue : ''}
          placeholder={placeholder}
          onChange={e => {
            if (e.target.value.length >= requestStartSumbolIndex) {
              searchRequest(e.target.value);
            } else if (!e.target.value) {
              // Если юзер очистил список показываем ему все варианты
              setSearchValue("");
            }

            setSearchValue(e.target.value);
          }}
        />
        <SearchIcon
          width='20px'
          height='20px'
          onClick={() => {
            searchRequest("");
          }}
        />
      </div>
      <ul
        style={
          searchResults.length > 0 ? { display: "block" } : { display: "none" }
        }
      >
        {formatAndSortSearchResults(searchResults, languageCode).map(item => {
          return (
            <li
              key={"searchUserList" + item.id}
              onMouseDown={() => {
                onSelect(item);
                setSearchValue("");
                // Костыль что бы очистить список
                searchRequest("awdawdawdawldawdawdawdawdawdawdawd");
              }}
            >
              {item.translatedName}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SearchInput;
