import React from "react";

import "./Form.scss";

const Form = ({ onSubmit = () => {}, title = "", width = 300, children }) => {
  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        onSubmit();
      }}
      style={{ minWidth: width }}
      className='Form'
    >
      {title ? <div className='title'>{title}</div> : ""}
      {children}
    </form>
  );
};
export default Form;
