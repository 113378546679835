import {
  getArticlesRequest,
  removeArticlesRequest,
  createArticleRequest,
  updateArticleRequest,
  getArticlesFailure,
  removeArticlesFailure,
  createArticleFailure,
  updateArticleFailure,
  getArticlesSuccess,
  removeArticlesSuccess,
  createArticleSuccess,
  updateArticleSuccess,
  massUpdateArticlesRequest,
  massUpdateArticlesFailure,
  massUpdateArticlesSuccess,
} from "./actionCreators";

const getArticles = api => filter => dispatch => {
  dispatch(getArticlesRequest());
  api
    .getArticles(filter)
    .then(res => {
      console.log(res, "res getArticles");
      dispatch(getArticlesSuccess(res.data));
    })
    .catch(err => {
      console.log(err, "res getArticles");
      dispatch(
        getArticlesFailure({
          status: "error",
          text: `Возникла ошибка с кодом ${
            err.response ? err.response.status : "???"
          } `,
        }),
      );
    });
};

const createArticle = api => (articleData, filter) => dispatch => {
  dispatch(createArticleRequest());

  api
    .createArticle(articleData)
    .then(res => {
      console.log(res, "res createArticle");
      dispatch(createArticleSuccess());

      getArticles(api)(filter)(dispatch);
    })
    .catch(err => {
      console.log(err, "err createArticle");
      dispatch(
        createArticleFailure({
          status: "error",
          text: `Возникла ошибка с кодом ${
            err.response ? err.response.status : "???"
          } `,
        }),
      );
    });
};

const updateArticle = api => (articleData, filter) => dispatch => {
  dispatch(updateArticleRequest());

  api
    .updateArticle(articleData)
    .then(res => {
      console.log(res, "res updateArticle");
      dispatch(updateArticleSuccess());

      getArticles(api)(filter)(dispatch);
    })
    .catch(err => {
      console.log(err, "err updateArticle");
      dispatch(
        updateArticleFailure({
          status: "error",
          text: `Возникла ошибка с кодом ${
            err.response ? err.response.status : "???"
          } `,
        }),
      );
    });
};

const removeArticles = api => (articles = [], filter) => dispatch => {
  dispatch(removeArticlesRequest());
  api
    .removeArticles(articles.map(article => article.id))
    .then(res => {
      console.log(res, "res removeArticles");
      dispatch(removeArticlesSuccess());

      getArticles(api)(filter)(dispatch);
    })
    .catch(err => {
      console.log(err, "err removeArticles");
      dispatch(
        removeArticlesFailure({
          status: "error",
          text: `Возникла ошибка с кодом ${
            err.response ? err.response.status : "???"
          } `,
        }),
      );
    });
};

const massUpdateArticles = api => (
  articles = [],
  status,
  filter,
) => dispatch => {
  dispatch(massUpdateArticlesRequest());
  api
    .massUpdateArticlesStatus({
      articlesIds: articles.map(article => article.id),
      status,
    })
    .then(res => {
      console.log(res, "res massUpdateArticlesStatus");
      dispatch(massUpdateArticlesSuccess());

      getArticles(api)(filter)(dispatch);
    })
    .catch(err => {
      console.log(err, "err massUpdateArticlesStatus");
      dispatch(
        massUpdateArticlesFailure({
          status: "error",
          text: `Возникла ошибка с кодом ${
            err.response ? err.response.status : "???"
          } `,
        }),
      );
    });
};

export {
  getArticles,
  createArticle,
  updateArticle,
  removeArticles,
  massUpdateArticles,
};
