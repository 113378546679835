import React, { useState } from "react";

import "./EditableTable.scss";
import EditableTableCell from "../EditableTableCell";
import { Button } from "../../FormItems";
const EditableTable = ({
  tableDataset = [[""]],
  onChange = () => {},
  tableWidth = "100%",
  maxHeight = "75%",

  withBorders = true,
  columns = [],
  showOnAddRow = true,
  showOnAddCol = true,
  showOnRemoveRow = true,
  showOnRemoveCol = true,
  showOnRevChanges = true,
}) => {
  const onChangeCell = (editedRow, editedCol, newValue) => {
    const editedTableDataset = [...tableDataset];
    editedTableDataset[editedRow][editedCol] = newValue;
    onChange(editedTableDataset);
  };

  const [lastRemovedValues, setLastRemovedValues] = useState({});

  const [highlightLastRow, setHighlightLastRow] = useState(false);
  const [highlightLastCol, setHighlightLastCol] = useState(false);

  const handleAddRow = (tableDataset = [[""]], newCellsContent = []) => {
    const newTableDataset = [...tableDataset];
    const newCells = [];

    // Подготавливаем новые пустые ячейки
    for (let i = 0; i < newTableDataset[0].length; i++) {
      newCells.push(newCellsContent[i] || "");
    }
    // Добавляем новую строку
    newTableDataset.push(newCells);
    // Перезаписываем таблицу
    onChange(newTableDataset);
  };

  const handleAddCol = (tableDataset = [[""]], newCellsContent = []) => {
    const newTableDataset = [...tableDataset];
    // К каждой строке добавляем 1 новый элемент
    for (let i = 0; i < newTableDataset.length; i++) {
      newTableDataset[i].push(newCellsContent[i] || "");
    }
    // Перезаписываем таблицу
    onChange(newTableDataset);
  };

  const handleRemoveRow = tableDataset => {
    const newTableDataset = [...tableDataset];
    // Сохраняем удаляемую строку
    setLastRemovedValues({
      type: "row",
      values: newTableDataset[newTableDataset.length - 1],
    });
    // Удаляем последнюю строку
    newTableDataset.pop();
    // Перезаписываем таблицу
    onChange(newTableDataset);
  };

  const handleRemoveCol = tableDataset => {
    const newTableDataset = [...tableDataset];

    let tmpRemovedCells = [];
    // Удаляем последний элемент из каждой строки массива
    for (let i = 0; i < tableDataset.length; i++) {
      // Сохраняем удаляемую ячейку
      tmpRemovedCells.push(newTableDataset[i][newTableDataset[i].length - 1]);
      newTableDataset[i].pop();
    }

    // Сохраняем удаляемую ячейку
    setLastRemovedValues({
      type: "col",
      values: tmpRemovedCells,
    });

    // Перезаписываем таблицу
    onChange(newTableDataset);
  };

  const resetRemoveChanges = (tableDataset, resetData) => {
    resetData.type === "row"
      ? handleAddRow(tableDataset, resetData.values)
      : handleAddCol(tableDataset, resetData.values);

    setLastRemovedValues({});
  };

  return (
    <div style={{ maxHeight: maxHeight }} className='EditableTable'>
      <table
        style={
          withBorders
            ? { width: tableWidth }
            : { border: "0", width: tableWidth }
        }
        border='2'
      >
        {columns.length > 0 ? (
          <thead>
            <tr>
              {columns.map(({ name, width }) => {
                return (
                  <th style={width ? { width } : { width: 180 }}>{name}</th>
                );
              })}
            </tr>
          </thead>
        ) : (
          ""
        )}

        <tbody>
          {tableDataset.map((editableRow = [], editableRowIndex) => {
            return (
              <tr
                className={
                  highlightLastRow &&
                  withBorders &&
                  editableRowIndex === tableDataset.length - 1
                    ? "highlight"
                    : ""
                }
                style={withBorders ? {} : { borderBottom: "2px solid #f7f7f7" }}
                key={`editableTableRow${editableRowIndex}`}
              >
                {editableRow.map((editableColData, editableColIndex) => {
                  return (
                    <EditableTableCell
                      withBorders={withBorders}
                      width={
                        columns[editableColIndex]
                          ? columns[editableColIndex].width
                          : "180px"
                      }
                      highlight={
                        highlightLastCol &&
                        withBorders &&
                        editableColIndex === editableRow.length - 1
                      }
                      key={`editableTableRow${editableRowIndex}Col${editableColIndex}`}
                      editableColData={editableColData}
                      onChangeCell={value => {
                        onChangeCell(editableRowIndex, editableColIndex, value);
                      }}
                    />
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className='controlsBlock mt2 mb2'>
        {showOnAddCol ? (
          <Button
            minHeight={40}
            onPress={() => {
              handleAddCol(tableDataset);
              setLastRemovedValues({});
            }}
          >
            Добавить столбец
          </Button>
        ) : (
          ""
        )}
        {showOnRemoveCol ? (
          <Button
            disabled={!tableDataset[0] || tableDataset[0].length <= 1}
            onMouseEnter={() => {
              setHighlightLastCol(true);
            }}
            onMouseOut={() => {
              setHighlightLastCol(false);
            }}
            minHeight={40}
            onPress={() => {
              handleRemoveCol(tableDataset);
            }}
          >
            Удалить столбец
          </Button>
        ) : (
          ""
        )}
        {showOnAddRow ? (
          <Button
            minHeight={40}
            onPress={() => {
              handleAddRow(tableDataset);
              setLastRemovedValues({});
            }}
          >
            Добавить строку
          </Button>
        ) : (
          ""
        )}
        {showOnRemoveRow ? (
          <Button
            disabled={!tableDataset || tableDataset.length <= 1}
            onMouseEnter={() => {
              setHighlightLastRow(true);
            }}
            onMouseOut={() => {
              setHighlightLastRow(false);
            }}
            minHeight={40}
            onPress={() => {
              handleRemoveRow(tableDataset);
            }}
          >
            Удалить строку
          </Button>
        ) : (
          ""
        )}
        {lastRemovedValues.type && showOnRevChanges ? (
          <Button
            minHeight={40}
            onPress={() => {
              resetRemoveChanges(tableDataset, lastRemovedValues);
            }}
          >
            Восстановить
          </Button>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default EditableTable;
