import {
  getSizesRequest,
  removeSizesRequest,
  createSizeRequest,
  updateSizeRequest,
  getSizesFailure,
  removeSizesFailure,
  createSizeFailure,
  updateSizeFailure,
  getSizesSuccess,
  removeSizesSuccess,
  createSizeSuccess,
  updateSizeSuccess,
} from "./actionCreators";

const getSizes = api => filter => dispatch => {
  dispatch(getSizesRequest());
  api
    .getSizes(filter)
    .then(res => {
      console.log(res, "res getSizes");
      dispatch(getSizesSuccess(res.data));
    })
    .catch(err => {
      console.log(err, "res getSizes");
      dispatch(
        getSizesFailure({
          status: "error",
          text: `Возникла ошибка с кодом ${
            err.response ? err.response.status : "???"
          } `,
        }),
      );
    });
};

const getSizeTablesTemplates = api => ({
  name,
  gender,
  productType,
}) => dispatch => {
  dispatch(getSizesRequest());
  api
    .getTemplatesSizes({ name, gender: gender.id, productType: productType.id })
    .then(res => {
      console.log(res, "res getSize");
      dispatch(getSizesSuccess(res.data));
    })
    .catch(err => {
      console.log(err, "res getSize");
      dispatch(
        getSizesFailure({
          status: "error",
          text: `Возникла ошибка с кодом ${
            err.response ? err.response.status : "???"
          } `,
        }),
      );
    });
};

const getSize = api => id => dispatch => {
  dispatch(getSizesRequest());
  api
    .getSize(id)
    .then(res => {
      console.log(res, "res getSize");
      dispatch(getSizesSuccess([res.data]));
    })
    .catch(err => {
      console.log(err, "res getSize");
      dispatch(
        getSizesFailure({
          status: "error",
          text: `Возникла ошибка с кодом ${
            err.response ? err.response.status : "???"
          } `,
        }),
      );
    });
};

const createSize = api => sizeData => dispatch => {
  dispatch(createSizeRequest());

  api
    .createSize(sizeData)
    .then(res => {
      console.log(res, "res createSize");
      dispatch(createSizeSuccess());
    })
    .catch(err => {
      console.log(err, "err createSize");
      dispatch(
        createSizeFailure({
          status: "error",
          text: `Возникла ошибка с кодом ${
            err.response ? err.response.status : "???"
          } `,
        }),
      );
    });
};

const updateSize = api => sizeData => dispatch => {
  dispatch(updateSizeRequest());

  api
    .updateSize(sizeData)
    .then(res => {
      console.log(res, "res updateSize");
      dispatch(updateSizeSuccess());
    })
    .catch(err => {
      console.log(err, "err updateSize");
      dispatch(
        updateSizeFailure({
          status: "error",
          text: `Возникла ошибка с кодом ${
            err.response ? err.response.status : "???"
          } `,
        }),
      );
    });
};

const removeSizes = api => (sizes = [], filter) => dispatch => {
  dispatch(removeSizesRequest());
  api
    .removeSizes(sizes.map(size => size.id))
    .then(res => {
      console.log(res, "res removeSizes");
      dispatch(removeSizesSuccess());

      getSizes(api)(filter)(dispatch);
    })
    .catch(err => {
      console.log(err, "err removeSizes");
      dispatch(
        removeSizesFailure({
          status: "error",
          text: `Возникла ошибка с кодом ${
            err.response ? err.response.status : "???"
          } `,
        }),
      );
    });
};

export {
  getSizes,
  getSize,
  createSize,
  updateSize,
  removeSizes,
  getSizeTablesTemplates,
};
