import axios from "axios";
import {
  mapUserInsideSortingFieldNameToEnum,
  mapUserOutsideSortingFieldNameToEnum,
  mapShopSortingFieldNameToEnum,
  mapSortValueToEnum,
  mapSizesSortingFieldNameToEnum,
  mapBrandSortingFieldNameToEnum,
  mapCategoriesSortingFieldNameToEnum,
  mapProductSortingFieldNameToEnum,
  mapModerationSortingFieldNameToEnum,
  mapSystemArticlesSortingFieldNameToEnum,
} from "../helpers/mapper";

export default class API {
  constructor(props) {
    this.api = axios.create({
      baseURL: window.location.origin + "/api",
      responseType: "json",
      ContentType: "application/json",
    });

    // this.api.interceptors.response.use(
    //   response => response,
    //   error => {
    //     return Promise.reject(error);
    //   },
    // );
  }

  // Auth
  auth = async ({ login, password }) => {
    const form = new FormData();
    form.append("username", login);
    form.append("password", password);

    let responseData = await this.api.post("/admin/authorization", form, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    return responseData;
  };

  // UsersInside
  getUsersInside = async filter => {
    let formattedFilter = {
      offset: filter.offset || null,
      limit: filter.limit || null,
      name: filter.name,
      username: filter.username !== undefined ? filter.username : null,
      role: filter.role !== undefined ? filter.role.id : null,
      sorting: mapUserInsideSortingFieldNameToEnum[filter.sortName],
      sortingDirection: mapSortValueToEnum[filter.sortValue],
    };
    let responseData = await this.api.get("/admin/staff", {
      params: {
        ...formattedFilter,
      },
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
      },
    });

    return responseData;
  };
  removeUsersInside = async usersIds => {
    let responseData = await this.api.post(
      `/admin/staff/bulk_delete`,
      { users: usersIds },
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
        },
      },
    );

    return responseData;
  };
  createUserInside = async ({ username, password, role }) => {
    let responseData = await this.api.post(
      `/admin/staff`,
      { username, password, role },
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
        },
      },
    );

    return responseData;
  };
  updateUserInside = async ({ id, username, password, role }) => {
    let responseData = await this.api.patch(
      `/admin/staff/${id}`,
      { username, password, role },
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
        },
      },
    );

    return responseData;
  };

  // UsersOutside
  getUsersOutside = async filter => {
    let formattedFilter = {
      offset: filter.offset || null,
      limit: filter.limit || null,

      isActive: filter.status !== undefined ? filter.status.id : null,
      sorting: mapUserOutsideSortingFieldNameToEnum[filter.sortName],
      sortingDirection: mapSortValueToEnum[filter.sortValue],
    };
    let responseData = await this.api.get("/admin/users", {
      params: {
        ...formattedFilter,
      },
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
      },
    });

    return responseData;
  };
  toggleUsersOutside = async usersIds => {
    let responseData = await this.api.patch(
      "/admin/users/toggle_is_active",
      {
        users: usersIds,
      },
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
        },
      },
    );

    return responseData;
  };
  exportUsersOutside = async () => {
    axios({
      url: "http://localhost:5000/static/example.pdf",
      method: "GET",
      responseType: "blob", // important
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.pdf");
      document.body.appendChild(link);
      link.click();
    });

    let responseData = await this.api.get("/admin/users/export_to_excel", {
      responseType: "blob",
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
      },
    });

    return responseData;
  };
  // Shops
  getShops = async filter => {
    let formattedFilter = {
      offset: filter.offset || null,
      limit: filter.limit || null,
      name: filter.name || null,
      sorting: mapShopSortingFieldNameToEnum[filter.sortName],
      sortingDirection: mapSortValueToEnum[filter.sortValue],
    };
    let responseData = await this.api.get("/admin/shops", {
      params: {
        ...formattedFilter,
      },
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
      },
    });

    return responseData;
  };

  removeShops = async shopsIds => {
    let responseData = await this.api.post(
      `/admin/shops/bulk_delete`,
      { shops: shopsIds },
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
        },
      },
    );

    return responseData;
  };

  createShop = async ({ name, addresses, moderators }) => {
    let responseData = await this.api.post(
      `/admin/shops`,
      { name, addresses, moderators },
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
        },
      },
    );

    return responseData;
  };
  updateShop = async ({ id, name, addresses, moderators }) => {
    let responseData = await this.api.patch(
      `/admin/shops/${id}`,
      { name, addresses, moderators },
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
        },
      },
    );

    return responseData;
  };

  getOwnShops = async filter => {
    let formattedFilter = {
      offset: filter.offset || null,
      limit: filter.limit || null,
      name: filter.name || null,
      sorting: mapShopSortingFieldNameToEnum[filter.sortName],
      sortingDirection: mapSortValueToEnum[filter.sortValue],
    };
    let responseData = await this.api.get("/admin/shops/own", {
      params: {
        ...formattedFilter,
      },
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
      },
    });

    return responseData;
  };

  updateOwnShop = async ({ id, name, addresses }) => {
    let responseData = await this.api.patch(
      `/admin/shops/own/${id}`,
      { name, addresses },
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
        },
      },
    );

    return responseData;
  };

  // Products
  getProducts = async filter => {
    let formattedFilter = {
      offset: filter.offset || null,
      limit: filter.limit || null,
      name: filter.name || null,
      sorting: mapProductSortingFieldNameToEnum[filter.sortName],
      sortingDirection: mapSortValueToEnum[filter.sortValue],
    };
    let responseData = await this.api.get("/admin/items", {
      params: {
        ...formattedFilter,
      },
      headers: {
        Authorization: "Token " + localStorage.getItem("token")
      },
    });

    return responseData;
  };
  removeProducts = async productsIds => {
    let responseData = await this.api.post(
      `/admin/items/bulk_delete`,
      { entities: productsIds },
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
        },
      },
    );

    return responseData;
  };
  createProduct = async ({
    name,
    description,
    itemType,
    gender,
    categoryId,
    shopId,
    brandId,
    materialId,
    colorId,
    sizeGrid,
    price,
    shopSiteUrl,
    availableSizes,
    productAddededImages,
    productLookImage,
    productPreview,
  }) => {
    let responseData = await this.api.post(
      `/admin/items`,
      {
        name,
        description,
        itemType,
        gender,
        categoryId,
        shopId,
        brandId,
        materialId,
        colorId,
        sizeGrid,
        price,
        shopSiteUrl,
        availableSizes,
      },
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
        },
      },
    );

    // Добавляем изображение образа
    if (productLookImage && productLookImage.file) {
      await this.addImageToProductLook({
        productId: responseData.data.id,
        imageFile: productLookImage.file,
      });
    }
    // Добавляем изображения товара
    if (productPreview && productPreview.file) {
      await this.addImageToProduct({
        productId: responseData.data.id,
        imageFile: productPreview.file,
      });
    }
    if (productAddededImages.length > 0) {
      // Добавление изображений в галерею
      for (let image of productAddededImages) {
        await this.addImageToProductGallery({
          productId: responseData.data.id,
          imageFile: image.file,
        });
      }
    }

    return responseData;
  };
  updateProduct = async ({
    id,
    name,
    description,
    itemType,
    gender,
    categoryId,
    shopId,
    brandId,
    materialId,
    colorId,
    sizeGrid,
    price,
    shopSiteUrl,
    availableSizes,
    productRemovedImages,
    productAddededImages,
    productLookImage,
    productPreview,
  }) => {
    let responseData = await this.api.patch(
      `/admin/items/${id}`,
      {
        name,
        description,
        itemType,
        gender,
        categoryId,
        shopId,
        brandId,
        materialId,
        colorId,
        sizeGrid,
        price,
        shopSiteUrl,
        availableSizes,
      },
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
        },
      },
    );

    console.log(productRemovedImages, "productRemovedImages");

    // Удаляем все картинки которые были удалены
    if (productRemovedImages.length > 0) {
      for (let image of productRemovedImages) {
        console.log(image, "image for delete");

        await this.deleteImageFromProduct({
          imageId: image.id,
        });
      }
    }

    // Добавляем изображение образа
    if (productLookImage && productLookImage.file) {
      await this.addImageToProductLook({
        productId: id,
        imageFile: productLookImage.file,
      });
    }

    // Добавляем изображения товара
    if (productPreview && productPreview.file) {
      await this.addImageToProduct({
        productId: id,
        imageFile: productPreview.file,
      });
    }
    if (productAddededImages.length > 0) {
      // Добавление изображений в галерею
      for (let image of productAddededImages) {
        await this.addImageToProductGallery({
          productId: id,
          imageFile: image.file,
        });
      }
    }

    return responseData;
  };

  // Sizes
  getSizes = async filter => {
    let formattedFilter = {
      offset: filter.offset || null,
      limit: filter.limit || null,
      name: filter.name || null,
      sorting: mapSizesSortingFieldNameToEnum[filter.sortName],
      sortingDirection: mapSortValueToEnum[filter.sortValue],
    };
    let responseData = await this.api.get("/admin/size_grids", {
      params: {
        ...formattedFilter,
      },
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
      },
    });

    return responseData;
  };

  getSize = async id => {
    let responseData = await this.api.get(`/admin/size_grids/${id}`, {
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
      },
    });

    return responseData;
  };
  removeSizes = async sizesIds => {
    let responseData = await this.api.post(
      `/admin/size_grids/bulk_delete`,
      { sizeGrids: sizesIds },
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
        },
      },
    );

    return responseData;
  };
  createSize = async ({ name, rows }) => {
    let responseData = await this.api.post(
      `/admin/size_grids`,
      { name, rows },
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
        },
      },
    );

    return responseData;
  };

  updateSize = async ({ id, name, rows }) => {
    let responseData = await this.api.patch(
      `/admin/size_grids/${id}`,
      { name, rows },
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
        },
      },
    );

    return responseData;
  };

  getTemplatesSizes = async ({ name, gender, productType }) => {
    let formattedFilter = {
      name: name || null,
      item_type: productType || null,
      gender: gender || null,
    };

    let responseData = await this.api.get("/admin/size_grids/templates", {
      params: {
        ...formattedFilter,
      },
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
      },
    });

    return responseData;
  };

  // Brands
  getBrands = async filter => {
    let formattedFilter = {
      offset: filter.offset || null,
      limit: filter.limit || null,
      name: filter.name || null,
      sorting: mapBrandSortingFieldNameToEnum[filter.sortName],
      sortingDirection: mapSortValueToEnum[filter.sortValue],
    };
    let responseData = await this.api.get("/admin/brands", {
      params: {
        ...formattedFilter,
      },
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
      },
    });

    return responseData;
  };
  removeBrands = async brandsIds => {
    let responseData = await this.api.post(
      `/admin/brands/bulk_delete`,
      { entities: brandsIds },
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
        },
      },
    );

    return responseData;
  };
  createBrand = async ({ name }) => {
    let responseData = await this.api.post(
      `/admin/brands`,
      {
        name,
      },
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
        },
      },
    );

    return responseData;
  };
  updateBrand = async ({ id, name }) => {
    let responseData = await this.api.patch(
      `/admin/brands/${id}`,
      {
        name,
      },
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
        },
      },
    );

    return responseData;
  };

  // Categories
  getCategories = async filter => {
    let formattedFilter = {
      offset: filter.offset || null,
      limit: filter.limit || null,
      name: filter.name || null,
      excludeLevels: "0",
      sorting: mapCategoriesSortingFieldNameToEnum[filter.sortName],
      sortingDirection: mapSortValueToEnum[filter.sortValue],
    };
    let responseData = await this.api.get("/admin/categories", {
      params: {
        ...formattedFilter,
      },
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
      },
    });

    return responseData;
  };
  getSearchCategories = async ({ name }) => {
    let formattedFilter = {
      name: name || null,
      limit: 99999999,
    };
    let responseData = await this.api.get("/admin/categories", {
      params: formattedFilter,
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
      },
    });

    return responseData;
  };
  removeCategories = async categoriesIds => {
    let responseData = await this.api.post(
      `/admin/categories/bulk_delete`,
      { entities: categoriesIds },
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
        },
      },
    );

    return responseData;
  };

  createCategory = async ({ name, parentCategoryId, itemType, gender }) => {
    let responseData = await this.api.post(
      `/admin/categories`,
      {
        name,
        parentCategoryId,
        itemType,
        gender,
      },
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
        },
      },
    );

    return responseData;
  };
  updateCategory = async ({ id, name, parentCategoryId, itemType, gender }) => {
    let responseData = await this.api.patch(
      `/admin/categories/${id}`,
      {
        name,
        parentCategoryId,
        itemType,
        gender,
      },
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
        },
      },
    );

    return responseData;
  };

  // Search
  getMaterials = async ({ name }) => {
    let formattedFilter = {
      offset: 0,
      limit: 99999,
      name: name || null,
    };
    let responseData = await this.api.get("/admin/materials", {
      params: {
        ...formattedFilter,
      },
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
      },
    });

    return responseData;
  };
  getColors = async ({ name }) => {
    let formattedFilter = {
      offset: 0,
      limit: 99999,
      name: name || null,
    };
    let responseData = await this.api.get("/admin/colors", {
      params: {
        ...formattedFilter,
      },
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
      },
    });

    return responseData;
  };

  // Moderation articles
  getModerationArticles = async filter => {
    let formattedFilter = {
      offset: filter.offset || null,
      limit: filter.limit || null,
      nickname: filter.nickname || null,
      contentType: 1,
      sorting: mapModerationSortingFieldNameToEnum[filter.sortName],
      sortingDirection: mapSortValueToEnum[filter.sortValue],
    };
    let responseData = await this.api.get("/admin/content_moderation", {
      params: {
        ...formattedFilter,
      },
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
      },
    });

    return responseData;
  };
  updateModerationArticle = async ({ id, status }) => {
    let responseData = await this.api.patch(
      `/admin/content_moderation/${id}`,
      { verificationStatus: status, contentType: 1 },
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
        },
      },
    );

    return responseData;
  };

  massUpdateModerationArticles = async ({ moderationArticlesIds, status }) => {
    let responseData = await this.api.patch(
      `/admin/content_moderation/bulk_status_update/${status}`,
      { articles: moderationArticlesIds, representations: [] },
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
        },
      },
    );

    return responseData;
  };

  // Moderation looks
  getModerationLooks = async filter => {
    let formattedFilter = {
      offset: filter.offset || null,
      limit: filter.limit || null,
      nickname: filter.nickname || null,
      contentType: 0,
      sorting: mapModerationSortingFieldNameToEnum[filter.sortName],
      sortingDirection: mapSortValueToEnum[filter.sortValue],
    };
    let responseData = await this.api.get("/admin/content_moderation", {
      params: {
        ...formattedFilter,
      },
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
      },
    });

    return responseData;
  };

  updateModerationLooks = async ({ id, status }) => {
    let responseData = await this.api.patch(
      `/admin/content_moderation/${id}`,
      { verificationStatus: status, contentType: 0 },
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
        },
      },
    );

    return responseData;
  };

  massUpdateModerationLooks = async ({ moderationLooksIds, status }) => {
    let responseData = await this.api.patch(
      `/admin/content_moderation/bulk_status_update/${status}`,
      { representations: moderationLooksIds, articles: [] },
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
        },
      },
    );

    return responseData;
  };

  // Complaints
  getComplaints = async filter => {
    let formattedFilter = {
      offset: filter.offset || null,
      limit: filter.limit || null,
      username: filter.username || null,
    };
    let responseData = await this.api.get("/admin/reports", {
      params: {
        ...formattedFilter,
      },
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
      },
    });

    return responseData;
  };

  updateComplaint = async ({ id, status }) => {
    let responseData = await this.api.patch(
      `/admin/reports/bulk_status_update/${status}`,
      { entities: [id] },
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
        },
      },
    );

    return responseData;
  };

  massUpdateComplaints = async ({ complaintsIds, status }) => {
    let responseData = await this.api.patch(
      `/admin/reports/bulk_status_update/${status}`,
      { entities: complaintsIds },
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
        },
      },
    );

    return responseData;
  };

  // System articles
  getArticles = async filter => {
    let formattedFilter = {
      offset: filter.offset || null,
      limit: filter.limit || null,
      status: filter.status !== undefined ? filter.status.id : null,
      username: filter.username || null,
      sorting: mapSystemArticlesSortingFieldNameToEnum[filter.sortName],
      sortingDirection: mapSortValueToEnum[filter.sortValue],
    };
    let responseData = await this.api.get("/admin/system_articles", {
      params: {
        ...formattedFilter,
      },
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
      },
    });

    return responseData;
  };

  removeArticles = async articlesIds => {
    let responseData = await this.api.post(
      `/admin/system_articles/bulk_delete`,
      { entities: articlesIds },
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
        },
      },
    );

    return responseData;
  };

  createArticle = async ({
    name,
    text,
    tags,
    verificationStatus,
    imagesToAdd,
    items,
  }) => {
    let responseData = await this.api.post(
      `/admin/system_articles`,
      { name, text, tags, verificationStatus, items },
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
        },
      },
    );

    // Добавляем картинки к созданной статье
    for (let image of imagesToAdd) {
      await this.addImageToArticle({
        articleId: responseData.data.id,
        imageFile: image.file,
      });
    }

    return responseData;
  };

  updateArticle = async ({
    id,
    name,
    text,
    tags,
    verificationStatus,
    imagesToRemove,
    imagesToAdd,
    items,
  }) => {
    let responseData = await this.api.patch(
      `/admin/system_articles/${id}`,
      { name, text, tags, verificationStatus, items },
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
        },
      },
    );

    // Удаляем карнинки
    if (imagesToRemove.length > 0) {
      await this.deleteImageFromArticle({
        articleId: id,
        imagesIds: imagesToRemove.map(image => image.id),
      });
    }

    // Добавляем картинки
    for (let image of imagesToAdd) {
      await this.addImageToArticle({
        articleId: id,
        imageFile: image.file,
      });
    }

    return responseData;
  };

  massUpdateArticlesStatus = async ({ articlesIds, status }) => {
    let responseData = await this.api.patch(
      `/admin/system_articles/bulk_verification_status_update/${status}`,
      { entities: articlesIds },
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
        },
      },
    );

    return responseData;
  };

  addImageToArticle = async ({ articleId, imageFile }) => {
    const form = new FormData();
    form.append("image", imageFile);

    let responseData = await this.api.post(
      `/media/article_images/${articleId}`,
      form,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Token " + localStorage.getItem("token"),
        },
      },
    );

    return responseData;
  };

  deleteImageFromArticle = async ({ articleId, imagesIds }) => {
    let responseData = await this.api.post(
      `/media/article_images/${articleId}/delete`,
      { imagesToDelete: imagesIds },
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
        },
      },
    );

    return responseData;
  };

  addImageToProduct = async ({ productId, imageFile }) => {
    const form = new FormData();
    form.append("image", imageFile);

    let responseData = await this.api.post(
      `/admin/media/item_photo/${productId}`,
      form,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Token " + localStorage.getItem("token"),
        },
      },
    );

    return responseData;
  };
  addImageToProductLook = async ({ productId, imageFile }) => {
    const form = new FormData();
    form.append("image", imageFile);

    let responseData = await this.api.post(
      `/admin/media/item_try_on_template/${productId}`,
      form,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Token " + localStorage.getItem("token"),
        },
      },
    );

    return responseData;
  };

  addImageToProductGallery = async ({ productId, imageFile }) => {
    const form = new FormData();
    form.append("image", imageFile);

    let responseData = await this.api.post(
      `/admin/media/item_gallery_image/${productId}`,
      form,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Token " + localStorage.getItem("token"),
        },
      },
    );

    return responseData;
  };
  deleteImageFromProduct = async ({ imageId }) => {
    let responseData = await this.api.delete(
      `/admin/media/delete_media_image/${imageId}`,
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
        },
      },
    );

    return responseData;
  };

  // Translations
  getTranslations = async filter => {
    let formattedFilter = {
      offset: filter.offset || null,
      limit: filter.limit || null,
    };
    let responseData = await this.api.get(
      "/admin/localization/static_strings",
      {
        params: {
          ...formattedFilter,
        },
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
        },
      },
    );

    return responseData;
  };

  getLanguages = async () => {
    let responseData = await this.api.get("/localization/languages", {
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
      },
    });

    return responseData;
  };

  updateTranslations = async translationsData => {
    let responseData = await this.api.patch(
      `/admin/localization/static_strings`,
      { staticLocalizedStrings: translationsData },
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
        },
      },
    );

    return responseData;
  };
}
