import React, { useState, useEffect } from "react";

import "./EditLookForm.scss";
import { SelectInput, Button } from "../../../components/FormItems";
import { mapComplaintStatusIdToName } from "../../../helpers/mapper";
import getFormattedDate from "../../../helpers/getFormattedDate";

const EditLookForm = ({
  lookData = {},
  onCloseModal,
  status = 1,

  updateComplaint,
}) => {
  const {
    id,
    image,
    items = [],
    name,
    author,
    updatedAt,
    tags,
    rating,
    text,
  } = lookData;

  const [editStatus, setEditStatus] = useState({
    id: status,
    name: mapComplaintStatusIdToName[status],
  });

  useEffect(() => {
    setEditStatus({
      id: status,
      name: mapComplaintStatusIdToName[status],
    });
  }, [lookData]);

  return (
    <div className='EditLookForm'>
      <div className='imagesBlock mb2'>
        {image ? (
          <div
            className='largeImageWrapper'
            style={{
              backgroundImage: `url(${window.location.origin + image.image})`,
            }}
          ></div>
        ) : (
          ""
        )}
        <div className='gridRow2'>
          {items[0] && items[0].photo ? (
            <div
              className='smallImageWrapper'
              style={{
                backgroundImage: `url(${window.location.origin +
                  items[0].photo.image})`,
              }}
            ></div>
          ) : (
            ""
          )}
          {items[1] && items[1].photo ? (
            <div
              className='smallImageWrapper'
              style={{
                backgroundImage: `url(${window.location.origin +
                  items[1].photo.image})`,
              }}
            ></div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className='flexRow'>
        <div className='title'>{name}</div>
        <div className='flexRow'>
          <SelectInput
            width={220}
            placeholder='Статус'
            onSelect={value => {
              setEditStatus(value);
            }}
            selectedValue={editStatus}
            options={[
              { id: 0, name: "Новая" },
              { id: 1, name: "Принята" },
              { id: 2, name: "Отклонена" },
            ]}
          />
          <div className='date-text ml2'>
            {getFormattedDate(updatedAt / 1000)}
          </div>
        </div>
      </div>
      <div className='author'>{author ? author.nickname : ""}</div>
      <div className='text mt1'>{text}</div>
      <div className='likes mt2 mb1'>
        Оценка: <b>{rating}</b>
      </div>
      <div className='tags'>
        {tags ? tags.map(tag => <div className='tag'>{tag.name}</div>) : ""}
      </div>
      <div className='controlsBlock'>
        <Button
          minWidth={260}
          filled={true}
          disabled={editStatus.id === status}
          onPress={() => {
            updateComplaint({ id, status: editStatus.id });
            onCloseModal();
          }}
        >
          Сохранить
        </Button>
        <div className='mt2'></div>
        <Button
          minWidth={260}
          onPress={() => {
            onCloseModal();
          }}
        >
          Отменить
        </Button>
      </div>
    </div>
  );
};

export default EditLookForm;
