import {
  FETCH_GET_USERS_INSIDE_REQUEST,
  FETCH_GET_USERS_INSIDE_FAILURE,
  FETCH_GET_USERS_INSIDE_SUCCESS,
  FETCH_REMOVE_USERS_INSIDE_REQUEST,
  FETCH_REMOVE_USERS_INSIDE_FAILURE,
  FETCH_REMOVE_USERS_INSIDE_SUCCESS,
  FETCH_CREATE_USER_INSIDE_REQUEST,
  FETCH_CREATE_USER_INSIDE_FAILURE,
  FETCH_CREATE_USER_INSIDE_SUCCESS,
  FETCH_UPDATE_USER_INSIDE_REQUEST,
  FETCH_UPDATE_USER_INSIDE_FAILURE,
  FETCH_UPDATE_USER_INSIDE_SUCCESS,
} from "./types";

export const getUsersInsideRequest = () => {
  return { type: FETCH_GET_USERS_INSIDE_REQUEST };
};
export const removeUsersInsideRequest = () => {
  return { type: FETCH_REMOVE_USERS_INSIDE_REQUEST };
};
export const createUserInsideRequest = () => {
  return { type: FETCH_CREATE_USER_INSIDE_REQUEST };
};
export const updateUserInsideRequest = () => {
  return { type: FETCH_UPDATE_USER_INSIDE_REQUEST };
};

export const getUsersInsideFailure = error => {
  return { type: FETCH_GET_USERS_INSIDE_FAILURE, payload: error };
};
export const removeUsersInsideFailure = error => {
  return { type: FETCH_REMOVE_USERS_INSIDE_FAILURE, payload: error };
};
export const createUserInsideFailure = error => {
  return { type: FETCH_CREATE_USER_INSIDE_FAILURE, payload: error };
};
export const updateUserInsideFailure = error => {
  return { type: FETCH_UPDATE_USER_INSIDE_FAILURE, payload: error };
};

export const getUsersInsideSuccess = usersInsideData => {
  return { type: FETCH_GET_USERS_INSIDE_SUCCESS, payload: usersInsideData };
};
export const removeUsersInsideSuccess = () => {
  return { type: FETCH_REMOVE_USERS_INSIDE_SUCCESS };
};
export const createUserInsideSuccess = () => {
  return { type: FETCH_CREATE_USER_INSIDE_SUCCESS };
};
export const updateUserInsideSuccess = () => {
  return { type: FETCH_UPDATE_USER_INSIDE_SUCCESS };
};
