import {
  loginRequest,
  loginFailure,
  loginSuccess,
  logout,
} from "./actionCreators";

const handleLogin = api => ({ login, password }) => dispatch => {
  dispatch(loginRequest());
  api
    .auth({ login, password })
    .then(res => {
      if (res.data.token) {
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("roleId", res.data.role);
        localStorage.setItem("login", login);
        dispatch(
          loginSuccess({ login, token: res.data.token, roleId: res.data.role }),
        );
      } else {
        dispatch(
          loginFailure({
            status: "error",
            text: "Неправильный логин или пароль",
          }),
        );
      }
    })
    .catch(err => {
      dispatch(
        loginFailure({
          status: "error",
          text: "Неправильный логин или пароль",
        }),
      );
    });
};

const handleLogout = () => () => dispatch => {
  localStorage.removeItem("token");
  localStorage.removeItem("login");
  localStorage.removeItem("roleId");
  dispatch(logout());
};

export { handleLogin, handleLogout };
