import {
  FETCH_GET_MODERATION_LOOKS_REQUEST,
  FETCH_GET_MODERATION_LOOKS_FAILURE,
  FETCH_GET_MODERATION_LOOKS_SUCCESS,
  FETCH_UPDATE_MODERATION_LOOK_REQUEST,
  FETCH_UPDATE_MODERATION_LOOK_FAILURE,
  FETCH_UPDATE_MODERATION_LOOK_SUCCESS,
  FETCH_MASS_UPDATE_MODERATION_LOOKS_REQUEST,
  FETCH_MASS_UPDATE_MODERATION_LOOKS_SUCCESS,
  FETCH_MASS_UPDATE_MODERATION_LOOKS_FAILURE,
} from "./types";

const initialState = {
  moderationLooks: [],
  moderationLooksTotalCount: 0,
  loading: false,
  error: null,
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GET_MODERATION_LOOKS_REQUEST:
    case FETCH_MASS_UPDATE_MODERATION_LOOKS_REQUEST:
    case FETCH_UPDATE_MODERATION_LOOK_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_GET_MODERATION_LOOKS_SUCCESS:
      return {
        ...state,
        loading: false,
        moderationLooks: action.payload.entities,
        moderationLooksTotalCount: action.payload.count,
      };

    case FETCH_MASS_UPDATE_MODERATION_LOOKS_SUCCESS:
    case FETCH_UPDATE_MODERATION_LOOK_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case FETCH_GET_MODERATION_LOOKS_FAILURE:
    case FETCH_MASS_UPDATE_MODERATION_LOOKS_FAILURE:
    case FETCH_UPDATE_MODERATION_LOOK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};

export default auth;
