import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withApiService } from "../../../components/hoc";
import compose from "../../../helpers/compose";
import { getUsersInside } from "../../../redux/usersInside/actions";

import "./EditOrAddShopForm.scss";
import {
  Form,
  TextInput,
  Button,
  SearchInput,
  NumInput,
  SelectInput,
} from "../../../components/FormItems";

import { ReactComponent as PlusIcon } from "../../../assets/images/plusIcon.svg";
import { ReactComponent as CloseIcon } from "../../../assets/images/closeIcon.svg";

import { Table } from "../../../components/Tables";

import Stepper from "../../../components/Stepper";

const getAvailableTranslates = (translates, languages) => {
  let tmpAvailableLanguages = [];
  let formatTranslates = translates ? translates : [];

  languages.map(language => {
    let findTranslate = formatTranslates.find(
      translate => translate.languageCode === language.code,
    );
    if (!findTranslate) {
      tmpAvailableLanguages.push({ ...language, languageCode: language.code });
    }
  });
  return tmpAvailableLanguages;
};

const changeSelectedTranslate = (
  translates,
  currentTranslate,
  nextTranslateIndex,
) => {
  // Находим индекс текущего перевода в списке пеереводов
  let formatTranslates = translates ? translates : [];

  let currentTranslateIndex = formatTranslates.findIndex(
    translate => translate.languageCode === currentTranslate.languageCode,
  );

  if (formatTranslates[currentTranslateIndex + nextTranslateIndex]) {
    return { ...formatTranslates[currentTranslateIndex + nextTranslateIndex] };
  } else {
    return nextTranslateIndex > 0
      ? formatTranslates[0]
      : formatTranslates[formatTranslates.length - 1];
  }
};

const addNewTranslateToFormTranslates = (addededTranslate, formTranslates) => {
  return [
    ...formTranslates,
    { data: "", languageCode: addededTranslate.languageCode },
  ];
};

const onChangeTranslatedField = (value, translate, formTranslates) => {
  // Находим перевод который нужно изменить
  let changedTranslateIndex = formTranslates.findIndex(
    formTranslate => formTranslate.languageCode === translate.languageCode,
  );

  formTranslates[changedTranslateIndex].data = value;

  return [...formTranslates];
};

const EditOrAddShopForm = ({
  onCloseModal,
  languages = [],
  shopData = {},
  usersInside = [],
  userRoleId = 0,
  createShop,
  updateShop,
  getUsersInside,
}) => {
  let isEdit = false;
  if (shopData.name || shopData.addresses || shopData.moderators) {
    isEdit = true;
  }

  const [shopAddresses, setShopAddresses] = useState([]);
  const [shopModerators, setShopModerators] = useState([]);
  const [shopAddressName, setShopAddressName] = useState("");
  const [shopAddressLatitude, setShopAddressLatitude] = useState(""); // широта
  const [shopAddressLongitude, setShopAddressLongitude] = useState(""); // долгота
  const [shopName, setShopName] = useState([{ languageCode: "en", data: "" }]);
  const [selectedTranslate, setSelectedTranslate] = useState({
    languageCode: "en",
  });
  const [selectedTranslateToAdd, setSelectedTranslateToAdd] = useState({});

  useEffect(() => {
    setShopName(
      shopData.name ? shopData.name : [{ languageCode: "en", data: "" }],
    );
  }, [shopData]);

  // Подготавливаем список языков которые можно добавить
  let availableToAddTranslates = getAvailableTranslates(shopName, languages);

  let selectedTranslateName = languages.find(
    language => language.code === selectedTranslate.languageCode,
  );

  let selectedTranslateShopName = shopName.find(
    shop => shop.languageCode === selectedTranslate.languageCode,
  );
  // Добавить возможность

  const updateShopAddresses = shopAddress => {
    // Смотрим есть ли у нас такой адресс в списке адресов
    let shopAddressIndex = shopAddresses.findIndex(
      item => item.name === shopAddress.name,
    );
    if (shopAddressIndex < 0) {
      // Если еще нет, добавляем его
      setShopAddresses([shopAddress, ...shopAddresses]);
      setShopAddressName("");
      setShopAddressLatitude("");
      setShopAddressLongitude("");
    }
  };

  const removeAddressFromShopAddresses = shopAddress => {
    let shopAddressIndex = shopAddresses.findIndex(
      item => item.name === shopAddress.name,
    );

    if (shopAddressIndex > -1) {
      setShopAddresses([
        ...shopAddresses.slice(0, shopAddressIndex),
        ...shopAddresses.slice(shopAddressIndex + 1),
      ]);
    }
  };

  const updateShopModerators = shopModerator => {
    // Смотрим есть ли у нас такой адресс в списке адресов
    let shopModeratorIndex = shopModerators.findIndex(
      item => item.id === shopModerator.id,
    );
    if (shopModeratorIndex < 0) {
      // Если еще нет, добавляем его
      setShopModerators([shopModerator, ...shopModerators]);
    }
  };

  const removeModeratorFromShopModerators = shopModerator => {
    let shopModeratorIndex = shopModerators.findIndex(
      item => item.id === shopModerator.id,
    );

    if (shopModeratorIndex > -1) {
      setShopModerators([
        ...shopModerators.slice(0, shopModeratorIndex),
        ...shopModerators.slice(shopModeratorIndex + 1),
      ]);
    }
  };

  useEffect(() => {
    getUsersInside({ username: "awdawdawdawdawdawdawdawd" }); // Костыль что бы очистить поиск
    setShopAddresses(shopData.addresses || []);
    setShopModerators(shopData.moderators || []);
  }, [shopData]);

  const onChangeAddressesRowData = (editedRow, allRows, editedRowIndex) => {
    // Находим в строках ту строку которую нужно изменить и заменяем ее

    let modifiedRow = [...allRows];

    modifiedRow[editedRowIndex] = editedRow;
    return modifiedRow;
  };

  const onChangeAddressNames = (oldNames, newName, selectedTranslate) => {
    // Находим строку которую нужно изменить и меняем ее
    let editedTranslateIndex = oldNames.findIndex(
      item => item.languageCode === selectedTranslate.languageCode,
    );

    if (oldNames[editedTranslateIndex]) {
      oldNames[editedTranslateIndex].data = newName;
      return [...oldNames];
    } else {
      return [
        ...oldNames,
        { languageCode: selectedTranslate.languageCode, data: newName },
      ];
    }
  };

  const getAddressNameByTranslate = (names, selectedTranslate) => {
    return names.find(
      shop => shop.languageCode === selectedTranslate.languageCode,
    );
  };
  const createNewAddressName = (name, allTranslates, selectedTranslate) => {
    return allTranslates.map(translate => {
      return {
        data:
          translate.languageCode === selectedTranslate.languageCode ? name : "",
        languageCode: translate.languageCode,
      };
    });
  };

  const columnsAddresses = [
    {
      title: "Адрес ",
      dataIndex: "name",
      width: "50%",
      render: (rowData, rowIndex) => {
        return (
          <TextInput
            maxLength={512}
            type='text'
            name='name'
            placeholder='Адрес магазина'
            onChange={value => {
              setShopAddresses(
                onChangeAddressesRowData(
                  {
                    ...rowData,
                    name: onChangeAddressNames(
                      rowData.name,
                      value,
                      selectedTranslate,
                    ),
                  },
                  shopAddresses,
                  rowIndex,
                ),
              );
            }}
            value={
              getAddressNameByTranslate(rowData.name, selectedTranslate)
                ? getAddressNameByTranslate(rowData.name, selectedTranslate)
                    .data
                : ""
            }
          />
        );
      },
    },
    {
      title: "Широта",
      dataIndex: "lat",
      width: "15%",
      render: (rowData, rowIndex) => {
        return (
          <NumInput
            type='text'
            name='lat'
            placeholder='Широта'
            onChange={value => {
              if (
                !value.split(".")[1] ||
                (value.split(".")[1] &&
                  value.split(".")[1].length <= 6 &&
                  value.length <= 9)
              ) {
                setShopAddresses(
                  onChangeAddressesRowData(
                    { ...rowData, lat: value },
                    shopAddresses,
                    rowIndex,
                  ),
                );
              }
            }}
            value={rowData.lat}
          />
        );
      },
    },
    {
      title: "Долгота",
      dataIndex: "lng",
      width: "15%",
      render: (rowData, rowIndex) => {
        return (
          <NumInput
            type='text'
            name='lng'
            placeholder='Долгота'
            onChange={value => {
              if (
                !value.split(".")[1] ||
                (value.split(".")[1] &&
                  value.split(".")[1].length <= 6 &&
                  value.length <= 9)
              ) {
                setShopAddresses(
                  onChangeAddressesRowData(
                    { ...rowData, lng: value },
                    shopAddresses,
                    rowIndex,
                  ),
                );
              }
            }}
            value={rowData.lng}
          />
        );
      },
    },
    {
      title: "",
      dataIndex: "name",
      render: rowData => {
        return (
          <CloseIcon
            onClick={() => {
              removeAddressFromShopAddresses(rowData);
            }}
            className='removeBtn'
          />
        );
      },
      width: "5%",
    },
  ];
  const columnsModerators = [
    {
      title: "Название",
      dataIndex: "username",
    },
    {
      title: "",
      dataIndex: "username",
      render: rowData => {
        return userRoleId != 2 ? (
          <CloseIcon
            onClick={() => {
              removeModeratorFromShopModerators(rowData);
            }}
            className='removeBtn'
          />
        ) : (
          ""
        );
      },
      width: "5%",
    },
  ];

  return (
    <Stepper
      onNextStep={() => {
        setSelectedTranslate(
          changeSelectedTranslate(shopName, selectedTranslate, 1),
        );
      }}
      onPrevStep={() => {
        setSelectedTranslate(
          changeSelectedTranslate(shopName, selectedTranslate, -1),
        );
      }}
    >
      <div className='EditOrAddShopForm'>
        <Form title=''>
          <div className='translate-flex-row'>
            <div className='translate-flex-col'>
              <div className='translate-title'>
                Добавление/редактирование магазина
              </div>
              <div className='translate-count'>
                Переводов: {shopName.length}
              </div>
              <div className='translate-language'>
                Выбран:{" "}
                {selectedTranslateName ? selectedTranslateName.name : ""}
              </div>
            </div>
            <div className='translate-flex-row'>
              <SelectInput
                placeholder='Перевод'
                onSelect={value => setSelectedTranslateToAdd(value)}
                selectedValue={selectedTranslateToAdd}
                options={availableToAddTranslates}
              />
              <div className='mr2'></div>
              <Button
                disabled={!selectedTranslateToAdd.name}
                filled={true}
                onPress={() => {
                  // Добавляем перевод в список переводов
                  setShopName(
                    addNewTranslateToFormTranslates(
                      selectedTranslateToAdd,
                      shopName,
                    ),
                  );
                  // Сбрасываем выбранный для добавления язык
                  setSelectedTranslateToAdd({});

                  // Переключаемся на последний перевод
                }}
              >
                Добавить перевод
              </Button>
            </div>
          </div>

          <div className='mt4'></div>
          <div className='EditOrAddShopForm__inputsBlock__shopName'>
            <TextInput
              maxLength={32}
              type='text'
              name='shopName'
              placeholder='Название магазина'
              onChange={value => {
                setShopName(
                  onChangeTranslatedField(value, selectedTranslate, shopName),
                );
              }}
              value={
                selectedTranslateShopName ? selectedTranslateShopName.data : ""
              }
            />
          </div>
          <div className='mt1'></div>
          <div className='EditOrAddShopForm__inputsBlock'>
            <div>
              <div className='EditOrAddShopForm__addressesForm'>
                <div className='EditOrAddShopForm__inputsBlock__shopName'>
                  <TextInput
                    maxLength={512}
                    type='text'
                    name='shopAddressName'
                    placeholder='Адрес магазина'
                    onChange={value => {
                      setShopAddressName(value);
                    }}
                    value={shopAddressName}
                  />
                </div>
                <div className='EditOrAddShopForm__addressesForm__coordinates'>
                  <NumInput
                    type='text'
                    name='shopAddressLatitude'
                    placeholder='Широта'
                    onChange={value => {
                      if (
                        (!value.split(".")[1] ||
                          (value.split(".")[1] &&
                            value.split(".")[1].length <= 6)) &&
                        value.length <= 9
                      ) {
                        setShopAddressLatitude(value);
                      }
                    }}
                    value={shopAddressLatitude}
                  />
                  <NumInput
                    type='text'
                    name='shopAddressLongitude'
                    placeholder='Долгота'
                    onChange={value => {
                      if (
                        (!value.split(".")[1] ||
                          (value.split(".")[1] &&
                            value.split(".")[1].length <= 6)) &&
                        value.length <= 9
                      ) {
                        setShopAddressLongitude(value);
                      }
                    }}
                    value={shopAddressLongitude}
                  />
                </div>
                <button
                  disabled={
                    !shopAddressName ||
                    !shopAddressLatitude ||
                    !shopAddressLongitude
                  }
                  type='button'
                  className='EditOrAddShopForm__plusBtn'
                  onClick={() => {
                    updateShopAddresses({
                      name: createNewAddressName(
                        shopAddressName,
                        shopName,
                        selectedTranslate,
                      ),
                      lat: shopAddressLatitude,
                      lng: shopAddressLongitude,
                    });
                  }}
                >
                  <PlusIcon />
                </button>
              </div>
              <div className='tableTitle'>Адреса магазинов</div>
              <Table
                loading={false}
                dataSource={shopAddresses}
                columns={columnsAddresses}
                onClickRow={() => {}}
                withHeader={false}
                maxHeight={150}
                highlightRow={rowData => {}}
                onChange={(pagination, sorter) => {}}
              />
            </div>
            <div>
              {userRoleId != 2 ? (
                <div className='EditOrAddShopForm__inputsBlock__searchName'>
                  <SearchInput
                    placeholder='Имя менеджера'
                    onSelect={value => {
                      updateShopModerators(value);
                    }}
                    searchResults={usersInside} // Заменить на боевые данные
                    searchRequest={val => {
                      getUsersInside({ username: val });
                    }} // Заменить на боевой запрос
                    selectedValue={{}} // Заменить на боевые данные
                  />
                </div>
              ) : (
                ""
              )}

              <div className='tableTitle'>Менеджеры</div>
              <Table
                loading={false}
                dataSource={shopModerators}
                columns={columnsModerators}
                onClickRow={() => {}}
                withHeader={false}
                maxHeight={150}
                onChange={(pagination, sorter) => {}}
              />
            </div>
          </div>
          <div className='mb4'></div>
          <div className='EditOrAddShopForm__buttonsBlock'>
            <Button
              filled={true}
              minWidth={400}
              disabled={
                !shopName &&
                !shopModerators.length > 0 &&
                !shopAddresses.length > 0
              }
              onPress={() => {
                console.log("Save user");
                isEdit
                  ? updateShop({
                      id: shopData.id,
                      name: shopName,
                      addresses: shopAddresses,
                      moderators: shopModerators,
                    })
                  : createShop({
                      name: shopName,
                      addresses: shopAddresses,
                      moderators: shopModerators,
                    });

                setShopAddresses([]);
                setShopModerators([]);
                setShopAddressName([]);
                setShopAddressLatitude("");
                setShopAddressLongitude("");
                setShopName([{ languageCode: "en", data: "" }]);
                setSelectedTranslate({
                  languageCode: "en",
                });
                setSelectedTranslateToAdd({});

                onCloseModal();
              }}
            >
              Сохранить
            </Button>
            <div className='mt2'></div>
            <Button
              minWidth={400}
              onPress={() => {
                onCloseModal();
              }}
            >
              Отменить
            </Button>
          </div>
        </Form>
      </div>
    </Stepper>
  );
};

const mapStateToProps = ({ usersInside }) => ({
  usersInside: usersInside.usersInside,
});
const mapDispatchToProps = (dispatch, { apiService, filter }) => {
  return bindActionCreators(
    {
      getUsersInside: getUsersInside(apiService, filter),
    },
    dispatch,
  );
};

export default compose(
  withApiService(),
  connect(mapStateToProps, mapDispatchToProps),
)(EditOrAddShopForm);
