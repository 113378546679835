import {
  FETCH_GET_COMPLAINTS_REQUEST,
  FETCH_GET_COMPLAINTS_FAILURE,
  FETCH_GET_COMPLAINTS_SUCCESS,
  FETCH_UPDATE_COMPLAINT_REQUEST,
  FETCH_UPDATE_COMPLAINT_FAILURE,
  FETCH_UPDATE_COMPLAINT_SUCCESS,
  FETCH_MASS_UPDATE_COMPLAINTS_REQUEST,
  FETCH_MASS_UPDATE_COMPLAINTS_SUCCESS,
  FETCH_MASS_UPDATE_COMPLAINTS_FAILURE,
} from "./types";

const initialState = {
  complaints: [],
  complaintsTotalCount: 0,
  loading: false,
  error: null,
};

const complaints = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GET_COMPLAINTS_REQUEST:
    case FETCH_MASS_UPDATE_COMPLAINTS_REQUEST:
    case FETCH_UPDATE_COMPLAINT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_GET_COMPLAINTS_SUCCESS:
      return {
        ...state,
        loading: false,
        complaints: action.payload.entities,
        complaintsTotalCount: action.payload.count,
      };

    case FETCH_MASS_UPDATE_COMPLAINTS_SUCCESS:
    case FETCH_UPDATE_COMPLAINT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case FETCH_GET_COMPLAINTS_FAILURE:
    case FETCH_MASS_UPDATE_COMPLAINTS_FAILURE:
    case FETCH_UPDATE_COMPLAINT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};

export default complaints;
