import React from "react";

import "./RemoveArticlesForm.scss";
import { Button, Form } from "../../../components/FormItems";

const RemoveArticlesForm = ({ onCloseModal, onRemove }) => {
  return (
    <div className='RemoveArticlesForm'>
      <Form title='Удаление статей'>
        <div className='description mt2 mb2'>
          Вы действительно хотите удалить выбранные статьи ?
        </div>
        <Button
          filled={true}
          onPress={() => {
            onRemove();
            onCloseModal();
          }}
        >
          Удалить
        </Button>
        <div className='mt2'></div>
        <Button
          onPress={() => {
            onCloseModal();
          }}
        >
          Отменить
        </Button>
      </Form>
    </div>
  );
};

export default RemoveArticlesForm;
