import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withApiService } from "../../../components/hoc";
import compose from "../../../helpers/compose";
import {
  getUsersInside,
  createUserInside,
  updateUserInside,
  removeUsersInside,
} from "../../../redux/usersInside/actions";

import "./UsersInside.scss";
import { ReactComponent as FilterIcon } from "../../../assets/images/filterIcon.svg";
import { ReactComponent as EditIcon } from "../../../assets/images/editIcon.svg";
import { ReactComponent as PlusIcon } from "../../../assets/images/plusIcon.svg";

import { Button, CheckBox, Select } from "../../../components/FormItems";
import { Table } from "../../../components/Tables";
import Modal from "../../../components/Modal";
import EditOrAddUserInsideForm from "../EditOrAddUserInsideForm";
import RemoveUsersInsideForm from "../RemoveUsersInsideForm";
import { mapRoleIdToRoleName } from "../../../helpers/mapper";

const UsersInside = ({
  usersInside,
  usersInsideTotalCount,
  loading,
  getUsersInside,
  createUserInside,
  updateUserInside,
  removeUsersInside,
}) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [usersFilter, setUsersFilter] = useState({ limit: 10, offset: 0 });

  const [showAddOrEditUserModal, setShowAddOrEditUserModal] = useState(false);
  const [selectedUserForEdit, setSelectedUserForEdit] = useState({});

  const [showRemoveUserModal, setShowRemoveUserModal] = useState(false);

  const updateUsersFilter = changedFields => {
    setUsersFilter(prevState => {
      return { ...prevState, ...changedFields };
    });
    getUsersInside({ ...usersFilter, ...changedFields });
  };

  useEffect(() => {
    getUsersInside(usersFilter);
  }, []);

  const columns = [
    {
      title: "",
      dataIndex: "",
      width: "5%",
      render: rowData => {
        return (
          <CheckBox
            valuesList={selectedRows}
            onChange={selectedValues => {
              setSelectedRows(selectedValues);
            }}
            value={rowData}
          />
        );
      },
    },
    {
      title: "Логин",
      dataIndex: "username",
      width: "25%",
      sorter: true,
    },
    {
      title: "Роль",
      dataIndex: "role",
      width: "65%",
      sorter: true,
      render: rowData => {
        return rowData.role !== undefined
          ? mapRoleIdToRoleName[rowData.role]
          : "";
      },
    },

    {
      title: "",
      dataIndex: "status",
      width: "5%",
      render: rowData => {
        return (
          <EditIcon
            style={{ fill: "#002534" }}
            onClick={() => {
              setSelectedUserForEdit(rowData);
              setShowAddOrEditUserModal(true);
            }}
          />
        );
      },
    },
  ];

  return (
    <div className='page-content'>
      <Modal
        onCloseModal={() => {
          setShowAddOrEditUserModal(false);
          setSelectedUserForEdit({});
        }}
        displayModal={showAddOrEditUserModal}
      >
        <EditOrAddUserInsideForm
          userData={selectedUserForEdit}
          createUser={userData => createUserInside(userData, usersFilter)}
          updateUser={userData => updateUserInside(userData, usersFilter)}
        />
      </Modal>
      <Modal
        onCloseModal={() => {
          setShowRemoveUserModal(false);
        }}
        displayModal={showRemoveUserModal}
      >
        <RemoveUsersInsideForm
          onRemove={() => {
            removeUsersInside(selectedRows, usersFilter);
            setSelectedRows([]);
          }}
        />
      </Modal>
      <div className='table-filters mb2'>
        <Select
          placeholder='Фильтр по роли'
          width={"200px"}
          onSelect={value => {
            updateUsersFilter({ role: value, offset: 0 });
          }}
          selectedValue={usersFilter["role"]}
          options={[
            { id: 0, name: "Администратор" },
            { id: 1, name: "Модератор" },
            { id: 2, name: "Контент-менеджер" },
          ]}
          icon={<FilterIcon style={{ marginTop: 5 }} />}
        />

        <div className='table-filters'>
          <Button
            filled={true}
            minHeight={40}
            onPress={() => {
              setShowAddOrEditUserModal(true);
              setSelectedUserForEdit({});
            }}
          >
            <PlusIcon style={{ marginRight: 3, marginBottom: -2 }} /> Добавить
            пользователя
          </Button>
          <div className='mr2'></div>
          <Button
            disabled={!selectedRows.length > 0}
            minHeight={40}
            minWidth={160}
            onPress={() => {
              setShowRemoveUserModal(true);
            }}
          >
            Удалить
          </Button>
        </div>
      </div>
      <Table
        loading={loading} // Заменить на боевые данные
        dataSource={usersInside} // Заменить на боевые данные
        columns={columns}
        onClickRow={() => {}}
        highlightRow={rowData => {
          return selectedRows.findIndex(item => item.id === rowData.id) > -1;
        }}
        pagination={{
          total: usersInsideTotalCount,
          offset: usersFilter.offset,
          limit: usersFilter.limit,
        }}
        onChange={(pagination, sorter) => {
          let changedFields = {
            ...pagination,
            ...sorter,
          };
          updateUsersFilter(changedFields);
        }}
      />
    </div>
  );
};

const mapStateToProps = ({ usersInside }) => ({
  usersInside: usersInside.usersInside,
  usersInsideTotalCount: usersInside.usersInsideTotalCount,
  loading: usersInside.loading,
});
const mapDispatchToProps = (
  dispatch,
  { apiService, filter, users, userData },
) => {
  return bindActionCreators(
    {
      getUsersInside: getUsersInside(apiService, filter),
      createUserInside: createUserInside(apiService, userData, filter),
      updateUserInside: updateUserInside(apiService, userData, filter),
      removeUsersInside: removeUsersInside(apiService, users, filter),
    },
    dispatch,
  );
};

export default compose(
  withApiService(),
  connect(mapStateToProps, mapDispatchToProps),
)(UsersInside);
