import React from "react";
import "./NumInput.scss";

const NumInput = ({
  type,
  name,
  placeholder,
  onChange,
  value,
  maxLength = 999999,
}) => {
  return (
    <input
      type={type}
      name={name}
      placeholder={placeholder}
      value={value || ""}
      className='NumInput'
      onChange={e => {
        if (e.target.value.length <= maxLength && !isNaN(e.target.value)) {
          onChange(e.target.value);
        }
      }}
    />
  );
};

export default NumInput;
