import React from "react";

import "./Pagination.scss";

const getPaginationButtonsByCurrent = (
  currentPage,
  lastPageNumber,
  maxElements,
) => {
  let pagCount = 0;
  let pagination = [];

  // Добавляем элементы идущие перед текущим
  for (
    let i = currentPage - Math.floor(maxElements / 2);
    i < currentPage;
    i++
  ) {
    if (i >= 1 && i < lastPageNumber) {
      pagination.push(i);
    }
  }
  pagCount = pagination.length;

  // Добавляем текущий элемент если он не выводится последним
  if (lastPageNumber !== currentPage) {
    pagination.push(currentPage);
    pagCount = pagination.length;
  }

  // Добавляем элементы идущие после текущего
  for (
    let i = currentPage + 1;
    i < currentPage + maxElements - pagCount + 1;
    i++
  ) {
    if (i < lastPageNumber && i >= 1) {
      pagination.push(i);
    }
  }
  pagCount = pagination.length;

  // Если нехватает страниц добавляем еще в начало
  if (pagination.length < maxElements) {
    // Высчитываем какой элемент должен быть первым
    let firstItem = pagination[0] - (maxElements - pagCount);

    for (let i = pagination[0] - 1; i >= firstItem; i--) {
      if (i >= 1) {
        pagination.unshift(i);
      }
    }

    pagCount = pagination.length - pagCount;
  }

  return pagination;
};

const Pagination = ({
  total,
  offset,
  limit,
  maxElements = 5,
  onChange = () => {},
}) => {
  let lastPageNumber = Math.ceil(total / limit);
  let currentPage = Math.ceil(offset / limit) + 1;

  let pagItems = getPaginationButtonsByCurrent(
    currentPage,
    lastPageNumber,
    maxElements,
  );

  return (
    <div className='Pagination'>
      {pagItems.map(pagPageNum => {
        return (
          <button
            key={"pagButton" + pagPageNum}
            onClick={() => onChange(pagPageNum)}
            className={pagPageNum === currentPage ? "active" : ""}
          >
            {pagPageNum}
          </button>
        );
      })}
      ...
      <button
        className={lastPageNumber === currentPage ? "active" : ""}
        onClick={() => onChange(lastPageNumber)}
      >
        {lastPageNumber}
      </button>
    </div>
  );
};

export default Pagination;
