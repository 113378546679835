export const FETCH_GET_ARTICLES_REQUEST = "FETCH_GET_ARTICLES_REQUEST";
export const FETCH_GET_ARTICLES_FAILURE = "FETCH_GET_ARTICLES_FAILURE";
export const FETCH_GET_ARTICLES_SUCCESS = "FETCH_GET_ARTICLES_SUCCESS";

export const FETCH_REMOVE_ARTICLES_REQUEST = "FETCH_REMOVE_ARTICLES_REQUEST";
export const FETCH_REMOVE_ARTICLES_FAILURE = "FETCH_REMOVE_ARTICLES_FAILURE";
export const FETCH_REMOVE_ARTICLES_SUCCESS = "FETCH_REMOVE_ARTICLES_SUCCESS";

export const FETCH_CREATE_ARTICLE_REQUEST = "FETCH_CREATE_ARTICLE_REQUEST";
export const FETCH_CREATE_ARTICLE_FAILURE = "FETCH_CREATE_ARTICLE_FAILURE";
export const FETCH_CREATE_ARTICLE_SUCCESS = "FETCH_CREATE_ARTICLE_SUCCESS";

export const FETCH_UPDATE_ARTICLE_REQUEST = "FETCH_UPDATE_ARTICLE_REQUEST";
export const FETCH_UPDATE_ARTICLE_FAILURE = "FETCH_UPDATE_ARTICLE_FAILURE";
export const FETCH_UPDATE_ARTICLE_SUCCESS = "FETCH_UPDATE_ARTICLE_SUCCESS";

export const FETCH_MASS_UPDATE_ARTICLES_REQUEST =
  "FETCH_MASS_UPDATE_ARTICLES_REQUEST";
export const FETCH_MASS_UPDATE_ARTICLES_FAILURE =
  "FETCH_MASS_UPDATE_ARTICLES_FAILURE";
export const FETCH_MASS_UPDATE_ARTICLES_SUCCESS =
  "FETCH_MASS_UPDATE_ARTICLES_SUCCESS";
