import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withApiService } from "../../../components/hoc";
import compose from "../../../helpers/compose";
import {
  getLanguages,
  getTranslates,
  updateTranslates,
} from "../../../redux/translates/actions";
import { withRouter } from "react-router-dom";

import "./TranslatesPage.scss";
import { Button } from "../../../components/FormItems";
import { EditableTable } from "../../../components/Tables";

import LoadingScreen from "../../../components/LoadingScreen";
const TranslatesPage = ({
  translates,
  translatesTotalCount,
  languages,
  loading,
  getLanguages,
  getTranslates,
  updateTranslates,
  match,
  history,
}) => {
  useEffect(() => {
    getTranslates({ limit: 99999 });
    getLanguages();
  }, []);

  useEffect(() => {
    if (
      translates &&
      translates.length > 0 &&
      languages &&
      languages.length > 0
    ) {
      setSizeTableData(formatServerTranslateDataToTable(translates, languages));
    }
  }, [translates, languages]);

  const formatServerTranslateDataToTable = (translateObject, languagesRow) => {
    let formatedData = [];
    let tmpRow = [];

    translateObject.forEach(rowData => {
      tmpRow = [rowData.name];

      languagesRow.forEach(({ code }, index) => {
        let translateByCode = rowData.data.find(
          item => item.languageCode === code,
        );

        tmpRow.push(translateByCode ? translateByCode.data : "");
      });

      formatedData.push(tmpRow);

      tmpRow = [];
    });

    return formatedData;
  };

  const formatTableDataToServer = (tableDataset, languagesData) => {
    let newRows = [];

    tableDataset.forEach((row, rowIndex) => {
      // записываем идентификатор строки

      let tmpRow = { name: row[0], data: [] };

      // Заполняем массив с переводами фразы
      languagesData.forEach(({ code }, cellIndex) => {
        if (row[cellIndex + 1]) {
          tmpRow.data.push({ languageCode: code, data: row[cellIndex + 1] });
        }
      });

      newRows.push(tmpRow);
    });

    return newRows;
  };

  const [sizeTableData, setSizeTableData] = useState([]);

  let titlesColumnsData = [{ width: 180, name: "Идентификатор" }];
  if (languages && languages.length > 0) {
    titlesColumnsData = [
      ...titlesColumnsData,
      ...languages.map(language => {
        return { width: 180, name: language.name };
      }),
    ];
  }

  return (
    <div className='page-content '>
      {loading ? (
        <LoadingScreen />
      ) : (
        <>
          <div className='table-filters mb2 mr2'>
            <div />
            <Button
              filled={true}
              minWidth={240}
              onPress={() => {
                updateTranslates(
                  formatTableDataToServer(sizeTableData, languages),
                );
              }}
            >
              Сохранить
            </Button>
          </div>
          <div className='TranslatesPage'>
            <EditableTable
              withBorders={false}
              columns={titlesColumnsData}
              showOnAddCol={false}
              showOnRemoveCol={false}
              tableWidth={"max-content"}
              tableDataset={sizeTableData}
              onChange={newTableDataset => {
                setSizeTableData(newTableDataset);
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = ({ translates }) => ({
  loading: translates.loading,
  error: translates.error,
  translates: translates.translates,
  translatesTotalCount: translates.translatesTotalCount,
  languages: translates.languages,
});
const mapDispatchToProps = (
  dispatch,
  { apiService, filter, translatesData },
) => {
  return bindActionCreators(
    {
      getLanguages: getLanguages(apiService),
      getTranslates: getTranslates(apiService, filter),
      updateTranslates: updateTranslates(apiService, translatesData),
    },
    dispatch,
  );
};

export default compose(
  withApiService(),
  connect(mapStateToProps, mapDispatchToProps),
)(withRouter(TranslatesPage));
