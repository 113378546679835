import {
  FETCH_GET_MODERATION_ARTICLES_REQUEST,
  FETCH_GET_MODERATION_ARTICLES_FAILURE,
  FETCH_GET_MODERATION_ARTICLES_SUCCESS,
  FETCH_UPDATE_MODERATION_ARTICLE_REQUEST,
  FETCH_UPDATE_MODERATION_ARTICLE_FAILURE,
  FETCH_UPDATE_MODERATION_ARTICLE_SUCCESS,
  FETCH_MASS_UPDATE_MODERATION_ARTICLES_REQUEST,
  FETCH_MASS_UPDATE_MODERATION_ARTICLES_SUCCESS,
  FETCH_MASS_UPDATE_MODERATION_ARTICLES_FAILURE,
} from "./types";

const initialState = {
  moderationArticles: [],
  moderationArticlesTotalCount: 0,
  loading: false,
  error: null,
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GET_MODERATION_ARTICLES_REQUEST:
    case FETCH_MASS_UPDATE_MODERATION_ARTICLES_REQUEST:
    case FETCH_UPDATE_MODERATION_ARTICLE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_GET_MODERATION_ARTICLES_SUCCESS:
      return {
        ...state,
        loading: false,
        moderationArticles: action.payload.entities,
        moderationArticlesTotalCount: action.payload.count,
      };

    case FETCH_MASS_UPDATE_MODERATION_ARTICLES_SUCCESS:
    case FETCH_UPDATE_MODERATION_ARTICLE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case FETCH_GET_MODERATION_ARTICLES_FAILURE:
    case FETCH_MASS_UPDATE_MODERATION_ARTICLES_FAILURE:
    case FETCH_UPDATE_MODERATION_ARTICLE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};

export default auth;
