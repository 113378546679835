import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withApiService } from "../../../components/hoc";
import compose from "../../../helpers/compose";
import {
  getBrands,
  createBrand,
  updateBrand,
  removeBrands,
} from "../../../redux/brands/actions";

import { getLanguages } from "../../../redux/translates/actions";

import "./BrandsPage.scss";
import { ReactComponent as EditIcon } from "../../../assets/images/editIcon.svg";
import { ReactComponent as PlusIcon } from "../../../assets/images/plusIcon.svg";
import { Button, CheckBox } from "../../../components/FormItems";
import { Table } from "../../../components/Tables";
import Modal from "../../../components/Modal";
import EditOrAddBrandForm from "../EditOrAddBrandForm";
import RemoveBrandsForm from "../RemoveBrandsForm";

const BrandsPage = ({
  brands,
  brandsTotalCount,
  loading,
  languages = [],
  getLanguages,
  getBrands,
  createBrand,
  updateBrand,
  removeBrands,
}) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [filter, setFilter] = useState({ limit: 10, offset: 0 });

  useEffect(() => {
    getBrands(filter);
    getLanguages();
  }, []);

  const [showAddOrEditModal, setShowAddOrEditModal] = useState(false);
  const [selectedBrandForEdit, setSelectedBrandForEdit] = useState({});

  const [showRemoveForm, setShowRemoveForm] = useState(false);
  const updateFilter = changedFields => {
    setFilter(prevState => {
      return { ...prevState, ...changedFields };
    });

    getBrands({ ...filter, ...changedFields });
  };
  const columns = [
    {
      title: "",
      dataIndex: "",
      width: "5%",
      render: rowData => {
        return (
          <CheckBox
            valuesList={selectedRows}
            onChange={selectedValues => {
              setSelectedRows(selectedValues);
            }}
            value={rowData}
          />
        );
      },
    },
    {
      title: "Название",
      dataIndex: "name",
      width: "90%",
      sorter: true,
      render: rowData => {
        return rowData.name ? rowData.name[0].data : "";
      },
    },
    {
      title: "",
      dataIndex: "",
      width: "5%",
      render: rowData => {
        return (
          <EditIcon
            style={{ fill: "#002534" }}
            onClick={() => {
              setSelectedBrandForEdit(rowData);
              setShowAddOrEditModal(true);
            }}
          />
        );
      },
    },
  ];

  return (
    <div className='page-content'>
      <Modal
        maxWidth='800px'
        onCloseModal={() => {
          setShowAddOrEditModal(false);
          setSelectedBrandForEdit({});
        }}
        displayModal={showAddOrEditModal}
      >
        <EditOrAddBrandForm
          languages={languages}
          brandData={selectedBrandForEdit}
          createBrand={brandData => {
            createBrand(brandData, filter);
          }}
          updateBrand={brandData => {
            updateBrand(brandData, filter);
          }}
        />
      </Modal>

      <Modal
        displayModal={showRemoveForm}
        onCloseModal={() => {
          setShowRemoveForm(false);
        }}
      >
        <RemoveBrandsForm
          onRemove={() => {
            removeBrands(selectedRows, filter);
            setSelectedRows([]);
          }}
        />
      </Modal>
      <div className='table-filters mb2'>
        <div />
        <div className='table-filters'>
          <Button
            filled={true}
            minHeight={40}
            onPress={() => {
              setSelectedBrandForEdit({});
              setShowAddOrEditModal(true);
            }}
          >
            <PlusIcon style={{ marginRight: 3, marginBottom: -2 }} /> Добавить
            бренд
          </Button>
          <div className='mr2'></div>
          <Button
            disabled={!selectedRows.length > 0}
            minHeight={40}
            minWidth={160}
            onPress={() => {
              setShowRemoveForm(true);
            }}
          >
            Удалить
          </Button>
        </div>
      </div>
      <Table
        loading={loading}
        dataSource={brands}
        columns={columns}
        onClickRow={() => {}}
        highlightRow={rowData => {
          return selectedRows.findIndex(item => item.id === rowData.id) > -1;
        }}
        pagination={{
          total: brandsTotalCount,
          offset: filter.offset,
          limit: filter.limit,
        }}
        onChange={(pagination, sorter) => {
          let changedFields = {
            ...pagination,
            ...sorter,
          };
          updateFilter(changedFields);
        }}
      />
    </div>
  );
};

const mapStateToProps = ({ brands, translates }) => ({
  brands: brands.brands,
  brandsTotalCount: brands.brandsTotalCount,
  loading: brands.loading,
  languages: translates.languages,
});
const mapDispatchToProps = (
  dispatch,
  { apiService, filter, brands, brandData },
) => {
  return bindActionCreators(
    {
      getLanguages: getLanguages(apiService),
      getBrands: getBrands(apiService, filter),
      createBrand: createBrand(apiService, brandData, filter),
      updateBrand: updateBrand(apiService, brandData, filter),
      removeBrands: removeBrands(apiService, brands, filter),
    },
    dispatch,
  );
};

export default compose(
  withApiService(),
  connect(mapStateToProps, mapDispatchToProps),
)(BrandsPage);
