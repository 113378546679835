import {
  FETCH_GET_SIZES_REQUEST,
  FETCH_GET_SIZES_FAILURE,
  FETCH_GET_SIZES_SUCCESS,
  FETCH_REMOVE_SIZES_REQUEST,
  FETCH_REMOVE_SIZES_FAILURE,
  FETCH_REMOVE_SIZES_SUCCESS,
  FETCH_CREATE_SIZE_REQUEST,
  FETCH_CREATE_SIZE_FAILURE,
  FETCH_CREATE_SIZE_SUCCESS,
  FETCH_UPDATE_SIZE_REQUEST,
  FETCH_UPDATE_SIZE_FAILURE,
  FETCH_UPDATE_SIZE_SUCCESS,
} from "./types";

const initialState = {
  sizes: [{ name: "", rows: [{ name: "", values: [] }] }],
  sizesTotalCount: 0,
  loading: false,
  error: null,
};

const sizes = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GET_SIZES_REQUEST:
    case FETCH_REMOVE_SIZES_REQUEST:
    case FETCH_CREATE_SIZE_REQUEST:
    case FETCH_UPDATE_SIZE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_GET_SIZES_SUCCESS:
      return {
        ...state,
        loading: false,
        sizes: action.payload.entities || action.payload,
        sizesTotalCount: action.payload.count || null,
      };

    case FETCH_REMOVE_SIZES_SUCCESS:
    case FETCH_CREATE_SIZE_SUCCESS:
    case FETCH_UPDATE_SIZE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case FETCH_GET_SIZES_FAILURE:
    case FETCH_REMOVE_SIZES_FAILURE:
    case FETCH_CREATE_SIZE_FAILURE:
    case FETCH_UPDATE_SIZE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};

export default sizes;
