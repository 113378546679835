import {
  getCategoriesRequest,
  removeCategoriesRequest,
  createCategoryRequest,
  updateCategoryRequest,
  getCategoriesFailure,
  removeCategoriesFailure,
  createCategoryFailure,
  updateCategoryFailure,
  getCategoriesSuccess,
  removeCategoriesSuccess,
  createCategorySuccess,
  updateCategorySuccess,
} from "./actionCreators";

const getCategories = api => filter => dispatch => {
  dispatch(getCategoriesRequest());
  api
    .getCategories(filter)
    .then(res => {
      console.log(res, "res getCategories");
      dispatch(getCategoriesSuccess(res.data));
    })
    .catch(err => {
      console.log(err, "res getCategories");
      dispatch(
        getCategoriesFailure({
          status: "error",
          text: `Возникла ошибка с кодом ${
            err.response ? err.response.status : "???"
          } `,
        }),
      );
    });
};

const createCategory = api => (categoryData, filter) => dispatch => {
  dispatch(createCategoryRequest());

  api
    .createCategory(categoryData)
    .then(res => {
      console.log(res, "res createCategory");
      dispatch(createCategorySuccess());

      getCategories(api)(filter)(dispatch);
    })
    .catch(err => {
      console.log(err, "err createCategory");
      dispatch(
        createCategoryFailure({
          status: "error",
          text: `Возникла ошибка с кодом ${
            err.response ? err.response.status : "???"
          } `,
        }),
      );
    });
};

const updateCategory = api => (categoryData, filter) => dispatch => {
  dispatch(updateCategoryRequest());

  api
    .updateCategory(categoryData)
    .then(res => {
      console.log(res, "res updateCategory");
      dispatch(updateCategorySuccess());

      getCategories(api)(filter)(dispatch);
    })
    .catch(err => {
      console.log(err, "err updateCategory");
      dispatch(
        updateCategoryFailure({
          status: "error",
          text: `Возникла ошибка с кодом ${
            err.response ? err.response.status : "???"
          } `,
        }),
      );
    });
};

const removeCategories = api => (categories = [], filter) => dispatch => {
  dispatch(removeCategoriesRequest());
  api
    .removeCategories(categories.map(category => category.id))
    .then(res => {
      console.log(res, "res removeCategories");
      dispatch(removeCategoriesSuccess());

      getCategories(api)(filter)(dispatch);
    })
    .catch(err => {
      console.log(err, "err removeCategories");
      dispatch(
        removeCategoriesFailure({
          status: "error",
          text: `Возникла ошибка с кодом ${
            err.response ? err.response.status : "???"
          } `,
        }),
      );
    });
};

export { getCategories, createCategory, updateCategory, removeCategories };
