import {
  FETCH_GET_MODERATION_LOOKS_REQUEST,
  FETCH_GET_MODERATION_LOOKS_FAILURE,
  FETCH_GET_MODERATION_LOOKS_SUCCESS,
  FETCH_UPDATE_MODERATION_LOOK_REQUEST,
  FETCH_UPDATE_MODERATION_LOOK_FAILURE,
  FETCH_UPDATE_MODERATION_LOOK_SUCCESS,
  FETCH_MASS_UPDATE_MODERATION_LOOKS_REQUEST,
  FETCH_MASS_UPDATE_MODERATION_LOOKS_SUCCESS,
  FETCH_MASS_UPDATE_MODERATION_LOOKS_FAILURE,
} from "./types";

export const getModerationLooksRequest = () => {
  return { type: FETCH_GET_MODERATION_LOOKS_REQUEST };
};

export const updateModerationLookRequest = () => {
  return { type: FETCH_UPDATE_MODERATION_LOOK_REQUEST };
};

export const getModerationLooksFailure = error => {
  return { type: FETCH_GET_MODERATION_LOOKS_FAILURE, payload: error };
};

export const updateModerationLookFailure = error => {
  return { type: FETCH_UPDATE_MODERATION_LOOK_FAILURE, payload: error };
};

export const getModerationLooksSuccess = moderationData => {
  return {
    type: FETCH_GET_MODERATION_LOOKS_SUCCESS,
    payload: moderationData,
  };
};
export const updateModerationLookSuccess = () => {
  return { type: FETCH_UPDATE_MODERATION_LOOK_SUCCESS };
};

export const massUpdateModerationLooksRequest = () => {
  return { type: FETCH_MASS_UPDATE_MODERATION_LOOKS_REQUEST };
};

export const massUpdateModerationLooksSuccess = () => {
  return { type: FETCH_MASS_UPDATE_MODERATION_LOOKS_SUCCESS };
};

export const massUpdateModerationLooksFailure = error => {
  return {
    type: FETCH_MASS_UPDATE_MODERATION_LOOKS_FAILURE,
    payload: error,
  };
};
