import React, { useState, useEffect } from "react";
import "./EditOrAddCategoryForm.scss";
import {
  Form,
  TextInput,
  Button,
  SelectInput,
  SearchInput,
} from "../../../components/FormItems";
import {
  mapGenderIdToName,
  mapProductTypeIdToName,
} from "../../../helpers/mapper";

import Stepper from "../../../components/Stepper";

const getAvailableTranslates = (translates, languages) => {
  let tmpAvailableLanguages = [];
  let formatTranslates = translates ? translates : [];

  languages.map(language => {
    let findTranslate = formatTranslates.find(
      translate => translate.languageCode === language.code,
    );
    if (!findTranslate) {
      tmpAvailableLanguages.push({ ...language, languageCode: language.code });
    }
  });
  return tmpAvailableLanguages;
};

const changeSelectedTranslate = (
  translates,
  currentTranslate,
  nextTranslateIndex,
) => {
  // Находим индекс текущего перевода в списке пеереводов
  let formatTranslates = translates ? translates : [];

  let currentTranslateIndex = formatTranslates.findIndex(
    translate => translate.languageCode === currentTranslate.languageCode,
  );

  if (formatTranslates[currentTranslateIndex + nextTranslateIndex]) {
    return { ...formatTranslates[currentTranslateIndex + nextTranslateIndex] };
  } else {
    return nextTranslateIndex > 0
      ? formatTranslates[0]
      : formatTranslates[formatTranslates.length - 1];
  }
};

const addNewTranslateToFormTranslates = (addededTranslate, formTranslates) => {
  return [
    ...formTranslates,
    { data: "", languageCode: addededTranslate.languageCode },
  ];
};

const onChangeTranslatedField = (value, translate, formTranslates) => {
  // Находим перевод который нужно изменить
  let changedTranslateIndex = formTranslates.findIndex(
    formTranslate => formTranslate.languageCode === translate.languageCode,
  );

  if (changedTranslateIndex > -1) {
    formTranslates[changedTranslateIndex].data = value;
    return [...formTranslates];
  } else {
    return [
      ...formTranslates,
      { data: value, languageCode: translate.languageCode },
    ];
  }
};

const EditOrAddCategoryForm = ({
  onCloseModal,
  categoryData = {},
  updateCategory,
  createCategory,
  searchCategoriesResults,
  searchCategories,
  languages = [],
}) => {
  const isEdit = categoryData.name ? true : false;

  const [categoryName, setCategoryName] = useState([
    { languageCode: "en", data: "" },
  ]);
  const [categoryParent, setCategoryParent] = useState({});
  const [categoryProductType, setCategoryProductType] = useState({});
  const [categoryGender, setCategoryGender] = useState({});

  const [selectedTranslate, setSelectedTranslate] = useState({
    languageCode: "en",
  });

  const [selectedTranslateToAdd, setSelectedTranslateToAdd] = useState({});
  useEffect(() => {
    setCategoryName(
      categoryData.name
        ? categoryData.name
        : [{ languageCode: "en", data: "" }],
    ) ;
    setCategoryProductType(
      {
        id: categoryData.itemType,
        name: mapProductTypeIdToName[categoryData.itemType],
      } || {},
    );
    setCategoryGender(
      {
        id: categoryData.gender,
        name: mapGenderIdToName[categoryData.gender],
      } || {},
    );
    setCategoryParent(categoryData.parent || {});
  }, [categoryData]);

  let selectedTranslateName = languages.find(
    language => language.code === selectedTranslate.languageCode,
  );
  let selectedTranslateCategoryValue = categoryName.find(
    brand => brand.languageCode === selectedTranslate.languageCode,
  );
  // Подготавливаем список языков которые можно добавить
  let availableToAddTranslates = getAvailableTranslates(
    categoryName,
    languages,
  );

  return (
    <Stepper
      onNextStep={() => {
        setSelectedTranslate(
          changeSelectedTranslate(categoryName, selectedTranslate, 1),
        );
      }}
      onPrevStep={() => {
        setSelectedTranslate(
          changeSelectedTranslate(categoryName, selectedTranslate, -1),
        );
      }}
    >
      <div className='EditOrAddCategoryForm'>
        <Form title=''>
          <div className='translate-flex-row'>
            <div className='translate-flex-col'>
              <div className='translate-title'>
                Добавление/редактирование категории
              </div>
              <div className='translate-count'>
                Переводов: {categoryName.length}
              </div>
              <div className='translate-language'>
                Выбран:{" "}
                {selectedTranslateName ? selectedTranslateName.name : ""}
              </div>
            </div>
            <div className='translate-flex-row'>
              <SelectInput
                placeholder='Перевод'
                onSelect={value => setSelectedTranslateToAdd(value)}
                selectedValue={selectedTranslateToAdd}
                options={availableToAddTranslates}
              />
              <div className='mr2'></div>
              <Button
                disabled={!selectedTranslateToAdd.name}
                filled={true}
                onPress={() => {
                  // Добавляем перевод в список переводов
                  setCategoryName(
                    addNewTranslateToFormTranslates(
                      selectedTranslateToAdd,
                      categoryName,
                    ),
                  );
                  // Сбрасываем выбранный для добавления язык
                  setSelectedTranslateToAdd({});

                  // Переключаемся на последний перевод
                }}
              >
                Добавить перевод
              </Button>
            </div>
          </div>
          <div className='EditOrAddUserInsideForm__inputsBlock'>
            <TextInput
              type='text'
              name='categoryName'
              placeholder='Название'
              onChange={value => {
                setCategoryName(
                  onChangeTranslatedField(
                    value,
                    selectedTranslate,
                    categoryName,
                  ),
                );
              }}
              value={
                selectedTranslateCategoryValue
                  ? selectedTranslateCategoryValue.data
                  : ""
              }
            />

            {categoryData.children && categoryData.children.length > 0 ? (
              <div className='childCategories'>
                {categoryData.children.map(child => {
                  return <div className='categoryChild'>{child.name}</div>;
                })}
              </div>
            ) : (
              ""
            )}
            <SearchInput
              placeholder='Родительская категория'
              onSelect={value => {
                setCategoryParent(value);
              }}
              searchResults={searchCategoriesResults}
              searchRequest={val => {
                searchCategories(val);
              }}
              selectedValue={categoryParent}
            />
            <SelectInput
              placeholder='Гендер'
              onSelect={value => {
                setCategoryGender(value);
              }}
              selectedValue={categoryGender}
              options={[
                { id: 1, name: "Женский" },
                { id: 2, name: "Мужской" },
                { id: 3, name: "Детский" },
              ]}
            />
            <SelectInput
              placeholder='Тип товара'
              onSelect={value => {
                setCategoryProductType(value);
              }}
              selectedValue={categoryProductType}
              options={[
                { id: 1, name: "Аксессуары" },
                { id: 2, name: "Одежда" },
                { id: 3, name: "Обувь" },
                { id: 4, name: "Головные уборы" },
              ]}
            />
          </div>
          <Button
            filled={true}
            disabled={
              !categoryName && !categoryProductType.name && !categoryGender.name
            }
            onPress={() => {
              isEdit
                ? updateCategory({
                    id: categoryData.id,
                    name: categoryName,
                    parentCategoryId: categoryParent.id || null,
                    itemType: categoryProductType.id,
                    gender: categoryGender.id,
                  })
                : createCategory({
                    name: categoryName,
                    parentCategoryId: categoryParent.id || null,
                    itemType: categoryProductType.id,
                    gender: categoryGender.id,
                  });

              onCloseModal();
            }}
          >
            Сохранить
          </Button>
          <div className='mt2'></div>
          <Button
            onPress={() => {
              onCloseModal();
            }}
          >
            Отменить
          </Button>
        </Form>
      </div>
    </Stepper>
  );
};

export default EditOrAddCategoryForm;
