import {
  SEARCH_CATEGORIES_SUCCESS,
  SEARCH_SIZES_SUCCESS,
  SEARCH_COLORS_SUCCESS,
  SEARCH_MATERIALS_SUCCESS,
  SEARCH_BRANDS_SUCCESS,
  SEARCH_SHOPS_SUCCESS,
} from "./types";

const initialState = {
  categories: [],
  sizeTables: [],
  colors: [],
  materials: [],
  brands: [],
  shops: [],
};
const search = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_CATEGORIES_SUCCESS:
      return { ...state, categories: action.payload.entities };

    case SEARCH_SIZES_SUCCESS:
      return { ...state, sizeTables: action.payload.entities };

    case SEARCH_COLORS_SUCCESS:
      return { ...state, colors: action.payload.entities };

    case SEARCH_MATERIALS_SUCCESS:
      return { ...state, materials: action.payload.entities };

    case SEARCH_BRANDS_SUCCESS:
      return { ...state, brands: action.payload.entities };

    case SEARCH_SHOPS_SUCCESS:
      return { ...state, shops: action.payload.entities };

    default:
      return { ...state };
  }
};

export default search;
