import {
  FETCH_GET_PRODUCTS_REQUEST,
  FETCH_GET_PRODUCTS_FAILURE,
  FETCH_GET_PRODUCTS_SUCCESS,
  FETCH_REMOVE_PRODUCTS_REQUEST,
  FETCH_REMOVE_PRODUCTS_FAILURE,
  FETCH_REMOVE_PRODUCTS_SUCCESS,
  FETCH_CREATE_PRODUCT_REQUEST,
  FETCH_CREATE_PRODUCT_FAILURE,
  FETCH_CREATE_PRODUCT_SUCCESS,
  FETCH_UPDATE_PRODUCT_REQUEST,
  FETCH_UPDATE_PRODUCT_FAILURE,
  FETCH_UPDATE_PRODUCT_SUCCESS,
} from "./types";

export const getProductsRequest = () => {
  return { type: FETCH_GET_PRODUCTS_REQUEST };
};
export const removeProductsRequest = () => {
  return { type: FETCH_REMOVE_PRODUCTS_REQUEST };
};
export const createProductRequest = () => {
  return { type: FETCH_CREATE_PRODUCT_REQUEST };
};
export const updateProductRequest = () => {
  return { type: FETCH_UPDATE_PRODUCT_REQUEST };
};

export const getProductsFailure = error => {
  return { type: FETCH_GET_PRODUCTS_FAILURE, payload: error };
};
export const removeProductsFailure = error => {
  return { type: FETCH_REMOVE_PRODUCTS_FAILURE, payload: error };
};
export const createProductFailure = error => {
  return { type: FETCH_CREATE_PRODUCT_FAILURE, payload: error };
};
export const updateProductFailure = error => {
  return { type: FETCH_UPDATE_PRODUCT_FAILURE, payload: error };
};

export const getProductsSuccess = productsData => {
  return { type: FETCH_GET_PRODUCTS_SUCCESS, payload: productsData };
};
export const removeProductsSuccess = () => {
  return { type: FETCH_REMOVE_PRODUCTS_SUCCESS };
};
export const createProductSuccess = () => {
  return { type: FETCH_CREATE_PRODUCT_SUCCESS };
};
export const updateProductSuccess = () => {
  return { type: FETCH_UPDATE_PRODUCT_SUCCESS };
};
