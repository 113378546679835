import React, { useRef, useState } from "react";
import { ReactComponent as PlusIcon } from "../../../assets/images/plusIcon.svg";
import { ReactComponent as CloseIcon } from "../../../assets/images/closeIcon.svg";

import "./ImageInput.scss";

const ImageInput = ({ value, addTitle = "", onChange = () => {}, }) => {
  const fileInput = useRef();
  const [newValue, setNewValue] = useState({});
  return (
    <div className='ImageInput'>
      <div
        style={{
          backgroundImage: `url(${
            value
              ? value.indexOf("blob:http") > -1
                ? value || newValue.image
                : window.location.origin + value
              : null
          })`,
        }}
        className='imagePresentBlock'
      >
        {(value && value !== "null") || (newValue.image && value !== "null") ? (
          <CloseIcon
            className='clearBlock'
            onClick={() => {
              //removeBtn
              onChange(value !== "null" ? value : newValue);
              // setNewValue({});
            }}
          />
        ) : (
          <div
            onClick={() => {
              fileInput.current.click();
            }}
            className='addBlock'
          >
            <PlusIcon /> {addTitle || "Добавить фото"}
          </div>
        )}
      </div>
      <input
        accept='.jpg, .jpeg, .png'
        ref={fileInput}
        onChange={e => {
          let file = e.target.files[0];
          if (file) {
            // setNewValue({
            //   file,
            //   image: URL.createObjectURL(e.target.files[0]),
            // });
            onChange({ file, image: URL.createObjectURL(e.target.files[0]) });
          }
        }}
        name='arenaLogo'
        type='file'
      />
    </div>
  );
};

export default ImageInput;
