import { LOGIN_REQUEST, LOGIN_FAILURE, LOGIN_SUCCESS, LOGOUT } from "./types";

const initialState = {
  login: localStorage.getItem("login") || null,
  token: localStorage.getItem("token") || null,
  roleId: localStorage.getItem("roleId") || null,
  loading: false,
  error: null,
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case LOGIN_SUCCESS:
      console.log(action, "action");

      return {
        ...state,
        loading: false,
        login: action.payload.login,
        token: action.payload.token,
        roleId: action.payload.roleId,
      };
    case LOGOUT:
      return {
        ...state,
        login: null,
        token: null,
        roleId: null,
      };

    default:
      return {
        ...state,
      };
  }
};

export default auth;
