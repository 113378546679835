import {
  FETCH_GET_ARTICLES_REQUEST,
  FETCH_GET_ARTICLES_FAILURE,
  FETCH_GET_ARTICLES_SUCCESS,
  FETCH_REMOVE_ARTICLES_REQUEST,
  FETCH_REMOVE_ARTICLES_FAILURE,
  FETCH_REMOVE_ARTICLES_SUCCESS,
  FETCH_CREATE_ARTICLE_REQUEST,
  FETCH_CREATE_ARTICLE_FAILURE,
  FETCH_CREATE_ARTICLE_SUCCESS,
  FETCH_UPDATE_ARTICLE_REQUEST,
  FETCH_UPDATE_ARTICLE_FAILURE,
  FETCH_UPDATE_ARTICLE_SUCCESS,
  FETCH_MASS_UPDATE_ARTICLES_REQUEST,
  FETCH_MASS_UPDATE_ARTICLES_FAILURE,
  FETCH_MASS_UPDATE_ARTICLES_SUCCESS,
} from "./types";

export const getArticlesRequest = () => {
  return { type: FETCH_GET_ARTICLES_REQUEST };
};
export const removeArticlesRequest = () => {
  return { type: FETCH_REMOVE_ARTICLES_REQUEST };
};
export const createArticleRequest = () => {
  return { type: FETCH_CREATE_ARTICLE_REQUEST };
};
export const updateArticleRequest = () => {
  return { type: FETCH_UPDATE_ARTICLE_REQUEST };
};

export const getArticlesFailure = error => {
  return { type: FETCH_GET_ARTICLES_FAILURE, payload: error };
};
export const removeArticlesFailure = error => {
  return { type: FETCH_REMOVE_ARTICLES_FAILURE, payload: error };
};
export const createArticleFailure = error => {
  return { type: FETCH_CREATE_ARTICLE_FAILURE, payload: error };
};
export const updateArticleFailure = error => {
  return { type: FETCH_UPDATE_ARTICLE_FAILURE, payload: error };
};

export const getArticlesSuccess = articlesData => {
  return { type: FETCH_GET_ARTICLES_SUCCESS, payload: articlesData };
};
export const removeArticlesSuccess = () => {
  return { type: FETCH_REMOVE_ARTICLES_SUCCESS };
};
export const createArticleSuccess = () => {
  return { type: FETCH_CREATE_ARTICLE_SUCCESS };
};
export const updateArticleSuccess = () => {
  return { type: FETCH_UPDATE_ARTICLE_SUCCESS };
};

export const massUpdateArticlesRequest = () => {
  return { type: FETCH_MASS_UPDATE_ARTICLES_REQUEST };
};

export const massUpdateArticlesFailure = error => {
  return { type: FETCH_MASS_UPDATE_ARTICLES_FAILURE, payload: error };
};

export const massUpdateArticlesSuccess = () => {
  return { type: FETCH_MASS_UPDATE_ARTICLES_SUCCESS };
};
