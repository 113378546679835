import React, { useState, useEffect } from "react";
import "./EditOrAddUserInsideForm.scss";
import {
  Form,
  TextInput,
  Button,
  SelectInput,
} from "../../../components/FormItems";

import { mapRoleIdToRoleName } from "../../../helpers/mapper";

const EditOrAddUserInsideForm = ({
  onCloseModal,
  userData = {},
  createUser,
  updateUser,
}) => {
  let isEdit = false;
  if (userData.username || userData.role) {
    isEdit = true;
  }

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState({});

  useEffect(() => {
    setUsername(userData.username || "");
    setPassword("");
    setRole(
      mapRoleIdToRoleName[userData.role]
        ? { id: userData.role, name: mapRoleIdToRoleName[userData.role] }
        : {},
    );
  }, [userData]);

  return (
    <div className='EditOrAddUserInsideForm'>
      <Form title='Добавление/редактирование'>
        <div className='EditOrAddUserInsideForm__inputsBlock'>
          <TextInput
            maxLength={32}
            type='text'
            name='username'
            placeholder='Логин'
            onChange={value => {
              setUsername(value);
            }}
            value={username}
          />
          <TextInput
            maxLength={16}
            type='text'
            name='password'
            placeholder='Пароль'
            onChange={value => {
              setPassword(value);
            }}
            value={password}
          />
          <SelectInput
            placeholder='Роль'
            onSelect={value => {
              setRole(value);
            }}
            selectedValue={role}
            options={[
              { id: 0, name: "Администратор" },
              { id: 1, name: "Модератор" },
              { id: 2, name: "Контент-менеджер" },
            ]}
          />
        </div>
        <Button
          filled={true}
          disabled={!(username && role.id !== undefined)}
          onPress={() => {
            isEdit
              ? updateUser({ id: userData.id, username, password, role })
              : createUser({ username, password, role });
            onCloseModal();
          }}
        >
          Сохранить
        </Button>
        <div className='mt2'></div>
        <Button
          onPress={() => {
            onCloseModal();
          }}
        >
          Отменить
        </Button>
      </Form>
    </div>
  );
};

export default EditOrAddUserInsideForm;
