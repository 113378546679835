import { LOGIN_REQUEST, LOGIN_FAILURE, LOGIN_SUCCESS, LOGOUT } from "./types";

export const loginRequest = () => {
  return { type: LOGIN_REQUEST };
};
export const loginFailure = error => {
  return { type: LOGIN_FAILURE, payload: error };
};
export const loginSuccess = ({ login, token, roleId }) => {
  return { type: LOGIN_SUCCESS, payload: { login, token, roleId } };
};

export const logout = () => {
  return { type: LOGOUT };
};
