import React from "react";
import "./TextInput.scss";

const TextInput = ({
  type,
  name,
  placeholder,
  onChange,
  value,
  maxLength = 999999,
  disabled = false,
  onBlur,
}) => {
  return (
    <input
      disabled={disabled}
      type={type}
      name={name}
      placeholder={placeholder}
      value={value ? value : ''}
      className='TextInput'
      onChange={e => {
        if (e.target.value.length <= maxLength) {
          onChange(e.target.value);
        }
      }}
      onBlur={ e => name == 'URLSize' ? onBlur(e.target.value) : {}}
    />
  );
};

export default TextInput;
