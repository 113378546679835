export const FETCH_GET_MODERATION_LOOKS_REQUEST =
  "FETCH_GET_MODERATION_LOOKS_REQUEST";
export const FETCH_GET_MODERATION_LOOKS_FAILURE =
  "FETCH_GET_MODERATION_LOOKS_FAILURE";
export const FETCH_GET_MODERATION_LOOKS_SUCCESS =
  "FETCH_GET_MODERATION_LOOKS_SUCCESS";

export const FETCH_UPDATE_MODERATION_LOOK_REQUEST =
  "FETCH_UPDATE_MODERATION_LOOK_REQUEST";
export const FETCH_UPDATE_MODERATION_LOOK_FAILURE =
  "FETCH_UPDATE_MODERATION_LOOK_FAILURE";
export const FETCH_UPDATE_MODERATION_LOOK_SUCCESS =
  "FETCH_UPDATE_MODERATION_LOOK_SUCCESS";

export const FETCH_MASS_UPDATE_MODERATION_LOOKS_REQUEST =
  "FETCH_MASS_UPDATE_MODERATION_LOOKS_REQUEST";
export const FETCH_MASS_UPDATE_MODERATION_LOOKS_FAILURE =
  "FETCH_MASS_UPDATE_MODERATION_LOOKS_FAILURE";
export const FETCH_MASS_UPDATE_MODERATION_LOOKS_SUCCESS =
  "FETCH_MASS_UPDATE_MODERATION_LOOKS_SUCCESS";
