import {
  FETCH_GET_TRANSLATE_REQUEST,
  FETCH_GET_TRANSLATE_FAILURE,
  FETCH_GET_TRANSLATE_SUCCESS,
  FETCH_GET_LANGUAGES_REQUEST,
  FETCH_GET_LANGUAGES_FAILURE,
  FETCH_GET_LANGUAGES_SUCCESS,
  FETCH_UPDATE_TRANSLATE_REQUEST,
  FETCH_UPDATE_TRANSLATE_FAILURE,
  FETCH_UPDATE_TRANSLATE_SUCCESS,
} from "./types";

const initialState = {
  translates: [],
  translatesTotalCount: null,
  languages: [],
  loading: false,
  error: null,
};

const translates = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GET_TRANSLATE_REQUEST:
    case FETCH_GET_LANGUAGES_REQUEST:
    case FETCH_UPDATE_TRANSLATE_REQUEST:
      return { ...state, loading: true, error: null };

    case FETCH_GET_TRANSLATE_FAILURE:
    case FETCH_GET_LANGUAGES_FAILURE:
    case FETCH_UPDATE_TRANSLATE_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case FETCH_UPDATE_TRANSLATE_SUCCESS:
      return { ...state, loading: false };

    case FETCH_GET_TRANSLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        translates: action.payload.entities,
        translatesTotalCount: action.payload.count,
      };

    case FETCH_GET_LANGUAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        languages: action.payload,
      };

    default:
      return { ...state };
  }
};

export default translates;
