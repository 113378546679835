export const FETCH_GET_PRODUCTS_REQUEST = "FETCH_GET_PRODUCTS_REQUEST";
export const FETCH_GET_PRODUCTS_FAILURE = "FETCH_GET_PRODUCTS_FAILURE";
export const FETCH_GET_PRODUCTS_SUCCESS = "FETCH_GET_PRODUCTS_SUCCESS";

export const FETCH_REMOVE_PRODUCTS_REQUEST = "FETCH_REMOVE_PRODUCTS_REQUEST";
export const FETCH_REMOVE_PRODUCTS_FAILURE = "FETCH_REMOVE_PRODUCTS_FAILURE";
export const FETCH_REMOVE_PRODUCTS_SUCCESS = "FETCH_REMOVE_PRODUCTS_SUCCESS";

export const FETCH_CREATE_PRODUCT_REQUEST = "FETCH_CREATE_PRODUCT_REQUEST";
export const FETCH_CREATE_PRODUCT_FAILURE = "FETCH_CREATE_PRODUCT_FAILURE";
export const FETCH_CREATE_PRODUCT_SUCCESS = "FETCH_CREATE_PRODUCT_SUCCESS";

export const FETCH_UPDATE_PRODUCT_REQUEST = "FETCH_UPDATE_PRODUCT_REQUEST";
export const FETCH_UPDATE_PRODUCT_FAILURE = "FETCH_UPDATE_PRODUCT_FAILURE";
export const FETCH_UPDATE_PRODUCT_SUCCESS = "FETCH_UPDATE_PRODUCT_SUCCESS";
