import React from "react";

import "./Stepper.scss";

import { ReactComponent as RightArrowIcon } from "../../assets/images/rightArrowIcon.svg";
import { ReactComponent as LeftArrowIcon } from "../../assets/images/leftArrowIcon.svg";

const Stepper = ({ children, onNextStep, onPrevStep }) => {
  return (
    <div className='Stepper'>
      <button
        onClick={onPrevStep}
        className='stepper-btn stepper-btn stepper-btn-left'
      >
        <LeftArrowIcon />
      </button>
      {children}
      <button
        onClick={onNextStep}
        className='stepper-btn stepper-btn stepper-btn-right'
      >
        <RightArrowIcon />
      </button>
    </div>
  );
};

export default Stepper;
