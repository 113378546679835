import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withApiService } from "../../components/hoc";
import compose from "../../helpers/compose";
import { getSizes } from "../../redux/sizes/actions";

import { withRouter } from "react-router-dom";

import "./SizesPageWatchOnly.scss";
import { ReactComponent as WatchIcon } from "../../assets/images/watchIcon.svg";

import { Table } from "../../components/Tables";

const SizesPageWatchOnly = ({
  history,
  sizes,
  sizesTotalCount,
  loading,
  getSizes,
}) => {
  const [filter, setFilter] = useState({ limit: 10, offset: 0 });

  useEffect(() => {
    getSizes(filter);
  }, []);
  const updateFilter = changedFields => {
    setFilter(prevState => {
      return { ...prevState, ...changedFields };
    });
    getSizes({ ...filter, ...changedFields });
  };
  const columns = [
    {
      title: "",
      dataIndex: "",
      width: "5%",
      render: rowData => {
        return "";
      },
    },
    {
      title: "Название",
      dataIndex: "name",
      width: "90%",
      sorter: true,
    },
    {
      title: "",
      dataIndex: "",
      width: "5%",
      render: rowData => {
        return (
          <WatchIcon
            style={{ fill: "#002534", width: 30, height: 30 }}
            onClick={() => {
              history.push(`/sizes/${rowData.id}`);
            }}
          />
        );
      },
    },
  ];

  return (
    <div className='page-content'>
      <Table
        loading={loading}
        dataSource={sizes}
        columns={columns}
        onClickRow={() => {}}
        pagination={{
          total: sizesTotalCount,
          offset: filter.offset,
          limit: filter.limit,
        }}
        onChange={(pagination, sorter) => {
          let changedFields = {
            ...pagination,
            ...sorter,
          };
          updateFilter(changedFields);
        }}
      />
    </div>
  );
};

const mapStateToProps = ({ sizes }) => ({
  sizes: sizes.sizes,
  sizesTotalCount: sizes.sizesTotalCount,
  loading: sizes.loading,
});
const mapDispatchToProps = (dispatch, { apiService, filter, sizes }) => {
  return bindActionCreators(
    {
      getSizes: getSizes(apiService, filter),
    },
    dispatch,
  );
};

export default compose(
  withApiService(),
  connect(mapStateToProps, mapDispatchToProps),
)(withRouter(SizesPageWatchOnly));
