import React from "react";

import "./RemoveShopForm.scss";
import { Button, Form } from "../../../components/FormItems";

const RemoveShopForm = ({ onCloseModal, removeShop }) => {
  return (
    <div className='RemoveShopForm'>
      <Form title='Удаление магазинов'>
        <div className='description mt2 mb2'>
          Вы действительно хотите удалить эти магазины ?
        </div>
        <Button
          filled={true}
          onPress={() => {
            removeShop();
            onCloseModal();
          }}
        >
          Удалить магазины
        </Button>
        <div className='mt2'></div>
        <Button
          onPress={() => {
            onCloseModal();
          }}
        >
          Отменить
        </Button>
      </Form>
    </div>
  );
};

export default RemoveShopForm;
