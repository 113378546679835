import React from "react";

import "./SidebarMenuItemTitle.scss";

import { ReactComponent as ArrowDown } from "../../../assets/images/arrowDown.svg";
const SidebarMenuItemTitle = ({
  label,
  hasArrow = false,
  onClick = () => {},
  highlighted = false,
}) => {
  let SidebarmenuItemClassNames = "Sidebarmenu__item sidebarmenu-item ";

  SidebarmenuItemClassNames += highlighted
    ? " Sidebarmenu__item--highlighted "
    : "";
  return (
    <span
      onClick={() => {
        onClick();
      }}
      className={SidebarmenuItemClassNames}
    >
      <div className='sidebarmenu-item__title'>{label}</div>
      {hasArrow ? (
        <div className='sidebarmenu-item__arrow'>
          <ArrowDown />
        </div>
      ) : (
        ""
      )}
    </span>
  );
};

export default SidebarMenuItemTitle;
