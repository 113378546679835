import {
  getModerationLooksRequest,
  updateModerationLookRequest,
  getModerationLooksFailure,
  updateModerationLookFailure,
  getModerationLooksSuccess,
  updateModerationLookSuccess,
  massUpdateModerationLooksRequest,
  massUpdateModerationLooksSuccess,
  massUpdateModerationLooksFailure,
} from "./actionCreators";

const getModerationLooks = api => filter => dispatch => {
  dispatch(getModerationLooksRequest());
  api
    .getModerationLooks(filter)
    .then(res => {
      console.log(res, "res getModerations");
      dispatch(getModerationLooksSuccess(res.data));
    })
    .catch(err => {
      console.log(err, "res getModerations");
      dispatch(
        getModerationLooksFailure({
          status: "error",
          text: `Возникла ошибка с кодом ${
            err.response ? err.response.status : "???"
          } `,
        }),
      );
    });
};

const updateModerationLook = api => (moderationData, filter) => dispatch => {
  dispatch(updateModerationLookRequest());

  api
    .updateModerationLooks(moderationData)
    .then(res => {
      console.log(res, "res updateModeration");
      dispatch(updateModerationLookSuccess());

      getModerationLooks(api)(filter)(dispatch);
    })
    .catch(err => {
      console.log(err, "err updateModeration");
      dispatch(
        updateModerationLookFailure({
          status: "error",
          text: `Возникла ошибка с кодом ${
            err.response ? err.response.status : "???"
          } `,
        }),
      );
    });
};

const massUpdateModerationLooks = api => (
  moderations = [],
  status,
  filter,
) => dispatch => {
  dispatch(massUpdateModerationLooksRequest());
  api
    .massUpdateModerationLooks({
      moderationLooksIds: moderations.map(moderation => moderation.id),
      status,
    })
    .then(res => {
      console.log(res, "res massUpdateModerationLooks");
      dispatch(massUpdateModerationLooksSuccess());

      getModerationLooks(api)(filter)(dispatch);
    })
    .catch(err => {
      console.log(err, "err massUpdateModerationLooks");
      dispatch(
        massUpdateModerationLooksFailure({
          status: "error",
          text: `Возникла ошибка с кодом ${
            err.response ? err.response.status : "???"
          } `,
        }),
      );
    });
};

export { getModerationLooks, updateModerationLook, massUpdateModerationLooks };
