import {
  FETCH_GET_BRANDS_REQUEST,
  FETCH_GET_BRANDS_FAILURE,
  FETCH_GET_BRANDS_SUCCESS,
  FETCH_REMOVE_BRANDS_REQUEST,
  FETCH_REMOVE_BRANDS_FAILURE,
  FETCH_REMOVE_BRANDS_SUCCESS,
  FETCH_CREATE_BRAND_REQUEST,
  FETCH_CREATE_BRAND_FAILURE,
  FETCH_CREATE_BRAND_SUCCESS,
  FETCH_UPDATE_BRAND_REQUEST,
  FETCH_UPDATE_BRAND_FAILURE,
  FETCH_UPDATE_BRAND_SUCCESS,
} from "./types";

export const getBrandsRequest = () => {
  return { type: FETCH_GET_BRANDS_REQUEST };
};
export const removeBrandsRequest = () => {
  return { type: FETCH_REMOVE_BRANDS_REQUEST };
};
export const createBrandRequest = () => {
  return { type: FETCH_CREATE_BRAND_REQUEST };
};
export const updateBrandRequest = () => {
  return { type: FETCH_UPDATE_BRAND_REQUEST };
};

export const getBrandsFailure = error => {
  return { type: FETCH_GET_BRANDS_FAILURE, payload: error };
};
export const removeBrandsFailure = error => {
  return { type: FETCH_REMOVE_BRANDS_FAILURE, payload: error };
};
export const createBrandFailure = error => {
  return { type: FETCH_CREATE_BRAND_FAILURE, payload: error };
};
export const updateBrandFailure = error => {
  return { type: FETCH_UPDATE_BRAND_FAILURE, payload: error };
};

export const getBrandsSuccess = brandsData => {
  return { type: FETCH_GET_BRANDS_SUCCESS, payload: brandsData };
};
export const removeBrandsSuccess = () => {
  return { type: FETCH_REMOVE_BRANDS_SUCCESS };
};
export const createBrandSuccess = () => {
  return { type: FETCH_CREATE_BRAND_SUCCESS };
};
export const updateBrandSuccess = () => {
  return { type: FETCH_UPDATE_BRAND_SUCCESS };
};
