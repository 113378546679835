import {
  SEARCH_CATEGORIES_SUCCESS,
  SEARCH_SIZES_SUCCESS,
  SEARCH_COLORS_SUCCESS,
  SEARCH_MATERIALS_SUCCESS,
  SEARCH_BRANDS_SUCCESS,
  SEARCH_SHOPS_SUCCESS,
} from "./types";

const getSearchCategories = api => name => dispatch => {
  api
    .getSearchCategories({ name, limit: 99999 })
    .then(res => {
      console.log(res, "res getSearchCategories");
      dispatch({ type: SEARCH_CATEGORIES_SUCCESS, payload: res.data });
    })
    .catch(err => {
      console.log(err, "err getSearchCategories");
    });
};

const getSearchSizes = api => name => dispatch => {
  api
    .getSizes({ name, limit: 99999 })
    .then(res => {
      console.log(res, "res getSizes");
      dispatch({ type: SEARCH_SIZES_SUCCESS, payload: res.data });
    })
    .catch(err => {
      console.log(err, "err getSizes");
    });
};

const getSearchColors = api => name => dispatch => {
  api
    .getColors({ name, limit: 99999 })
    .then(res => {
      console.log(res, "res getColors");
      dispatch({ type: SEARCH_COLORS_SUCCESS, payload: res.data });
    })
    .catch(err => {
      console.log(err, "err getColors");
    });
};

const getSearchMaterials = api => name => dispatch => {
  api
    .getMaterials({ name, limit: 99999 })
    .then(res => {
      console.log(res, "res getMaterials");
      dispatch({ type: SEARCH_MATERIALS_SUCCESS, payload: res.data });
    })
    .catch(err => {
      console.log(err, "err getMaterials");
    });
};

const getSearchBrands = api => name => dispatch => {
  api
    .getBrands({ name, limit: 99999 })
    .then(res => {
      console.log(res, "res getBrands");
      dispatch({ type: SEARCH_BRANDS_SUCCESS, payload: res.data });
    })
    .catch(err => {
      console.log(err, "err getBrands");
    });
};
const getSearchShops = api => (name, userRoleId) => dispatch => {
  let getShopsMethod = userRoleId != 2 ? api.getShops : api.getOwnShops;

  getShopsMethod({ name, limit: 99999 })
    .then(res => {
      console.log(res, "res getShops");
      dispatch({ type: SEARCH_SHOPS_SUCCESS, payload: res.data });
    })
    .catch(err => {
      console.log(err, "err getShops");
    });
};
export {
  getSearchCategories,
  getSearchSizes,
  getSearchColors,
  getSearchMaterials,
  getSearchBrands,
  getSearchShops,
};
