import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withApiService } from "../../components/hoc";
import compose from "../../helpers/compose";
import {
  getSize,
  createSize,
  updateSize,
  getSizeTablesTemplates,
} from "../../redux/sizes/actions";

import { withRouter } from "react-router-dom";

import "./SizesDetailPage.scss";
import {
  TextInput,
  SelectInput,
  Button,
  Form,
} from "../../components/FormItems";
import { EditableTable } from "../../components/Tables";

import Modal from "../../components/Modal";

const SizesDetailPage = ({
  sizeData,
  loading,
  getSize,
  createSize,
  updateSize,
  getSizeTablesTemplates,
  match,
  history,
}) => {
  const [sizeFormData, setSizeFormData] = useState({});
  const [showTemplateGridsModal, setShowTemplateGridsModal] = useState(false);

  const isEdit = match.params.id !== "new" ? true : false;

  useEffect(() => {
    if (match.params.id !== "new") {
      getSize(match.params.id);
    }
  }, []);

  const formatServerSizesDataToTable = (rows = []) => {
    let formatedData = [];
    let tmpRow = [];

    rows.forEach(rowData => {
      tmpRow = [rowData.name];
      rowData.values.forEach(rowCell => {
        tmpRow.push(rowCell);
      });
      formatedData.push(tmpRow);
      tmpRow = [];
    });

    return formatedData;
  };

  const formatTableDataToServer = tableDataset => {
    let newRows = [];

    tableDataset.forEach((row, rowIndex) => {
      let tmpRow = { name: "", values: [] };

      row.forEach((cellData, cellIndex) => {
        if (cellIndex === 0) {
          tmpRow.name = [
            {
              languageCode: "ru",
              data: cellData,
            },
            {
              languageCode: "en",
              data: cellData,
            },
          ];
        } else {
          tmpRow.values.push(cellData);
        }
      });

      newRows.push(tmpRow);
    });

    return newRows;
  };

  const [sizeTableData, setSizeTableData] = useState(
    formatServerSizesDataToTable(sizeData.rows),
  );

  useEffect(() => {
    if (sizeData) {
      setSizeTableData(
        formatServerSizesDataToTable(
          sizeData.rows && sizeData.rows.length > 0
            ? sizeData.rows
            : [{ name: "", values: [] }],
        ),
      );
      // Если это не шаблон меняем данные
      if (!sizeData.gender && !sizeData.item_type) {
        setSizeFormData(sizeData);
      }
    }
  }, [sizeData]);

  const updateSizeFormData = changedFields => {
    setSizeFormData(prevData => ({ ...prevData, ...changedFields }));
  };

  return (
    <div className='SizesDetailPage'>
      <Modal
        onCloseModal={() => setShowTemplateGridsModal(false)}
        displayModal={showTemplateGridsModal}
      >
        <Form>
          <SelectInput
            placeholder='Гендер'
            onSelect={value => {
              updateSizeFormData({ gender: value });
            }}
            selectedValue={sizeFormData["gender"]}
            options={[
              { id: 1, name: "Женский" },
              { id: 2, name: "Мужской" },
              { id: 3, name: "Детский" },
            ]}
          />
          <div className='mt2'></div>
          <SelectInput
            placeholder='Тип товара'
            onSelect={value => {
              updateSizeFormData({ productType: value });
            }}
            selectedValue={sizeFormData["productType"]}
            options={[
              { id: 1, name: "Аксессуары" },
              { id: 2, name: "Одежда" },
              { id: 3, name: "Обувь" },
              { id: 4, name: "Головные уборы" },
            ]}
          />
          <div className='mt4'></div>

          <Button
            disabled={!sizeFormData.gender && !sizeFormData.productType}
            onPress={() => {
              getSizeTablesTemplates({
                gender: sizeFormData.gender,
                productType: sizeFormData.productType,
              });
              setShowTemplateGridsModal(false);
            }}
            filled={true}
          >
            Подобрать шаблон
          </Button>
          <div className='mt1'></div>
          <Button onPress={() => setShowTemplateGridsModal(false)}>
            Отмена
          </Button>
        </Form>
      </Modal>
      <div className='formItems mb2'>
        <TextInput
          type='text'
          name='name'
          placeholder='Название'
          onChange={value => {
            updateSizeFormData({ name: value });
          }}
          value={sizeFormData["name"]}
        />
        {isEdit ? (
          ""
        ) : (
          <div style={{ maxWidth: 250 }}>
            <Button
              onPress={() => setShowTemplateGridsModal(true)}
              filled={true}
            >
              Подобрать шаблон
            </Button>
          </div>
        )}
      </div>
      <EditableTable
        tableWidth={"max-content"}
        tableDataset={sizeTableData}
        onChange={newTableDataset => {
          setSizeTableData(newTableDataset);
        }}
      />
      <div className='controlButtons'>
        <Button
          filled={true}
          disabled={!sizeFormData.name}
          onPress={() => {
            isEdit
              ? updateSize({
                  id: sizeFormData.id,
                  name: [
                    {
                      languageCode: "ru",
                      data: sizeFormData.name,
                    },
                    {
                      languageCode: "en",
                      data: sizeFormData.name,
                    },
                  ],

                  rows: formatTableDataToServer(sizeTableData),
                })
              : createSize({
                  name: [
                    {
                      languageCode: "ru",
                      data: sizeFormData.name,
                    },
                    {
                      languageCode: "en",
                      data: sizeFormData.name,
                    },
                  ],
                  rows: formatTableDataToServer(sizeTableData),
                });
            history.push("/sizes");
          }}
        >
          Сохранить
        </Button>
        <Button
          onPress={() => {
            history.push("/sizes");
          }}
        >
          Назад
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = ({ sizes }) => ({
  sizeData: sizes.sizes[0] || {},
  loading: sizes.loading,
});
const mapDispatchToProps = (
  dispatch,
  { apiService, sizeData, sizeId, templateFilter },
) => {
  return bindActionCreators(
    {
      getSize: getSize(apiService, sizeId),
      createSize: createSize(apiService, sizeData),
      updateSize: updateSize(apiService, sizeData),
      getSizeTablesTemplates: getSizeTablesTemplates(
        apiService,
        templateFilter,
      ),
    },
    dispatch,
  );
};

export default compose(
  withApiService(),
  connect(mapStateToProps, mapDispatchToProps),
)(withRouter(SizesDetailPage));
