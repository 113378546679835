export const FETCH_GET_TRANSLATE_REQUEST = "FETCH_GET_TRANSLATE_REQUEST";
export const FETCH_GET_TRANSLATE_FAILURE = "FETCH_GET_TRANSLATE_FAILURE";
export const FETCH_GET_TRANSLATE_SUCCESS = "FETCH_GET_TRANSLATE_SUCCESS";

export const FETCH_GET_LANGUAGES_REQUEST = "FETCH_GET_LANGUAGES_REQUEST";
export const FETCH_GET_LANGUAGES_FAILURE = "FETCH_GET_LANGUAGES_FAILURE";
export const FETCH_GET_LANGUAGES_SUCCESS = "FETCH_GET_LANGUAGES_SUCCESS";

export const FETCH_UPDATE_TRANSLATE_REQUEST = "FETCH_UPDATE_TRANSLATE_REQUEST";
export const FETCH_UPDATE_TRANSLATE_FAILURE = "FETCH_UPDATE_TRANSLATE_FAILURE";
export const FETCH_UPDATE_TRANSLATE_SUCCESS = "FETCH_UPDATE_TRANSLATE_SUCCESS";
