import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withApiService } from "../../../components/hoc";
import compose from "../../../helpers/compose";
import {
  getCategories,
  createCategory,
  updateCategory,
  removeCategories,
} from "../../../redux/categories/actions";

import { getLanguages } from "../../../redux/translates/actions";

import { getSearchCategories } from "../../../redux/search/actions";
import "./CategoriesPage.scss";
import { ReactComponent as FilterIcon } from "../../../assets/images/filterIcon.svg";
import { ReactComponent as EditIcon } from "../../../assets/images/editIcon.svg";
import { ReactComponent as PlusIcon } from "../../../assets/images/plusIcon.svg";
import {
  Button,
  CheckBox,
  Select,
  Search,
} from "../../../components/FormItems";
import { Table } from "../../../components/Tables";
import Modal from "../../../components/Modal";
import EditOrAddCategoryForm from "../EditOrAddCategoryForm";
import RemoveCategoryForm from "../RemoveCategoryForm";

import {
  mapGenderIdToName,
  mapProductTypeIdToName,
} from "../../../helpers/mapper";

const CategoriesPage = ({
  categories,
  categoriesTotalCount,
  loading,
  languages = [],
  getCategories,
  createCategory,
  updateCategory,
  removeCategories,
  getSearchCategories,
  searchCategoriesResult,
  getLanguages,
}) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [filter, setFilter] = useState({ limit: 10, offset: 0 });

  useEffect(() => {
    getCategories(filter);
    getLanguages();
  }, []);

  const [showAddOrEditModal, setShowAddOrEditModal] = useState(false);
  const [selectedCategoryForEdit, setSelectedCategoryForEdit] = useState({});

  const [showRemoveCategoriesModal, setShowRemoveCategoriesModal] = useState(
    false,
  );
  const updateFilter = changedFields => {
    setFilter(prevState => {
      return { ...prevState, ...changedFields };
    });

    getCategories({ ...filter, ...changedFields });
  };

  const columns = [
    {
      title: "",
      dataIndex: "",
      width: "5%",
      render: rowData => {
        return (
          <CheckBox
            valuesList={selectedRows}
            onChange={selectedValues => {
              setSelectedRows(selectedValues);
            }}
            value={rowData}
          />
        );
      },
    },
    {
      title: "Название",
      dataIndex: "name",
      width: "15%",
      sorter: true,
      render: rowData => {
        return rowData.name && rowData.name[0] ? rowData.name[0].data : "";
      },
    },
    {
      title: "Уровень",
      dataIndex: "level",
      width: "10%",
      sorter: true,
    },
    {
      title: "Гендер",
      dataIndex: "gender",
      width: "10%",
      sorter: true,
      render: rowData => {
        return mapGenderIdToName[rowData.gender] || "Не выбран";
      },
    },
    {
      title: "Тип товара",
      dataIndex: "itemType",
      width: "15%",
      sorter: true,
      render: rowData => {
        return mapProductTypeIdToName[rowData.itemType] || "Не выбран";
      },
    },
    {
      title: "Дочерние категории",
      dataIndex: "children",
      width: "15%",
      sorter: true,
      render: rowData => {
        // Сортируем массив родителей по уровню и выводим его
        return rowData.children.length > 0
          ? rowData.children
              .sort((a, b) => {
                if (a.level < b.level) {
                  return -1;
                }
                if (a.level > b.level) {
                  return 1;
                }
                return 0;
              })
              .map(parent => parent.name)
              .join(" > ")
          : "Нет категорий";
      },
    },
    {
      title: "",
      dataIndex: "",
      width: "5%",
      render: rowData => {
        return (
          <EditIcon
            style={{ fill: "#002534" }}
            onClick={() => {
              setSelectedCategoryForEdit(rowData);
              setShowAddOrEditModal(true);
            }}
          />
        );
      },
    },
  ];

  return (
    <div className='page-content'>
      <Modal
        maxWidth={"800px"}
        onCloseModal={() => {
          setShowAddOrEditModal(false);
          setSelectedCategoryForEdit({});
        }}
        displayModal={showAddOrEditModal}
      >
        <EditOrAddCategoryForm
          createCategory={categoryData => createCategory(categoryData, filter)}
          updateCategory={categoryData => updateCategory(categoryData, filter)}
          searchCategories={value => getSearchCategories(value)}
          searchCategoriesResults={searchCategoriesResult}
          categoryData={selectedCategoryForEdit}
          languages={languages}
        />
      </Modal>

      <Modal
        onCloseModal={() => {
          setShowRemoveCategoriesModal(false);
        }}
        displayModal={showRemoveCategoriesModal}
      >
        <RemoveCategoryForm
          removeCategory={() => {
            removeCategories(selectedRows, filter);
            setSelectedRows([]);
          }}
        />
      </Modal>

      <div className='table-filters mb2'>
        <Search
          placeholder='Название категории'
          onSelect={value => {
            updateFilter({ name: value.name, offset: 0 });
          }}
          searchResults={[]}
          searchRequest={val => {
            getCategories({ ...filter, name: val, offset: 0 });
          }}
          selectedValue={filter["name"]}
          requestStartSumbolIndex={0}
        />

        <div className='table-filters'>
          <Button
            filled={true}
            minHeight={40}
            onPress={() => {
              setSelectedCategoryForEdit({});
              setShowAddOrEditModal(true);
            }}
          >
            <PlusIcon style={{ marginRight: 3, marginBottom: -2 }} /> Добавить
            категорию
          </Button>
          <div className='mr2'></div>
          <Button
            disabled={!selectedRows.length > 0}
            minHeight={40}
            minWidth={160}
            onPress={() => {
              setShowRemoveCategoriesModal(true);
            }}
          >
            Удалить
          </Button>
        </div>
      </div>
      <Table
        loading={loading}
        dataSource={categories}
        columns={columns}
        onClickRow={() => {}}
        highlightRow={rowData => {
          return selectedRows.findIndex(item => item.id === rowData.id) > -1;
        }}
        pagination={{
          total: categoriesTotalCount,
          offset: filter.offset,
          limit: filter.limit,
        }}
        onChange={(pagination, sorter) => {
          let changedFields = {
            ...pagination,
            ...sorter,
          };
          updateFilter(changedFields);
        }}
      />
    </div>
  );
};

const mapStateToProps = ({ categories, search, translates }) => ({
  categories: categories.categories,
  categoriesTotalCount: categories.categoriesTotalCount,
  loading: categories.loading,
  searchCategoriesResult: search.categories,
  languages: translates.languages,
});
const mapDispatchToProps = (
  dispatch,
  { apiService, filter, categories, categoryData, searchCategoryName },
) => {
  return bindActionCreators(
    {
      getCategories: getCategories(apiService, filter),
      createCategory: createCategory(apiService, categoryData, filter),
      updateCategory: updateCategory(apiService, categoryData, filter),
      removeCategories: removeCategories(apiService, categories, filter),
      getSearchCategories: getSearchCategories(apiService, searchCategoryName),
      getLanguages: getLanguages(apiService),
    },
    dispatch,
  );
};

export default compose(
  withApiService(),
  connect(mapStateToProps, mapDispatchToProps),
)(CategoriesPage);
