import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withApiService } from "../../../components/hoc";
import compose from "../../../helpers/compose";
import {
  getShops,
  createShop,
  updateShop,
  removeShops,
} from "../../../redux/shops/actions";

import { getLanguages } from "../../../redux/translates/actions";

import "./ShopsPage.scss";
import { ReactComponent as EditIcon } from "../../../assets/images/editIcon.svg";
import { ReactComponent as PlusIcon } from "../../../assets/images/plusIcon.svg";

import { Button, CheckBox } from "../../../components/FormItems";
import { Table } from "../../../components/Tables";
import Modal from "../../../components/Modal";
import EditOrAddShopForm from "../EditOrAddShopForm";
import RemoveShopForm from "../RemoveShopForm";

const ShopsPage = ({
  shops,
  shopsTotalCount,
  loading,
  languages = [],
  userRoleId,
  getShops,
  createShop,
  updateShop,
  removeShops,
  getLanguages,
}) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [filter, setFilter] = useState({ limit: 10, offset: 0 });

  const [showAddOrEditStoreModal, setShowAddOrEditStoreModal] = useState(false);
  const [selectedStoreForEdit, setSelectedStoreForEdit] = useState({});

  const [showRemoveStoreModal, setShowRemoveStoreModal] = useState(false);

  useEffect(() => {
    getLanguages();
    getShops(filter, userRoleId);
  }, []);

  const updateFilter = changedFields => {
    setFilter(prevState => {
      return { ...prevState, ...changedFields };
    });

    getShops({ ...filter, ...changedFields }, userRoleId);
  };
  const columns = [
    {
      title: "",
      dataIndex: "",
      width: "5%",
      render: rowData => {
        return (
          <CheckBox
            valuesList={selectedRows}
            onChange={selectedValues => {
              setSelectedRows(selectedValues);
            }}
            value={rowData}
          />
        );
      },
    },
    {
      title: "Название",
      dataIndex: "name",
      width: "25%",
      sorter: true,
      render: rowData => {
        return rowData.name && rowData.name[0] ? rowData.name[0].data : "";
      },
    },
    {
      title: "Кол_во адресов",
      dataIndex: "addresses",
      width: "25%",
      render: rowData => {
        return rowData.addresses ? rowData.addresses.length : 0;
      },
    },

    {
      title: "",
      dataIndex: "",
      width: "5%",
      render: rowData => {
        return (
          <EditIcon
            style={{ fill: "#002534" }}
            onClick={() => {
              setSelectedStoreForEdit(rowData);
              setShowAddOrEditStoreModal(true);
            }}
          />
        );
      },
    },
  ];

  console.log(userRoleId, "userRoleId");

  return (
    <div className='page-content'>
      <Modal
        onCloseModal={() => {
          setShowRemoveStoreModal(false);
        }}
        displayModal={showRemoveStoreModal}
      >
        <RemoveShopForm
          removeShop={() => {
            removeShops(selectedRows, filter);
            setSelectedRows([]);
            setShowRemoveStoreModal(false);
            updateFilter({ offset: 0 });
          }}
        />
      </Modal>
      <Modal
        maxWidth='1170px'
        onCloseModal={() => {
          setSelectedStoreForEdit({});
          setShowAddOrEditStoreModal(false);
        }}
        displayModal={showAddOrEditStoreModal}
      >
        <EditOrAddShopForm
          userRoleId={userRoleId}
          languages={languages}
          updateShop={shopData => {
            updateShop(shopData, filter, userRoleId);
            updateFilter({ offset: 0 });
          }}
          createShop={shopData => {
            createShop(shopData, filter);
            updateFilter({ offset: 0 });
          }}
          shopData={selectedStoreForEdit}
        />
      </Modal>
      <div className='table-filters mb2'>
        <div />

        {userRoleId != 2 ? (
          <div className='table-filters'>
            <Button
              filled={true}
              minHeight={40}
              onPress={() => {
                setSelectedStoreForEdit({});
                setShowAddOrEditStoreModal(true);
              }}
            >
              <PlusIcon style={{ marginRight: 3, marginBottom: -2 }} /> Добавить
              магазин
            </Button>
            <div className='mr2'></div>
            <Button
              disabled={!selectedRows.length > 0}
              minHeight={40}
              minWidth={160}
              onPress={() => {
                setShowRemoveStoreModal(true);
              }}
            >
              Удалить
            </Button>
          </div>
        ) : (
          ""
        )}
      </div>
      <Table
        loading={loading}
        dataSource={shops}
        columns={columns}
        onClickRow={() => {}}
        highlightRow={rowData => {
          return selectedRows.findIndex(item => item.id === rowData.id) > -1;
        }}
        pagination={{
          total: shopsTotalCount,
          offset: filter.offset,
          limit: filter.limit,
        }}
        onChange={(pagination, sorter) => {
          let changedFields = {
            ...pagination,
            ...sorter,
          };
          updateFilter(changedFields);
        }}
      />
    </div>
  );
};

const mapStateToProps = ({ shops, translates, auth }) => ({
  shops: shops.shops,
  shopsTotalCount: shops.shopsTotalCount,
  loading: shops.loading,
  languages: translates.languages,
  userRoleId: auth.roleId,
});
const mapDispatchToProps = (
  dispatch,
  { apiService, shops, shopData, userRoleId, filter },
) => {
  return bindActionCreators(
    {
      getLanguages: getLanguages(apiService),
      getShops: getShops(apiService, filter, userRoleId),
      createShop: createShop(apiService, shopData, filter),
      updateShop: updateShop(apiService, shopData, filter, userRoleId),
      removeShops: removeShops(apiService, shops, filter),
    },
    dispatch,
  );
};

export default compose(
  withApiService(),
  connect(mapStateToProps, mapDispatchToProps),
)(ShopsPage);
