import React from "react";

import "./SidebarMenu.scss";

const SidebarMenu = ({
  className = "",
  children,
  onSelect = () => {},
  openSidebarIds = [],
}) => {
  const modifiedChild = [];
  React.Children.forEach(children, child => {
    modifiedChild.push(
      React.cloneElement(child, {
        onSelect,
        openSidebarIds,
      }),
    );
  });

  return <ul className={"Sidebarmenu " + className}>{modifiedChild}</ul>;
};

export default SidebarMenu;
