import {
  getBrandsRequest,
  removeBrandsRequest,
  createBrandRequest,
  updateBrandRequest,
  getBrandsFailure,
  removeBrandsFailure,
  createBrandFailure,
  updateBrandFailure,
  getBrandsSuccess,
  removeBrandsSuccess,
  createBrandSuccess,
  updateBrandSuccess,
} from "./actionCreators";

const getBrands = api => filter => dispatch => {
  dispatch(getBrandsRequest());
  api
    .getBrands(filter)
    .then(res => {
      console.log(res, "res getBrands");
      dispatch(getBrandsSuccess(res.data));
    })
    .catch(err => {
      console.log(err, "res getBrands");
      dispatch(
        getBrandsFailure({
          status: "error",
          text: `Возникла ошибка с кодом ${
            err.response ? err.response.status : "???"
          } `,
        }),
      );
    });
};

const createBrand = api => (brandData, filter) => dispatch => {
  dispatch(createBrandRequest());

  api
    .createBrand(brandData)
    .then(res => {
      console.log(res, "res createBrand");
      dispatch(createBrandSuccess());

      getBrands(api)(filter)(dispatch);
    })
    .catch(err => {
      console.log(err, "err createBrand");
      dispatch(
        createBrandFailure({
          status: "error",
          text: `Возникла ошибка с кодом ${
            err.response ? err.response.status : "???"
          } `,
        }),
      );
    });
};

const updateBrand = api => (brandData, filter) => dispatch => {
  dispatch(updateBrandRequest());

  api
    .updateBrand(brandData)
    .then(res => {
      console.log(res, "res updateBrand");
      dispatch(updateBrandSuccess());

      getBrands(api)(filter)(dispatch);
    })
    .catch(err => {
      console.log(err, "err updateBrand");
      dispatch(
        updateBrandFailure({
          status: "error",
          text: `Возникла ошибка с кодом ${
            err.response ? err.response.status : "???"
          } `,
        }),
      );
    });
};

const removeBrands = api => (brands = [], filter) => dispatch => {
  dispatch(removeBrandsRequest());
  api
    .removeBrands(brands.map(brand => brand.id))
    .then(res => {
      console.log(res, "res removeBrands");
      dispatch(removeBrandsSuccess());

      getBrands(api)(filter)(dispatch);
    })
    .catch(err => {
      console.log(err, "err removeBrands");
      dispatch(
        removeBrandsFailure({
          status: "error",
          text: `Возникла ошибка с кодом ${
            err.response ? err.response.status : "???"
          } `,
        }),
      );
    });
};

export { getBrands, createBrand, updateBrand, removeBrands };
