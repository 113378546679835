import React from "react";
import "./TableEmptyScreen.scss";

import { ReactComponent as EmptyTable } from "../../../assets/images/EmptyTable.svg";

const TableEmptyScreen = ({ maxHeight }) => {
  return (
    <div
      style={{ maxHeight, minHeight: maxHeight }}
      className='EmptyTableBlock'
    >
      <EmptyTable />
      <div className='Text'>Нет данных</div>
    </div>
  );
};

export default TableEmptyScreen;
