import React, { useState } from "react";
import "./TagsInput.scss";
import { ReactComponent as SendIcon } from "../../../assets/images/sendIcon.svg";
import { ReactComponent as CloseIcon } from "../../../assets/images/closeIcon.svg";

const TagsInput = ({
  placeholder,
  name,
  values = [],
  onAddTag,
  onRemoveTag,
  maxTagsCount = 5,
}) => {
  const [newTagName, setNewTagName] = useState("");

  return (
    <div className='TagsInput'>
      <div className='tagsBlock'>
        {values.map(tag => {
          return (
            <div className='tag'>
              {tag}
              <CloseIcon
                className='removeBtn'
                onClick={() => {
                  let tagIndex = values.findIndex(item => item === tag);

                  if (tagIndex > -1) {
                    onRemoveTag([
                      ...values.slice(0, tagIndex),
                      ...values.slice(tagIndex + 1),
                    ]);
                  }
                }}
              />
            </div>
          );
        })}
      </div>
      <div className='inputBlock'>
        <input
          name={name}
          value={newTagName}
          placeholder={placeholder}
          onChange={e => {
            if (e.target.value.length <= 32) {
              setNewTagName(e.target.value);
            }
          }}
        />
        <SendIcon
          width='20px'
          height='20px'
          onClick={() => {
            let tagIndex = values.findIndex(item => item === newTagName);
            if (
              tagIndex < 0 &&
              newTagName.length > 0 &&
              values.length < maxTagsCount
            ) {
              onAddTag([...values, newTagName]);
              setNewTagName("");
            }
          }}
        />
      </div>
    </div>
  );
};

export default TagsInput;
