import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withApiService } from "../../components/hoc";
import compose from "../../helpers/compose";
import {
  getUsersOutside,
  toggleUsersOutsideStatus,
  exportUsersOutside,
} from "../../redux/usersOutside/actions";

import "./UsersOutsidePage.scss";
import { ReactComponent as FilterIcon } from "../../assets/images/filterIcon.svg";

import { Button, CheckBox, Select } from "../../components/FormItems";
import { Table } from "../../components/Tables";

const UsersOutsidePage = ({
  usersOutside,
  usersOutsideTotalCount,
  loading,
  getUsersOutside,
  toggleUsersOutsideStatus,
  exportUsersOutside,
}) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [usersFilter, setUsersFilter] = useState({ limit: 10, offset: 0 });

  const updateUsersFilter = changedFields => {
    setUsersFilter(prevState => {
      return { ...prevState, ...changedFields };
    });
    getUsersOutside({ ...usersFilter, ...changedFields });
  };

  useEffect(() => {
    getUsersOutside(usersFilter);
  }, []);
  const columns = [
    {
      title: "",
      dataIndex: "",
      width: "5%",
      render: rowData => {
        return (
          <CheckBox
            valuesList={selectedRows}
            onChange={selectedValues => {
              setSelectedRows(selectedValues);
            }}
            value={rowData}
          />
        );
      },
    },
    {
      title: "Имя",
      dataIndex: "firstName",
      width: "20%",
      sorter: true,
    },
    {
      title: "Фамилия",
      dataIndex: "lastName",
      width: "20%",
      sorter: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      width: "20%",
      sorter: true,
    },
    {
      title: "Логин",
      dataIndex: "nickname",
      width: "20%",
      sorter: true,
    },
    {
      title: "Статус",
      dataIndex: "status",
      width: "10%",
      render: rowData => {
        return rowData.isActive ? (
          <span className='green-text'>Активен</span>
        ) : (
          <span className='red-text'>Заблокирован</span>
        );
      },
      sorter: true,
    },
  ];

  return (
    <div className='page-content'>
      <div className='table-filters mb2'>
        <Select
          placeholder='Фильтр по статусу'
          width={"200px"}
          onSelect={value => {
            updateUsersFilter({ status: value, offset: 0 });
          }}
          selectedValue={usersFilter["status"]}
          options={[
            { id: 1, name: "Активные" },
            { id: 0, name: "Заблокированные" },
          ]}
          icon={<FilterIcon style={{ marginTop: 5 }} />}
        />
        <div className='table-filters'>
          <Button
            disabled={!selectedRows.length > 0}
            filled={true}
            minHeight={40}
            minWidth={160}
            onPress={() => {
              toggleUsersOutsideStatus(selectedRows, usersFilter);
              setSelectedRows([]);
            }}
          >
            Изменить статус
          </Button>
          <div className='mr2'></div>
          <Button
            filled={true}
            minHeight={40}
            minWidth={160}
            onPress={() => {
              exportUsersOutside();
            }}
          >
            Выгрузить пользователей
          </Button>
        </div>
      </div>
      <Table
        loading={loading}
        dataSource={usersOutside}
        columns={columns}
        onClickRow={() => {}}
        highlightRow={rowData => {
          return selectedRows.findIndex(item => item.id === rowData.id) > -1;
        }}
        pagination={{
          total: usersOutsideTotalCount,
          offset: usersFilter.offset,
          limit: usersFilter.limit,
        }}
        onChange={(pagination, sorter) => {
          let changedFields = {
            ...pagination,
            ...sorter,
          };
          updateUsersFilter(changedFields);
        }}
      />
    </div>
  );
};

const mapStateToProps = ({ usersOutside }) => ({
  usersOutside: usersOutside.usersOutside,
  usersOutsideTotalCount: usersOutside.usersOutsideTotalCount,
  loading: usersOutside.loading,
});
const mapDispatchToProps = (dispatch, { apiService, filter, users }) => {
  return bindActionCreators(
    {
      getUsersOutside: getUsersOutside(apiService, filter),
      exportUsersOutside: exportUsersOutside(apiService),
      toggleUsersOutsideStatus: toggleUsersOutsideStatus(
        apiService,
        users,
        filter,
      ),
    },
    dispatch,
  );
};

export default compose(
  withApiService(),
  connect(mapStateToProps, mapDispatchToProps),
)(UsersOutsidePage);
