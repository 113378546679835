export const mapRoleIdToRoleName = {
  "0": "Администратор",
  "1": "Модератор",
  "2": "Контент-менеджер",
};
export const mapArticleIdToName = {
  "0": "Модерация",
  "1": "На редактировании",
  "2": "Одобрена",
};
export const mapGenderIdToName = {
  "1": "Женский",
  "2": "Мужской",
  "3": "Детский",
};
export const mapProductTypeIdToName = {
  "1": "Аксессуары",
  "2": "Одежда",
  "3": "Обувь",
  "4": "Головные уборы",
};

export const mapStatusIdToName = {
  "1": "Модерация",
  "2": "Одобрена",
  "3": "На редактировании",
};
export const mapComplaintTypeToName = {
  "0": "Образ",
  "1": "Статья",
  "2": "Примерка",
};
export const mapComplaintStatusIdToName = {
  "0": "Новая",
  "1": "Принята",
  "2": "Отклонена",
};

export const mapProductSortingFieldNameToEnum = {
  id: "0",
  name: "1",
  shop: "2",
};
export const mapUserOutsideSortingFieldNameToEnum = {
  id: "0",
  firstName: "1",
  lastName: "2",
  nickname: "3",
  email: "4",
  status: "5",
};
export const mapUserInsideSortingFieldNameToEnum = {
  id: "0",
  username: "1",
  role: "2",
};

export const mapShopSortingFieldNameToEnum = {
  id: "0",
  name: "1",
};
export const mapSizesSortingFieldNameToEnum = {
  id: "0",
  name: "1",
};
export const mapBrandSortingFieldNameToEnum = {
  id: "0",
  name: "1",
};

export const mapSortValueToEnum = {
  asc: -1,
  desc: 1,
};

export const mapCategoriesSortingFieldNameToEnum = {
  id: "0",
  name: "1",
  level: "2",
  gender: "3",
  productType: "4",
};

export const mapModerationSortingFieldNameToEnum = {
  id: "0",
  name: "1",
  nickname: "2",
  updatedAt: "3",
  status: "4",
};

export const mapSystemArticlesSortingFieldNameToEnum = {
  id: "0",
  name: "1",
  createdAt: "2",
  updatedAt: "3",
  status: "4",
};
