import React, { useState, useEffect } from "react";

import "./EditOrAddArticleForm.scss";

import {
  Form,
  TextInput,
  Button,
  TextareaInput,
  TagsInput,
  ImageInput,
  SelectInput,
  SearchInput,
} from "../../../components/FormItems";

const EditOrAddArticleForm = ({
  articleData,
  languages = [],
  updateArticle,
  createArticle,
  onCloseModal,
  getProducts,
  searchProducts,
}) => {
  const [articleTitle, setArticleTitle] = useState("");
  const [articleText, setArticleText] = useState("");
  const [articleImages, setArticleImages] = useState([]);
  const [articleTags, setArticleTags] = useState([]);
  const [products, setProducts] = useState([]);

  const [articleRemovedImages, setArticleRemovedImages] = useState([]);
  const [articleAddededImages, setArticleAddededImages] = useState([]);

  const updateArticleImagesArray = image => {
    let imageIndex = articleImages.findIndex(
      item => item === image || (item.image && item.image === image),
    );
    if (imageIndex > -1) {
      setArticleImages([
        ...articleImages.slice(0, imageIndex),
        ...articleImages.slice(imageIndex + 1),
      ]);
      let indexInAdded = articleAddededImages.findIndex(() => item =>
        item === image || (item.image && item.image === image),
      );

      // Если была удалена картинка присланная с сервера
      if (articleImages[imageIndex].id) {
        // Добавляем ее в список удаленных
        setArticleRemovedImages([
          articleImages[imageIndex],
          ...articleRemovedImages,
        ]);
      }

      // Убираем ее из списка добавленных
      if (indexInAdded > -1) {
        setArticleAddededImages([
          ...articleAddededImages.slice(0, imageIndex),
          ...articleAddededImages.slice(imageIndex + 1),
        ]);
      }
    } else {
      setArticleImages([image, ...articleImages]);
      setArticleAddededImages([image, ...articleAddededImages]);
    }
  };

  useEffect(() => {
    setArticleTitle(articleData.name ? articleData.name : "");
    setArticleText(articleData.text ? articleData.text : "");
    setArticleImages(articleData.images || []);
    setArticleTags(
      articleData.tags ? articleData.tags.map(tag => tag.name) : [],
    );
    setArticleAddededImages([]);
    setProducts(
      articleData.items && articleData.items.length > 0
        ? articleData.items
        : [],
    );
  }, [articleData]);

  const isEdit = articleData.name ? true : false;

  console.log(products, "products");

  return (
    <div className='EditOrAddArticleForm'>
      <Form title='Добавление/редактирование статьи'>
        <div className='gridCol2 mt3 mb4'>
          <div className='inputsBlock'>
            <TextInput
              type='text'
              name='title'
              placeholder='Название'
              onChange={value => {
                setArticleTitle(value);
              }}
              value={articleTitle}
            />
            <TextareaInput
              name='articleText'
              placeholder='Текст'
              onChange={value => {
                setArticleText(value);
              }}
              value={articleText}
            />
            <TagsInput
              name='articleTags'
              placeholder='Введите тег'
              onAddTag={values => {
                setArticleTags(values);
              }}
              onRemoveTag={values => {
                setArticleTags(values);
              }}
              values={articleTags}
            />

            {products.map((productData, productIndex) => {
              return (
                <SearchInput
                  placeholder='Товар'
                  onSelect={value => {
                    setProducts(oldProducts => {
                      let newProducts = [...oldProducts];
                      newProducts[productIndex] = value;
                      return newProducts;
                    });
                  }}
                  searchResults={searchProducts}
                  searchRequest={val => {
                    getProducts({ name: val, limit: 25 });
                  }}
                  selectedValue={productData}
                />
              );
            })}
            <Button
              disabled={products.length >= 10}
              filled={true}
              onPress={() => {
                setProducts(oldProducts => [...oldProducts, ""]);
              }}
            >
              Добавить товар
            </Button>
          </div>
          <div className='imagesBlock'>
            {articleImages.map(image => {
              return (
                <ImageInput
                  value={image.image}
                  onChange={imageData => updateArticleImagesArray(imageData)}
                />
              );
            })}
            <ImageInput
              value={"null"}
              onChange={imageData => updateArticleImagesArray(imageData)}
            />
          </div>
        </div>
        <div className='controlsBlock'>
          <Button
            minWidth={360}
            filled={true}
            disabled={!articleTitle || !articleText}
            onPress={() => {
              isEdit
                ? updateArticle({
                    id: articleData.id,
                    name: articleTitle,
                    text: articleText,
                    tags: articleTags,
                    items: products.map(productData => productData.id),
                    imagesToRemove: articleRemovedImages,
                    imagesToAdd: articleAddededImages,
                    verificationStatus: 2,
                  })
                : createArticle({
                    name: articleTitle,
                    text: articleText,
                    tags: articleTags,
                    items: products.map(productData => productData.id),
                    verificationStatus: 1,
                    imagesToAdd: articleAddededImages,
                  });
              onCloseModal();
            }}
          >
            Сохранить
          </Button>
          <div className='mt2'></div>
          <Button
            minWidth={360}
            filled={true}
            disabled={!articleTitle && !articleText}
            onPress={() => {
              isEdit
                ? updateArticle({
                    id: articleData.id,
                    name: articleTitle,
                    text: articleText,
                    tags: articleTags,
                    verificationStatus: 2,
                    imagesToRemove: articleRemovedImages,
                    imagesToAdd: articleAddededImages,
                    items: products.map(productData => productData.id),
                  })
                : createArticle({
                    name: articleTitle,
                    text: articleText,
                    tags: articleTags,
                    items: products.map(productData => productData.id),
                    verificationStatus: 1,
                    imagesToAdd: articleAddededImages,
                  });
              onCloseModal();
            }}
          >
            Опубликовать
          </Button>
          <div className='mt2'></div>
          <Button
            minWidth={360}
            onPress={() => {
              onCloseModal();
            }}
          >
            Отменить
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default EditOrAddArticleForm;
