export const FETCH_GET_CATEGORIES_REQUEST = "FETCH_GET_CATEGORIES_REQUEST";
export const FETCH_GET_CATEGORIES_FAILURE = "FETCH_GET_CATEGORIES_FAILURE";
export const FETCH_GET_CATEGORIES_SUCCESS = "FETCH_GET_CATEGORIES_SUCCESS";

export const FETCH_REMOVE_CATEGORIES_REQUEST =
  "FETCH_REMOVE_CATEGORIES_REQUEST";
export const FETCH_REMOVE_CATEGORIES_FAILURE =
  "FETCH_REMOVE_CATEGORIES_FAILURE";
export const FETCH_REMOVE_CATEGORIES_SUCCESS =
  "FETCH_REMOVE_CATEGORIES_SUCCESS";

export const FETCH_CREATE_CATEGORY_REQUEST = "FETCH_CREATE_CATEGORY_REQUEST";
export const FETCH_CREATE_CATEGORY_FAILURE = "FETCH_CREATE_CATEGORY_FAILURE";
export const FETCH_CREATE_CATEGORY_SUCCESS = "FETCH_CREATE_CATEGORY_SUCCESS";

export const FETCH_UPDATE_CATEGORY_REQUEST = "FETCH_UPDATE_CATEGORY_REQUEST";
export const FETCH_UPDATE_CATEGORY_FAILURE = "FETCH_UPDATE_CATEGORY_FAILURE";
export const FETCH_UPDATE_CATEGORY_SUCCESS = "FETCH_UPDATE_CATEGORY_SUCCESS";
