import React from "react";
import Notification from "rc-notification";
import { ReactComponent as SuccessIcon } from "../../assets/images/SuccessIcon.svg";
import { ReactComponent as ErrorIcon } from "../../assets/images/ErrorIcon.svg";

const showMessage = (message = {}) => {
  Notification.newInstance(
    {
      prefixCls: "error-message",
      transitionName: "move-up",
      style: { top: 0 },
      HTMLElement: HTMLElement,
      maxCount: 0,
    },
    notification => {
      notification.notice({
        key: "notice-1",
        duration: 2,
        style: {},
        content: (
          <>
            {message.status === "success" ? (
              <SuccessIcon
                style={{
                  width: 20,
                  height: 20,
                  fill: "green",
                  marginRight: 10,
                }}
              />
            ) : (
              <ErrorIcon
                style={{
                  width: 20,
                  height: 20,
                  fill: "red",
                  marginRight: 10,
                  marginTop: "-3px",
                }}
              />
            )}
            {message.text}
          </>
        ),
      });
    },
  );
};
export default showMessage;
