export const FETCH_GET_COMPLAINTS_REQUEST = "FETCH_GET_COMPLAINTS_REQUEST";
export const FETCH_GET_COMPLAINTS_FAILURE = "FETCH_GET_COMPLAINTS_FAILURE";
export const FETCH_GET_COMPLAINTS_SUCCESS = "FETCH_GET_COMPLAINTS_SUCCESS";

export const FETCH_UPDATE_COMPLAINT_REQUEST = "FETCH_UPDATE_COMPLAINT_REQUEST";
export const FETCH_UPDATE_COMPLAINT_FAILURE = "FETCH_UPDATE_COMPLAINT_FAILURE";
export const FETCH_UPDATE_COMPLAINT_SUCCESS = "FETCH_UPDATE_COMPLAINT_SUCCESS";

export const FETCH_MASS_UPDATE_COMPLAINTS_REQUEST =
  "FETCH_MASS_UPDATE_COMPLAINTS_REQUEST";
export const FETCH_MASS_UPDATE_COMPLAINTS_FAILURE =
  "FETCH_MASS_UPDATE_COMPLAINTS_FAILURE";
export const FETCH_MASS_UPDATE_COMPLAINTS_SUCCESS =
  "FETCH_MASS_UPDATE_COMPLAINTS_SUCCESS";
