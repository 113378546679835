import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { withApiService } from "../../components/hoc";
import compose from "../../helpers/compose";
import { handleLogout } from "../../redux/auth/actions";

import "./Header.scss";
import { ReactComponent as Logo } from "../../assets/images/Logo.svg";
import { Button } from "../../components/FormItems";

const Header = ({ login = "", handleLogout }) => {
  return (
    <div className='Header'>
      <div className='Header__logo'>
        <Logo />
      </div>
      <div className='Header__controlsBlock'>
        <div className='Header__controlsBlock__userInfo'>{login}</div>
        <Button
          minHeight={40}
          minWidth={160}
          onPress={() => {
            handleLogout();
          }}
        >
          Выход
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = ({ auth }) => ({
  login: auth.login,
});
const mapDispatchToProps = (dispatch, { apiService }) => {
  return bindActionCreators(
    {
      handleLogout: handleLogout(),
    },
    dispatch,
  );
};

export default compose(
  withApiService(),
  connect(mapStateToProps, mapDispatchToProps),
)(Header);
