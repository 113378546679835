import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withApiService } from "../../../components/hoc";
import compose from "../../../helpers/compose";
import {
  getProducts,
  createProduct,
  updateProduct,
  removeProducts,
} from "../../../redux/products/actions";

import { getLanguages } from "../../../redux/translates/actions";

import "./ProductsPage.scss";
import { ReactComponent as EditIcon } from "../../../assets/images/editIcon.svg";
import { ReactComponent as PlusIcon } from "../../../assets/images/plusIcon.svg";

import { Button, CheckBox, Search } from "../../../components/FormItems";
import { Table } from "../../../components/Tables";
import Modal from "../../../components/Modal";
import EditOrAddProductForm from "../EditOrAddProductForm";
import RemoveProductsForm from "../RemoveProductsForm";

import {
  mapGenderIdToName,
  mapProductTypeIdToName,
} from "../../../helpers/mapper";

const ProductsPage = ({
  products,
  productsTotalCount,
  loading,
  languages = [],
  userRoleId,
  getProducts,
  createProduct,
  updateProduct,
  removeProducts,
  getLanguages,
}) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [filter, setFilter] = useState({ limit: 10, offset: 0 });

  const [showAddOrEditProductModal, setShowAddOrEditProductModal] = useState(
    false,
  );
  const [selectedProductForEdit, setSelectedProductForEdit] = useState({});
  const [showRemoveForm, setShowRemoveForm] = useState(false);

  const updateFilter = changedFields => {
    setFilter(prevState => {
      return { ...prevState, ...changedFields };
    });
    getProducts({ ...filter, ...changedFields });
  };

  useEffect(() => {
    getProducts(filter);
    getLanguages();
  }, []);

  const columns = [
    {
      title: "",
      dataIndex: "",
      width: "35px",
      render: rowData => {
        return (
          <CheckBox
            valuesList={selectedRows}
            onChange={selectedValues => {
              setSelectedRows(selectedValues);
            }}
            value={rowData}
          />
        );
      },
    },

    {
      title: "Название",
      dataIndex: "name",
      width: "350px",
      sorter: true,
      render: rowData => {
        return rowData.name && rowData.name[0] ? rowData.name[0].data : "";
      },
    },
    {
      title: "Гендер",
      dataIndex: "gender",
      width: "100px",
      render: rowData => {
        return mapGenderIdToName[rowData.gender] || "Не выбран";
      },
    },
    {
      title: "Тип товара",
      dataIndex: "itemType",
      width: "150px",
      render: rowData => {
        return mapProductTypeIdToName[rowData.itemType] || "Не выбран";
      },
    },
    {
      title: "Магазин",
      dataIndex: "shop",
      width: "250px",
      sorter: true,
      render: rowData => {
        return rowData.shop && rowData.shop.name
          ? rowData.shop.name[0].data
          : "Не выбран";
      },
    },
    {
      title: "Бренд",
      dataIndex: "brand",
      width: "200px",
      render: rowData => {
        return rowData.brand && rowData.brand.name
          ? rowData.brand.name[0].data
          : "Не выбран";
      },
    },
    {
      title: "Цена",
      dataIndex: "price",
      width: "150px",
      render: rowData => {
        return rowData.price && rowData.price[0] ? rowData.price[0].data : "";
      },
    },
    {
      title: "Цвет",
      dataIndex: "color",
      width: "150px",
      render: rowData => {
        return rowData.color && rowData.color.name
          ? rowData.color.name[0].data
          : "Не выбран";
      },
    },
    {
      title: "Материал",
      dataIndex: "material",
      width: "150px",
      render: rowData => {
        return rowData.material && rowData.material.name
          ? rowData.material.name[0].data
          : "Не выбран";
      },
    },
    {
      title: "URL",
      dataIndex: "brand",
      width: "100px",
      render: rowData => {
        return <a href={rowData.url}>URL</a>;
      },
    },
    {
      title: "Категория",
      dataIndex: "category",
      width: "250px",
      render: rowData => {
        return rowData.category && rowData.category.name
          ? rowData.category.name[0].data
          : "Нет категории";
      },
    },
    {
      title: "",
      dataIndex: "",
      width: "50px",
      render: rowData => {
        return (
          <EditIcon
            style={{ fill: "#002534" }}
            onClick={() => {
              let rowDataModify = {...rowData, preview: {...rowData.preview, image: rowData.preview.image +  `?${Math.random()}`}, photo: {...rowData.photo, image: rowData.photo.image + `?${Math.random()}`}};
              setSelectedProductForEdit(rowDataModify);
              setShowAddOrEditProductModal(true);
            }}
          />
        );
      },
    },
  ];

  return (
    <div className='page-content'>
      <Modal
        maxWidth={1140}
        onCloseModal={() => {
          setShowAddOrEditProductModal(false);
          setSelectedProductForEdit({});
        }}
        displayModal={showAddOrEditProductModal}
      >
        <EditOrAddProductForm
          productData={selectedProductForEdit}
          createProduct={productData => {
            createProduct(productData, filter);
            setSelectedProductForEdit({});
          }}
          updateProduct={productData => {
            updateProduct(productData, filter);
            setSelectedProductForEdit({});
          }}
          languages={languages}
          userRoleId={userRoleId}
        />
      </Modal>
      <Modal
        displayModal={showRemoveForm}
        onCloseModal={() => {
          setShowRemoveForm(false);
        }}
      >
        <RemoveProductsForm
          onRemove={() => {
            removeProducts(selectedRows, filter);
            setSelectedRows([]);
          }}
        />
      </Modal>
      <div className='table-filters mb2'>
        <Search
          placeholder='Название товара'
          onSelect={value => {
            updateFilter({ name: value.name, offset: 0 });
          }}
          searchResults={[]}
          searchRequest={val => {
            getProducts({ ...filter, name: val });
          }}
          selectedValue={filter["name"]}
          requestStartSumbolIndex={0}
        />
        <div className='table-filters'>
          <Button
            filled={true}
            minHeight={40}
            onPress={() => {
              setShowAddOrEditProductModal(true);
              setSelectedProductForEdit({});
            }}
          >
            <PlusIcon style={{ marginRight: 3, marginBottom: -2 }} /> Добавить
            товар
          </Button>
          <div className='mr2'></div>
          <Button
            disabled={!selectedRows.length > 0}
            minHeight={40}
            minWidth={160}
            onPress={() => {
              setShowRemoveForm(true);
            }}
          >
            Удалить
          </Button>
        </div>
      </div>
      <Table
        loading={loading}
        dataSource={products}
        columns={columns}
        tableWidth={"max-content"}
        onClickRow={() => {}}
        highlightRow={rowData => {
          return selectedRows.findIndex(item => item.id === rowData.id) > -1;
        }}
        pagination={{
          total: productsTotalCount,
          offset: filter.offset,
          limit: filter.limit,
        }}
        onChange={(pagination, sorter) => {
          let changedFields = {
            ...pagination,
            ...sorter,
          };
          updateFilter(changedFields);
        }}
      />
    </div>
  );
};

const mapStateToProps = ({ products, translates, auth }) => ({
  products: products.products,
  productsTotalCount: products.productsTotalCount,
  loading: products.loading,
  languages: translates.languages,
  userRoleId: auth.roleId,
});
const mapDispatchToProps = (
  dispatch,
  { apiService, filter, products, productData },
) => {
  return bindActionCreators(
    {
      getProducts: getProducts(apiService, filter),
      createProduct: createProduct(apiService, productData, filter),
      updateProduct: updateProduct(apiService, productData, filter),
      removeProducts: removeProducts(apiService, products, filter),
      getLanguages: getLanguages(apiService),
    },
    dispatch,
  );
};

export default compose(
  withApiService(),
  connect(mapStateToProps, mapDispatchToProps),
)(ProductsPage);
