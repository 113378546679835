import {
  FETCH_GET_BRANDS_REQUEST,
  FETCH_GET_BRANDS_FAILURE,
  FETCH_GET_BRANDS_SUCCESS,
  FETCH_REMOVE_BRANDS_REQUEST,
  FETCH_REMOVE_BRANDS_FAILURE,
  FETCH_REMOVE_BRANDS_SUCCESS,
  FETCH_CREATE_BRAND_REQUEST,
  FETCH_CREATE_BRAND_FAILURE,
  FETCH_CREATE_BRAND_SUCCESS,
  FETCH_UPDATE_BRAND_REQUEST,
  FETCH_UPDATE_BRAND_FAILURE,
  FETCH_UPDATE_BRAND_SUCCESS,
} from "./types";

const initialState = {
  brands: [],
  brandsTotalCount: 0,
  loading: false,
  error: null,
};

const brands = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GET_BRANDS_REQUEST:
    case FETCH_REMOVE_BRANDS_REQUEST:
    case FETCH_CREATE_BRAND_REQUEST:
    case FETCH_UPDATE_BRAND_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_GET_BRANDS_SUCCESS:
      return {
        ...state,
        loading: false,
        brands: action.payload.entities,
        brandsTotalCount: action.payload.count,
      };

    case FETCH_REMOVE_BRANDS_SUCCESS:
    case FETCH_CREATE_BRAND_SUCCESS:
    case FETCH_UPDATE_BRAND_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case FETCH_GET_BRANDS_FAILURE:
    case FETCH_REMOVE_BRANDS_FAILURE:
    case FETCH_CREATE_BRAND_FAILURE:
    case FETCH_UPDATE_BRAND_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};

export default brands;
