export const FETCH_GET_USERS_OUTSIDE_REQUEST =
  "FETCH_GET_USERS_OUTSIDE_REQUEST";
export const FETCH_GET_USERS_OUTSIDE_FAILURE =
  "FETCH_GET_USERS_OUTSIDE_FAILURE";
export const FETCH_GET_USERS_OUTSIDE_SUCCESS =
  "FETCH_GET_USERS_OUTSIDE_SUCCESS";

export const FETCH_UPDATE_USERS_OUTSIDE_STATUS_REQUEST =
  "FETCH_UPDATE_USERS_OUTSIDE_STATUS_REQUEST";
export const FETCH_UPDATE_USERS_OUTSIDE_STATUS_FAILURE =
  "FETCH_UPDATE_USERS_OUTSIDE_STATUS_FAILURE";
export const FETCH_UPDATE_USERS_OUTSIDE_STATUS_SUCCESS =
  "FETCH_UPDATE_USERS_OUTSIDE_STATUS_SUCCESS";
