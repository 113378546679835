import {
  getShopsRequest,
  removeShopsRequest,
  createShopRequest,
  updateShopRequest,
  getShopsFailure,
  removeShopsFailure,
  createShopFailure,
  updateShopFailure,
  getShopsSuccess,
  removeShopsSuccess,
  createShopSuccess,
  updateShopSuccess,
} from "./actionCreators";

const getShops = api => (filter, userRoleId) => dispatch => {
  dispatch(getShopsRequest());
  let getShopsMethod = userRoleId != 2 ? api.getShops : api.getOwnShops;
  getShopsMethod(filter)
    .then(res => {
      console.log(res, "res getShops");
      dispatch(getShopsSuccess(res.data));
    })
    .catch(err => {
      console.log(err, "res getShops");
      dispatch(
        getShopsFailure({
          status: "error",
          text: `Возникла ошибка с кодом ${
            err.response ? err.response.status : "???"
          } `,
        }),
      );
    });
};

const createShop = api => (
  { name, addresses = [], moderators = [] },
  filter,
) => dispatch => {
  dispatch(createShopRequest());

  let formattedShopData = {
    name,
    addresses: addresses.map(address => ({
      name: address.name,
      latitude: address.lat,
      longitude: address.lng,
    })),
    moderators: moderators.map(moderator => moderator.id),
  };

  console.log(formattedShopData, "createShop shopData ");

  api
    .createShop(formattedShopData)
    .then(res => {
      console.log(res, "res createShop");
      dispatch(createShopSuccess());

      getShops(api)(filter)(dispatch);
    })
    .catch(err => {
      console.log(err, "err createShop");
      dispatch(
        createShopFailure({
          status: "error",
          text: `Возникла ошибка с кодом ${
            err.response ? err.response.status : "???"
          } `,
        }),
      );
    });
};

const updateShop = api => (
  { id, name, addresses = [], moderators = [] },
  filter,
  userRoleId,
) => dispatch => {
  let updateShopMethod = userRoleId != 2 ? api.updateShop : api.updateOwnShop;

  dispatch(updateShopRequest());
  let formattedShopData = {
    id,
    name,
    addresses: addresses.map(address => {
      return {
        name: address.name,
        latitude: +address.lat,
        longitude: +address.lng,
      };
    }),
    moderators: moderators.map(moderator => moderator.id),
  };

  updateShopMethod(formattedShopData)
    .then(res => {
      console.log(res, "res updateShop");
      dispatch(updateShopSuccess());

      getShops(api)(filter)(dispatch);
    })
    .catch(err => {
      console.log(err, "err updateShop");
      dispatch(
        updateShopFailure({
          status: "error",
          text: `Возникла ошибка с кодом ${
            err.response ? err.response.status : "???"
          } `,
        }),
      );
    });
};

const removeShops = api => (shops = [], filter) => dispatch => {
  dispatch(removeShopsRequest());
  api
    .removeShops(shops.map(shop => shop.id))
    .then(res => {
      console.log(res, "res removeShops");
      dispatch(removeShopsSuccess());

      getShops(api)(filter)(dispatch);
    })
    .catch(err => {
      console.log(err, "err removeShops");
      dispatch(
        removeShopsFailure({
          status: "error",
          text: `Возникла ошибка с кодом ${
            err.response ? err.response.status : "???"
          } `,
        }),
      );
    });
};
export { getShops, createShop, updateShop, removeShops };
