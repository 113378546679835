import {
  getModerationArticlesRequest,
  massUpdateModerationArticlesRequest,
  updateModerationArticleRequest,
  getModerationArticlesFailure,
  massUpdateModerationArticlesFailure,
  updateModerationArticleFailure,
  getModerationArticlesSuccess,
  massUpdateModerationArticlesSuccess,
  updateModerationArticleSuccess,
} from "./actionCreators";

const getModerationArticles = api => filter => dispatch => {
  dispatch(getModerationArticlesRequest());
  api
    .getModerationArticles(filter)
    .then(res => {
      console.log(res, "res getModerationArticles");
      dispatch(getModerationArticlesSuccess(res.data));
    })
    .catch(err => {
      console.log(err, "res getModerationArticles");
      dispatch(
        getModerationArticlesFailure({
          status: "error",
          text: `Возникла ошибка с кодом ${
            err.response ? err.response.status : "???"
          } `,
        }),
      );
    });
};

const updateModerationArticle = api => (moderationData, filter) => dispatch => {
  dispatch(updateModerationArticleRequest());

  api
    .updateModerationArticle(moderationData)
    .then(res => {
      console.log(res, "res updateModeration");
      dispatch(updateModerationArticleSuccess());

      getModerationArticles(api)(filter)(dispatch);
    })
    .catch(err => {
      console.log(err, "err updateModeration");
      dispatch(
        updateModerationArticleFailure({
          status: "error",
          text: `Возникла ошибка с кодом ${
            err.response ? err.response.status : "???"
          } `,
        }),
      );
    });
};

const massUpdateModerationArticles = api => (
  moderations = [],
  status,
  filter,
) => dispatch => {
  dispatch(massUpdateModerationArticlesRequest());
  api
    .massUpdateModerationArticles({
      moderationArticlesIds: moderations.map(moderation => moderation.id),
      status,
    })
    .then(res => {
      console.log(res, "res massUpdateModerationArticles");
      dispatch(massUpdateModerationArticlesSuccess());

      getModerationArticles(api)(filter)(dispatch);
    })
    .catch(err => {
      console.log(err, "err massUpdateModerationArticles");
      dispatch(
        massUpdateModerationArticlesFailure({
          status: "error",
          text: `Возникла ошибка с кодом ${
            err.response ? err.response.status : "???"
          } `,
        }),
      );
    });
};

export {
  getModerationArticles,
  updateModerationArticle,
  massUpdateModerationArticles,
};
