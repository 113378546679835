import {
  FETCH_GET_USERS_OUTSIDE_REQUEST,
  FETCH_GET_USERS_OUTSIDE_FAILURE,
  FETCH_GET_USERS_OUTSIDE_SUCCESS,
  FETCH_UPDATE_USERS_OUTSIDE_STATUS_REQUEST,
  FETCH_UPDATE_USERS_OUTSIDE_STATUS_FAILURE,
  FETCH_UPDATE_USERS_OUTSIDE_STATUS_SUCCESS,
} from "./types";

const initialState = {
  usersOutside: [],
  usersOutsideTotalCount: 0,
  loading: false,
  error: null,
};

const usersOutside = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GET_USERS_OUTSIDE_REQUEST:
    case FETCH_UPDATE_USERS_OUTSIDE_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_GET_USERS_OUTSIDE_SUCCESS:
      return {
        ...state,
        loading: false,
        usersOutside: action.payload.entities,
        usersOutsideTotalCount: action.payload.count,
      };

    case FETCH_UPDATE_USERS_OUTSIDE_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case FETCH_GET_USERS_OUTSIDE_FAILURE:
    case FETCH_UPDATE_USERS_OUTSIDE_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};

export default usersOutside;
