import React from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import "./App.scss";
import Header from "../Header";
import Nav from "../Nav";
import AuthPage from "../../pages/AuthPage";
import UsersOutsidePage from "../../pages/UsersOutsidePage";
import UsersInsidePage from "../../pages/UsersInsidePage";
import ShopsPage from "../../pages/ShopsPage";
import BrandsPage from "../../pages/BrandsPage";
import SizesPage from "../../pages/SizesPage";
import SizesDetailPage from "../../pages/SizesDetailPage";
import SizesPageWatchOnly from "../../pages/SizesPageWatchOnly";
import SizesDetailPageWatchOnly from "../../pages/SizesDetailPageWatchOnly";
import CategoriesPage from "../../pages/CategoriesPage";
import ProductsPage from "../../pages/ProductsPage";
import ModerationArticlePage from "../../pages/ModerationArticlePage";
import ModerationLookPage from "../../pages/ModerationLookPage";
import ArticlesPage from "../../pages/ArticlesPage";
import ComplaintsPage from "../../pages/ComplaintsPage";
import TranslatesPage from "../../pages/TranslatesPage";

const App = ({ token, roleId }) => {
  if (!token) {
    return <AuthPage />;
  }

  const roleToRoutes = role => {
    let availableRoutes = [];

    if ([0].findIndex(item => item == role) > -1) {
      availableRoutes.push(
        <Route exact path='/' component={UsersOutsidePage} />,
        <Route exact path='/users-inside' component={UsersInsidePage} />,
        <Route exact path='/brands' component={BrandsPage} />,
        <Route exact path='/categories' component={CategoriesPage} />,
        // <Route exact path='/sizes' component={SizesPage} />,
        // <Route exact path='/sizes/:id' component={SizesDetailPage} />,
      );
    }
    if ([0, 2].findIndex(item => item == role) > -1) {
      availableRoutes.push(
        <Route exact path='/products' component={ProductsPage} />,
        <Route exact path='/shops' component={ShopsPage} />,
        <Route
          exact
          path='/sizes'
          component={role === 2 ? SizesPageWatchOnly : SizesPage}
        />,
        <Route
          exact
          path='/sizes/:id'
          component={role === 2 ? SizesDetailPageWatchOnly : SizesDetailPage}
        />,
      );
    }
    if ([0, 1].findIndex(item => item == role) > -1) {
      availableRoutes.push(
        <Route
          exact
          path='/moderation-articles'
          component={ModerationArticlePage}
        />,
        <Route exact path='/moderation-looks' component={ModerationLookPage} />,
        <Route exact path='/articles' component={ArticlesPage} />,
        <Route exact path='/complaints' component={ComplaintsPage} />,
        <Route exact path='/translates' component={TranslatesPage} />,
      );
    }

    return availableRoutes;
  };
  return (
    <>
      <Header />
      <div className='Layout'>
        <Nav />
        <Switch>{roleToRoutes(roleId)}</Switch>
      </div>
    </>
  );
};
const mapStateToProps = ({ auth }) => ({
  token: auth.token,
  roleId: auth.roleId,
});
export default connect(mapStateToProps, null)(withRouter(App));
