import React from "react";
import "./CheckBox.scss";

const CheckBox = ({ valuesList = [], onChange, value = {} }) => {
  let checkboxStatus = valuesList.findIndex(item => item.id === value.id) > -1;

  return (
    <div className='Checkbox'>
      <input onChange={() => {}} checked={checkboxStatus} type='checkbox' />
      <label
        onClick={() => {
          if (onChange) {
            let valueIndex = valuesList.findIndex(item => item.id === value.id);

            // Если в массиве есть value удаляем его
            if (valueIndex > -1) {
              onChange([
                ...valuesList.slice(0, valueIndex),
                ...valuesList.slice(valueIndex + 1),
              ]);
            } else {
              // Если в массиве нет value добавляем его
              onChange([...valuesList, value]);
            }
          }
        }}
      ></label>
    </div>
  );
};

export default CheckBox;
