export const FETCH_GET_USERS_INSIDE_REQUEST = "FETCH_GET_USERS_INSIDE_REQUEST";
export const FETCH_GET_USERS_INSIDE_FAILURE = "FETCH_GET_USERS_INSIDE_FAILURE";
export const FETCH_GET_USERS_INSIDE_SUCCESS = "FETCH_GET_USERS_INSIDE_SUCCESS";

export const FETCH_REMOVE_USERS_INSIDE_REQUEST =
  "FETCH_REMOVE_USERS_INSIDE_REQUEST";
export const FETCH_REMOVE_USERS_INSIDE_FAILURE =
  "FETCH_REMOVE_USERS_INSIDE_FAILURE";
export const FETCH_REMOVE_USERS_INSIDE_SUCCESS =
  "FETCH_REMOVE_USERS_INSIDE_SUCCESS";

export const FETCH_CREATE_USER_INSIDE_REQUEST =
  "FETCH_CREATE_USER_INSIDE_REQUEST";
export const FETCH_CREATE_USER_INSIDE_FAILURE =
  "FETCH_CREATE_USER_INSIDE_FAILURE";
export const FETCH_CREATE_USER_INSIDE_SUCCESS =
  "FETCH_CREATE_USER_INSIDE_SUCCESS";

export const FETCH_UPDATE_USER_INSIDE_REQUEST =
  "FETCH_UPDATE_USER_INSIDE_REQUEST";
export const FETCH_UPDATE_USER_INSIDE_FAILURE =
  "FETCH_UPDATE_USER_INSIDE_FAILURE";
export const FETCH_UPDATE_USER_INSIDE_SUCCESS =
  "FETCH_UPDATE_USER_INSIDE_SUCCESS";
