import {
  FETCH_GET_SIZES_REQUEST,
  FETCH_GET_SIZES_FAILURE,
  FETCH_GET_SIZES_SUCCESS,
  FETCH_REMOVE_SIZES_REQUEST,
  FETCH_REMOVE_SIZES_FAILURE,
  FETCH_REMOVE_SIZES_SUCCESS,
  FETCH_CREATE_SIZE_REQUEST,
  FETCH_CREATE_SIZE_FAILURE,
  FETCH_CREATE_SIZE_SUCCESS,
  FETCH_UPDATE_SIZE_REQUEST,
  FETCH_UPDATE_SIZE_FAILURE,
  FETCH_UPDATE_SIZE_SUCCESS,
} from "./types";

export const getSizesRequest = () => {
  return { type: FETCH_GET_SIZES_REQUEST };
};
export const removeSizesRequest = () => {
  return { type: FETCH_REMOVE_SIZES_REQUEST };
};
export const createSizeRequest = () => {
  return { type: FETCH_CREATE_SIZE_REQUEST };
};
export const updateSizeRequest = () => {
  return { type: FETCH_UPDATE_SIZE_REQUEST };
};

export const getSizesFailure = error => {
  return { type: FETCH_GET_SIZES_FAILURE, payload: error };
};
export const removeSizesFailure = error => {
  return { type: FETCH_REMOVE_SIZES_FAILURE, payload: error };
};
export const createSizeFailure = error => {
  return { type: FETCH_CREATE_SIZE_FAILURE, payload: error };
};
export const updateSizeFailure = error => {
  return { type: FETCH_UPDATE_SIZE_FAILURE, payload: error };
};

export const getSizesSuccess = sizesData => {
  return { type: FETCH_GET_SIZES_SUCCESS, payload: sizesData };
};
export const removeSizesSuccess = () => {
  return { type: FETCH_REMOVE_SIZES_SUCCESS };
};
export const createSizeSuccess = () => {
  return { type: FETCH_CREATE_SIZE_SUCCESS };
};
export const updateSizeSuccess = () => {
  return { type: FETCH_UPDATE_SIZE_SUCCESS };
};
