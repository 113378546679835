import {
  FETCH_GET_SHOPS_REQUEST,
  FETCH_GET_SHOPS_FAILURE,
  FETCH_GET_SHOPS_SUCCESS,
  FETCH_REMOVE_SHOPS_REQUEST,
  FETCH_REMOVE_SHOPS_FAILURE,
  FETCH_REMOVE_SHOPS_SUCCESS,
  FETCH_CREATE_SHOP_REQUEST,
  FETCH_CREATE_SHOP_FAILURE,
  FETCH_CREATE_SHOP_SUCCESS,
  FETCH_UPDATE_SHOP_REQUEST,
  FETCH_UPDATE_SHOP_FAILURE,
  FETCH_UPDATE_SHOP_SUCCESS,
} from "./types";

export const getShopsRequest = () => {
  return { type: FETCH_GET_SHOPS_REQUEST };
};
export const removeShopsRequest = () => {
  return { type: FETCH_REMOVE_SHOPS_REQUEST };
};
export const createShopRequest = () => {
  return { type: FETCH_CREATE_SHOP_REQUEST };
};
export const updateShopRequest = () => {
  return { type: FETCH_UPDATE_SHOP_REQUEST };
};

export const getShopsFailure = error => {
  return { type: FETCH_GET_SHOPS_FAILURE, payload: error };
};
export const removeShopsFailure = error => {
  return { type: FETCH_REMOVE_SHOPS_FAILURE, payload: error };
};
export const createShopFailure = error => {
  return { type: FETCH_CREATE_SHOP_FAILURE, payload: error };
};
export const updateShopFailure = error => {
  return { type: FETCH_UPDATE_SHOP_FAILURE, payload: error };
};

export const getShopsSuccess = shopsData => {
  return { type: FETCH_GET_SHOPS_SUCCESS, payload: shopsData };
};
export const removeShopsSuccess = () => {
  return { type: FETCH_REMOVE_SHOPS_SUCCESS };
};
export const createShopSuccess = () => {
  return { type: FETCH_CREATE_SHOP_SUCCESS };
};
export const updateShopSuccess = () => {
  return { type: FETCH_UPDATE_SHOP_SUCCESS };
};
