import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { SidebarMenu, SidebarMenuItemTitle, SidebarSubMenu } from "../Sidebar";

const Nav = ({ history, location, roleId }) => {
  // При открытии страницы определяем какой блок меню должен быть открытым
  let initialHighlightRowId = "";

  if (["/", "/users-inside", "/content-managers"].includes(location.pathname)) {
    initialHighlightRowId = "sub1";
  }
  if (
    ["/moderation-articles", "/moderation-looks", "/system-articles"].includes(
      location.pathname,
    )
  ) {
    initialHighlightRowId = "sub2";
  }

  const [selectedSidebarRow, setSelectedSidebarRow] = useState(
    initialHighlightRowId,
  );

  const roleToRoutes = role => {
    let availableRoutes = [];

    if ([0].findIndex(item => item == role) > -1) {
      availableRoutes.push(
        <SidebarSubMenu key='1' itemId='sub1' label='Пользователи'>
          <SidebarMenuItemTitle
            highlighted={location.pathname === "/"}
            onClick={() => {
              history.push("/");
            }}
            key='2'
            label='Внешние'
          />
          <SidebarMenuItemTitle
            key='3'
            highlighted={location.pathname === "/users-inside"}
            onClick={() => {
              history.push("/users-inside");
            }}
            label='Внутренние'
          />
        </SidebarSubMenu>,
        <SidebarMenuItemTitle
          highlighted={location.pathname === "/brands"}
          onClick={() => {
            history.push("/brands");
          }}
          key='8'
          label='Бренды'
        />,
        <SidebarMenuItemTitle
          key='10'
          highlighted={location.pathname === "/categories"}
          onClick={() => {
            history.push("/categories");
          }}
          label='Категории'
        />,
      );
    }
    if ([0, 2].findIndex(item => item == role) > -1) {
      availableRoutes.push(
        <SidebarMenuItemTitle
          key='9'
          highlighted={location.pathname.indexOf("/sizes") > -1}
          onClick={() => {
            history.push("/sizes");
          }}
          label='Размерные сетки'
        />,
        <SidebarMenuItemTitle
          highlighted={location.pathname === "/shops"}
          onClick={() => {
            history.push("/shops");
          }}
          key='6'
          label='Магазины'
        />,
        <SidebarMenuItemTitle
          key='11'
          highlighted={location.pathname === "/products"}
          onClick={() => {
            history.push("/products");
          }}
          label='Товары'
        />,
      );
    }
    if ([0, 1].findIndex(item => item == role) > -1) {
      availableRoutes.push(
        <SidebarSubMenu key='12' itemId='sub2' label='Статьи'>
          <SidebarMenuItemTitle
            key='13'
            highlighted={location.pathname === "/moderation-looks"}
            onClick={() => {
              history.push("/moderation-looks");
            }}
            label='Модерация образов'
          />
          <SidebarMenuItemTitle
            key='13'
            highlighted={location.pathname === "/moderation-articles"}
            onClick={() => {
              history.push("/moderation-articles");
            }}
            label='Модерация статей'
          />

          <SidebarMenuItemTitle
            key='14'
            highlighted={location.pathname === "/articles"}
            onClick={() => {
              history.push("/articles");
            }}
            label='Системные статьи'
          />
        </SidebarSubMenu>,
        <SidebarMenuItemTitle
          key='15'
          highlighted={location.pathname === "/complaints"}
          onClick={() => {
            history.push("/complaints");
          }}
          label='Жалобы'
        />,
        <SidebarMenuItemTitle
          key='16'
          highlighted={location.pathname === "/translates"}
          onClick={() => {
            history.push("/translates");
          }}
          label='Переводы'
        />,
      );
    }
    return availableRoutes;
  };
  return (
    <SidebarMenu
      onSelect={selectedId => {
        if (selectedId === selectedSidebarRow) {
          setSelectedSidebarRow("");
        } else {
          setSelectedSidebarRow(selectedId);
        }
      }}
      openSidebarIds={[selectedSidebarRow]}
    >
      {roleToRoutes(roleId)}
    </SidebarMenu>
  );
};

const mapStateToProps = ({ auth }) => ({ roleId: auth.roleId });
export default connect(mapStateToProps, null)(withRouter(Nav));
