import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withApiService } from "../../../components/hoc";
import compose from "../../../helpers/compose";
import {
  getComplaints,
  updateComplaint,
  massUpdateComplaints,
} from "../../../redux/complaints/actions";

import "./ComplaintsPage.scss";
import {
  Button,
  CheckBox,
  Search,
  Select,
} from "../../../components/FormItems";
import { Table } from "../../../components/Tables";

import Modal from "../../../components/Modal";
import EditArticleForm from "../EditArticleForm";
import EditLookForm from "../EditLookForm";

import { ReactComponent as FilterIcon } from "../../../assets/images/filterIcon.svg";
import { ReactComponent as ShowIcon } from "../../../assets/images/showIcon.svg";

import { mapComplaintTypeToName } from "../../../helpers/mapper";

const ComplaintsPage = ({
  complaints,
  complaintsTotalCount,
  loading,
  getComplaints,
  updateComplaint,
  massUpdateComplaints,
}) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [filter, setFilter] = useState({ limit: 10, offset: 0 });

  const [showEditArticleModal, setShowEditArticleModal] = useState(false);
  const [showEditLookModal, setShowEditLookModal] = useState(false);
  const [showEditTryClothesModal, setShowEditTryClothesModal] = useState(false);

  const [selectedRowForEdit, setSelectedRowForEdit] = useState({});

  const [selectedStatus, setSelectedStatus] = useState({
    id: 0,
    name: "Новая",
  });

  const updateFilter = changedFields => {
    setFilter(prevState => {
      return { ...prevState, ...changedFields };
    });

    getComplaints({ ...filter, ...changedFields });
  };

  useEffect(() => {
    getComplaints(filter);
  }, []);
  const columns = [
    {
      title: "",
      dataIndex: "",
      width: "5%",
      render: rowData => {
        return (
          <CheckBox
            valuesList={selectedRows}
            onChange={selectedValues => {
              setSelectedRows(selectedValues);
            }}
            value={rowData}
          />
        );
      },
    },
    {
      title: "Заявитель",
      dataIndex: "user",
      width: "20%",
      render: rowData => {
        return rowData.user ? rowData.user.nickname : "";
      },
    },
    {
      title: "Тип контента",
      dataIndex: "contentType",
      width: "10%",
      render: rowData => {
        return mapComplaintTypeToName[rowData.contentType];
      },
    },
    {
      title: "Автор",
      dataIndex: "owner",
      width: "35%",
      render: rowData => {
        return rowData.content
          ? rowData.content.author
            ? rowData.content.author.nickname
            : ""
          : "";
      },
    },
    {
      title: "Статус жалобы",
      dataIndex: "status",
      width: "10%",
      render: rowData => {
        if (rowData.status === 0) {
          return <span className='red-text'>Новая</span>;
        } else if (rowData.status === 1) {
          return <span className='green-text'>Принята</span>;
        } else {
          return <span className='red-text'>Отклонена</span>;
        }
      },
    },
    {
      title: "",
      dataIndex: "",
      width: "5%",
      render: rowData => {
        return (
          <ShowIcon
            style={{ width: 20, height: 20 }}
            onClick={() => {
              setSelectedRowForEdit(rowData);
              if (rowData.contentType === 0) {
                setShowEditLookModal(true);
              } else if (rowData.contentType === 1) {
                setShowEditArticleModal(true);
              } else if (rowData.contentType === 2) {
                setShowEditTryClothesModal(true);
              }
            }}
          />
        );
      },
    },
  ];
  return (
    <div className='page-content'>
      <Modal
        maxWidth={900}
        onCloseModal={() => {
          setShowEditTryClothesModal(false);
          setSelectedRowForEdit({});
        }}
        displayModal={showEditTryClothesModal}
      >
        <EditLookForm
          lookData={
            { ...selectedRowForEdit.content, id: selectedRowForEdit.id } || {}
          }
          status={selectedRowForEdit.status || 0}
          updateComplaint={complaintData => {
            updateComplaint(complaintData, filter);
          }}
        />
      </Modal>
      <Modal
        maxWidth={900}
        onCloseModal={() => {
          setShowEditLookModal(false);
          setSelectedRowForEdit({});
        }}
        displayModal={showEditLookModal}
      >
        <EditLookForm
          lookData={
            { ...selectedRowForEdit.content, id: selectedRowForEdit.id } || {}
          }
          status={selectedRowForEdit.status || 0}
          updateComplaint={complaintData => {
            updateComplaint(complaintData, filter);
          }}
        />
      </Modal>
      <Modal
        maxWidth={900}
        onCloseModal={() => {
          setShowEditArticleModal(false);
          setSelectedRowForEdit({});
        }}
        displayModal={showEditArticleModal}
      >
        <EditArticleForm
          articleData={
            { ...selectedRowForEdit.content, id: selectedRowForEdit.id } || {}
          }
          status={selectedRowForEdit.status || 0}
          updateComplaint={complaintData => {
            updateComplaint(complaintData, filter);
          }}
        />
      </Modal>

      <div className='table-filters mb2'>
        <div className='table-filters'>
          <Search
            placeholder='Поиск по заявителю'
            onSelect={value => {}}
            searchResults={[]}
            searchRequest={val => {
              updateFilter({ nickname: val, offset: 0 });
            }}
            selectedValue={{}}
            requestStartSumbolIndex={0}
          />
          <div className='mr2'></div>
          <Select
            placeholder='Фильтр по статусу'
            width={"200px"}
            onSelect={value => {
              updateFilter({ status: value, offset: 0 });
            }}
            selectedValue={filter["status"]}
            options={[
              { id: 0, name: "Новая" },
              { id: 1, name: "Принята" },
              { id: 2, name: "Отклонена" },
            ]}
            icon={<FilterIcon style={{ marginTop: 5 }} />}
          />
        </div>
        <div className='table-filters'>
          <Select
            placeholder='Статус'
            width={"200px"}
            onSelect={value => {
              setSelectedStatus(value);
            }}
            selectedValue={selectedStatus}
            options={[
              { id: 0, name: "Новая" },
              { id: 1, name: "Принята" },
              { id: 2, name: "Отклонена" },
            ]}
          />
          <div className='mr2'></div>
          <Button
            filled={true}
            minHeight={40}
            minWidth={160}
            disabled={
              !(selectedRows.length > 0) && selectedStatus.id !== undefined
            }
            onPress={() => {
              massUpdateComplaints(selectedRows, selectedStatus.id, filter);
            }}
          >
            Изменить статус
          </Button>
        </div>
      </div>
      <Table
        loading={loading}
        dataSource={complaints}
        columns={columns}
        onClickRow={() => {}}
        highlightRow={rowData => {
          return selectedRows.findIndex(item => item.id === rowData.id) > -1;
        }}
        pagination={{
          total: complaintsTotalCount,
          offset: filter.offset,
          limit: filter.limit,
        }}
        onChange={(pagination, sorter) => {
          let changedFields = {
            ...pagination,
            ...sorter,
          };
          updateFilter(changedFields);
        }}
      />
    </div>
  );
};

const mapStateToProps = ({ complaints }) => ({
  complaints: complaints.complaints,
  complaintsTotalCount: complaints.complaintsTotalCount,
  loading: complaints.loading,
});
const mapDispatchToProps = (
  dispatch,
  { apiService, filter, complaintData, selectedComplaints, status },
) => {
  return bindActionCreators(
    {
      getComplaints: getComplaints(apiService, filter),
      updateComplaint: updateComplaint(apiService, complaintData, filter),
      massUpdateComplaints: massUpdateComplaints(
        apiService,
        selectedComplaints,
        status,
        filter,
      ),
    },
    dispatch,
  );
};

export default compose(
  withApiService(),
  connect(mapStateToProps, mapDispatchToProps),
)(ComplaintsPage);
