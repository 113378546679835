import {
  FETCH_GET_TRANSLATE_REQUEST,
  FETCH_GET_TRANSLATE_FAILURE,
  FETCH_GET_TRANSLATE_SUCCESS,
  FETCH_GET_LANGUAGES_REQUEST,
  FETCH_GET_LANGUAGES_FAILURE,
  FETCH_GET_LANGUAGES_SUCCESS,
  FETCH_UPDATE_TRANSLATE_REQUEST,
  FETCH_UPDATE_TRANSLATE_FAILURE,
  FETCH_UPDATE_TRANSLATE_SUCCESS,
} from "./types";

export const getTranslateRequest = () => {
  return { type: FETCH_GET_TRANSLATE_REQUEST };
};
export const getTranslateFailure = error => {
  return { type: FETCH_GET_TRANSLATE_FAILURE, payload: error };
};
export const getTranslateSuccess = translateData => {
  return { type: FETCH_GET_TRANSLATE_SUCCESS, payload: translateData };
};

export const getLanguagesRequest = () => {
  return { type: FETCH_GET_LANGUAGES_REQUEST };
};
export const getLanguagesFailure = error => {
  return { type: FETCH_GET_LANGUAGES_FAILURE, payload: error };
};
export const getLanguagesSuccess = languagesData => {
  return { type: FETCH_GET_LANGUAGES_SUCCESS, payload: languagesData };
};

export const updateTranslateRequest = () => {
  return { type: FETCH_UPDATE_TRANSLATE_REQUEST };
};
export const updateTranslateFailure = error => {
  return { type: FETCH_UPDATE_TRANSLATE_FAILURE, payload: error };
};
export const updateTranslateSuccess = () => {
  return { type: FETCH_UPDATE_TRANSLATE_SUCCESS };
};
