import React from "react";

import "./Sorter.scss";
import { ReactComponent as SortDesc } from "../../../assets/images/sortUp.svg";
import { ReactComponent as SortAsc } from "../../../assets/images/sortDown.svg";

const Sorter = ({ sortValue, onChange = () => {} }) => {
  return (
    <button
      className='Sorter'
      onClick={() => {
        let newSortValue = sortValue === "asc" ? "desc" : "asc";
        onChange(newSortValue);
      }}
    >
      {sortValue === "asc" ? (
        <SortDesc className='SortDesc' />
      ) : (
        <SortAsc className='SortAsc' />
      )}
    </button>
  );
};

export default Sorter;
