import {
  FETCH_GET_COMPLAINTS_REQUEST,
  FETCH_GET_COMPLAINTS_FAILURE,
  FETCH_GET_COMPLAINTS_SUCCESS,
  FETCH_UPDATE_COMPLAINT_REQUEST,
  FETCH_UPDATE_COMPLAINT_FAILURE,
  FETCH_UPDATE_COMPLAINT_SUCCESS,
  FETCH_MASS_UPDATE_COMPLAINTS_REQUEST,
  FETCH_MASS_UPDATE_COMPLAINTS_SUCCESS,
  FETCH_MASS_UPDATE_COMPLAINTS_FAILURE,
} from "./types";

export const getComplaintsRequest = () => {
  return { type: FETCH_GET_COMPLAINTS_REQUEST };
};
export const massUpdateComplaintsRequest = () => {
  return { type: FETCH_MASS_UPDATE_COMPLAINTS_REQUEST };
};
export const updateComplaintRequest = () => {
  return { type: FETCH_UPDATE_COMPLAINT_REQUEST };
};

export const getComplaintsFailure = error => {
  return { type: FETCH_GET_COMPLAINTS_FAILURE, payload: error };
};
export const massUpdateComplaintsFailure = error => {
  return { type: FETCH_MASS_UPDATE_COMPLAINTS_FAILURE, payload: error };
};
export const updateComplaintFailure = error => {
  return { type: FETCH_UPDATE_COMPLAINT_FAILURE, payload: error };
};

export const getComplaintsSuccess = complaintsData => {
  return { type: FETCH_GET_COMPLAINTS_SUCCESS, payload: complaintsData };
};
export const massUpdateComplaintsSuccess = () => {
  return { type: FETCH_MASS_UPDATE_COMPLAINTS_SUCCESS };
};
export const updateComplaintSuccess = () => {
  return { type: FETCH_UPDATE_COMPLAINT_SUCCESS };
};
