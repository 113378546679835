import React from "react";

import "./Button.scss";

const Button = ({
  onPress = () => {},
  disabled = false,
  minHeight = 50,
  minWidth = "auto",
  filled = false,
  children,
  onMouseEnter = () => {},
  onMouseOut = () => {},
}) => {
  return (
    <button
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseOut}
      style={{ minHeight, minWidth }}
      disabled={disabled}
      className={"Button " + (filled ? " Button--filled " : "")}
      onClick={e => {
        onPress();
      }}
    >
      {children}
    </button>
  );
};

export default Button;
