import {
  FETCH_GET_MODERATION_ARTICLES_REQUEST,
  FETCH_GET_MODERATION_ARTICLES_FAILURE,
  FETCH_GET_MODERATION_ARTICLES_SUCCESS,
  FETCH_UPDATE_MODERATION_ARTICLE_REQUEST,
  FETCH_UPDATE_MODERATION_ARTICLE_FAILURE,
  FETCH_UPDATE_MODERATION_ARTICLE_SUCCESS,
  FETCH_MASS_UPDATE_MODERATION_ARTICLES_REQUEST,
  FETCH_MASS_UPDATE_MODERATION_ARTICLES_SUCCESS,
  FETCH_MASS_UPDATE_MODERATION_ARTICLES_FAILURE,
} from "./types";

export const getModerationArticlesRequest = () => {
  return { type: FETCH_GET_MODERATION_ARTICLES_REQUEST };
};
export const massUpdateModerationArticlesRequest = () => {
  return { type: FETCH_MASS_UPDATE_MODERATION_ARTICLES_REQUEST };
};

export const updateModerationArticleRequest = () => {
  return { type: FETCH_UPDATE_MODERATION_ARTICLE_REQUEST };
};

export const getModerationArticlesFailure = error => {
  return { type: FETCH_GET_MODERATION_ARTICLES_FAILURE, payload: error };
};
export const massUpdateModerationArticlesFailure = error => {
  return {
    type: FETCH_MASS_UPDATE_MODERATION_ARTICLES_FAILURE,
    payload: error,
  };
};

export const updateModerationArticleFailure = error => {
  return { type: FETCH_UPDATE_MODERATION_ARTICLE_FAILURE, payload: error };
};

export const getModerationArticlesSuccess = moderationData => {
  return {
    type: FETCH_GET_MODERATION_ARTICLES_SUCCESS,
    payload: moderationData,
  };
};
export const massUpdateModerationArticlesSuccess = () => {
  return { type: FETCH_MASS_UPDATE_MODERATION_ARTICLES_SUCCESS };
};

export const updateModerationArticleSuccess = () => {
  return { type: FETCH_UPDATE_MODERATION_ARTICLE_SUCCESS };
};
