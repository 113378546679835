import React, { useState, useEffect } from "react";
import "./EditOrAddBrandForm.scss";
import {
  Form,
  TextInput,
  Button,
  SelectInput,
} from "../../../components/FormItems";
import Stepper from "../../../components/Stepper";

const getAvailableTranslates = (translates, languages) => {
  let tmpAvailableLanguages = [];
  let formatTranslates = translates ? translates : [];

  languages.map(language => {
    let findTranslate = formatTranslates.find(
      translate => translate.languageCode === language.code,
    );
    if (!findTranslate) {
      tmpAvailableLanguages.push({ ...language, languageCode: language.code });
    }
  });
  return tmpAvailableLanguages;
};

const changeSelectedTranslate = (
  translates,
  currentTranslate,
  nextTranslateIndex,
) => {
  // Находим индекс текущего перевода в списке пеереводов
  let formatTranslates = translates ? translates : [];

  let currentTranslateIndex = formatTranslates.findIndex(
    translate => translate.languageCode === currentTranslate.languageCode,
  );

  if (formatTranslates[currentTranslateIndex + nextTranslateIndex]) {
    return { ...formatTranslates[currentTranslateIndex + nextTranslateIndex] };
  } else {
    return nextTranslateIndex > 0
      ? formatTranslates[0]
      : formatTranslates[formatTranslates.length - 1];
  }
};

const addNewTranslateToFormTranslates = (addededTranslate, formTranslates) => {
  return [
    ...formTranslates,
    { data: "", languageCode: addededTranslate.languageCode },
  ];
};

const onChangeTranslatedField = (value, translate, formTranslates) => {
  // Находим перевод который нужно изменить
  let changedTranslateIndex = formTranslates.findIndex(
    formTranslate => formTranslate.languageCode === translate.languageCode,
  );

  formTranslates[changedTranslateIndex].data = value;

  return [...formTranslates];
};

const EditOrAddBrandForm = ({
  onCloseModal,
  brandData = {},
  createBrand,
  updateBrand,
  languages = [],
}) => {
  const isEdit = brandData.name ? true : false;

  const [brandName, setBrandName] = useState([
    { languageCode: "en", data: "" },
  ]);
  const [selectedTranslate, setSelectedTranslate] = useState({
    languageCode: "en",
  });
  const [selectedTranslateToAdd, setSelectedTranslateToAdd] = useState({});

  useEffect(() => {
    setBrandName(
      brandData.name ? brandData.name : [{ languageCode: "en", data: "" }],
    );
  }, [brandData]);

  // Подготавливаем список языков которые можно добавить
  let availableToAddTranslates = getAvailableTranslates(
    brandData.name,
    languages,
  );

  let selectedTranslateName = languages.find(
    language => language.code === selectedTranslate.languageCode,
  );
  let selectedTranslateBrandValue = brandName.find(
    brand => brand.languageCode === selectedTranslate.languageCode,
  );

  return (
    <Stepper
      onNextStep={() => {
        setSelectedTranslate(
          changeSelectedTranslate(brandName, selectedTranslate, 1),
        );
      }}
      onPrevStep={() => {
        setSelectedTranslate(
          changeSelectedTranslate(brandName, selectedTranslate, -1),
        );
      }}
    >
      <div className='EditOrAddBrandForm'>
        <Form title=''>
          <div className='translate-flex-row'>
            <div className='translate-flex-col'>
              <div className='translate-title'>
                Добавление/редактирование бренда
              </div>
              <div className='translate-count'>
                Переводов: {brandName.length}
              </div>
              <div className='translate-language'>
                Выбран:{" "}
                {selectedTranslateName ? selectedTranslateName.name : ""}
              </div>
            </div>
            <div className='translate-flex-row'>
              <SelectInput
                placeholder='Перевод'
                onSelect={value => setSelectedTranslateToAdd(value)}
                selectedValue={selectedTranslateToAdd}
                options={availableToAddTranslates}
              />
              <div className='mr2'></div>
              <Button
                disabled={!selectedTranslateToAdd.name}
                filled={true}
                onPress={() => {
                  // Добавляем перевод в список переводов
                  setBrandName(
                    addNewTranslateToFormTranslates(
                      selectedTranslateToAdd,
                      brandName,
                    ),
                  );
                  // Сбрасываем выбранный для добавления язык
                  setSelectedTranslateToAdd({});

                  // Переключаемся на последний перевод
                }}
              >
                Добавить перевод
              </Button>
            </div>
          </div>
          <div className='EditOrAddUserInsideForm__inputsBlock'>
            <TextInput
              type='text'
              name='brandName'
              placeholder='Название'
              onChange={value => {
                setBrandName(
                  onChangeTranslatedField(value, selectedTranslate, brandName),
                );
              }}
              value={
                selectedTranslateBrandValue
                  ? selectedTranslateBrandValue.data
                  : ""
              }
            />
          </div>
          <Button
            filled={true}
            disabled={!brandName}
            onPress={() => {
              console.log("Save user");
              isEdit
                ? updateBrand({ id: brandData.id, name: brandName })
                : createBrand({ name: brandName });
              onCloseModal();
            }}
          >
            Сохранить
          </Button>
          <div className='mt2'></div>
          <Button
            onPress={() => {
              onCloseModal();
            }}
          >
            Отменить
          </Button>
        </Form>
      </div>
    </Stepper>
  );
};

export default EditOrAddBrandForm;
