export const FETCH_GET_BRANDS_REQUEST = "FETCH_GET_BRANDS_REQUEST";
export const FETCH_GET_BRANDS_FAILURE = "FETCH_GET_BRANDS_FAILURE";
export const FETCH_GET_BRANDS_SUCCESS = "FETCH_GET_BRANDS_SUCCESS";

export const FETCH_REMOVE_BRANDS_REQUEST = "FETCH_REMOVE_BRANDS_REQUEST";
export const FETCH_REMOVE_BRANDS_FAILURE = "FETCH_REMOVE_BRANDS_FAILURE";
export const FETCH_REMOVE_BRANDS_SUCCESS = "FETCH_REMOVE_BRANDS_SUCCESS";

export const FETCH_CREATE_BRAND_REQUEST = "FETCH_CREATE_BRAND_REQUEST";
export const FETCH_CREATE_BRAND_FAILURE = "FETCH_CREATE_BRAND_FAILURE";
export const FETCH_CREATE_BRAND_SUCCESS = "FETCH_CREATE_BRAND_SUCCESS";

export const FETCH_UPDATE_BRAND_REQUEST = "FETCH_UPDATE_BRAND_REQUEST";
export const FETCH_UPDATE_BRAND_FAILURE = "FETCH_UPDATE_BRAND_FAILURE";
export const FETCH_UPDATE_BRAND_SUCCESS = "FETCH_UPDATE_BRAND_SUCCESS";
