import {
  FETCH_GET_USERS_INSIDE_REQUEST,
  FETCH_GET_USERS_INSIDE_FAILURE,
  FETCH_GET_USERS_INSIDE_SUCCESS,
  FETCH_REMOVE_USERS_INSIDE_REQUEST,
  FETCH_REMOVE_USERS_INSIDE_FAILURE,
  FETCH_REMOVE_USERS_INSIDE_SUCCESS,
  FETCH_CREATE_USER_INSIDE_REQUEST,
  FETCH_CREATE_USER_INSIDE_FAILURE,
  FETCH_CREATE_USER_INSIDE_SUCCESS,
  FETCH_UPDATE_USER_INSIDE_REQUEST,
  FETCH_UPDATE_USER_INSIDE_FAILURE,
  FETCH_UPDATE_USER_INSIDE_SUCCESS,
} from "./types";

const initialState = {
  usersInside: [],
  usersInsideTotalCount: 0,
  loading: false,
  error: null,
};

const usersInside = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GET_USERS_INSIDE_REQUEST:
    case FETCH_REMOVE_USERS_INSIDE_REQUEST:
    case FETCH_CREATE_USER_INSIDE_REQUEST:
    case FETCH_UPDATE_USER_INSIDE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_GET_USERS_INSIDE_SUCCESS:
      return {
        ...state,
        loading: false,
        usersInside: action.payload.entities,
        usersInsideTotalCount: action.payload.count,
      };

    case FETCH_REMOVE_USERS_INSIDE_SUCCESS:
    case FETCH_CREATE_USER_INSIDE_SUCCESS:
    case FETCH_UPDATE_USER_INSIDE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case FETCH_GET_USERS_INSIDE_FAILURE:
    case FETCH_REMOVE_USERS_INSIDE_FAILURE:
    case FETCH_CREATE_USER_INSIDE_FAILURE:
    case FETCH_UPDATE_USER_INSIDE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};

export default usersInside;
