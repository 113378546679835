import React from "react";
import "./TextareaInput.scss";

const TextareaInput = ({
  name,
  placeholder,
  onChange,
  value,
  maxLength = 999999,
  minHeight = 230,
}) => {
  return (
    <textarea
      style={{ minHeight: minHeight }}
      name={name}
      placeholder={placeholder}
      value={value}
      className='TextareaInput'
      onChange={e => {
        if (e.target.value.length <= maxLength) {
          onChange(e.target.value);
        }
      }}
    />
  );
};

export default TextareaInput;
