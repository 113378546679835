import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

import configureStore from "./redux";
import { ApiServiceProvider } from "./components/ApiServiceContext";
import ApiService from "./service";

import App from "./components/App";

const apiService = new ApiService();
const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <ApiServiceProvider value={apiService}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ApiServiceProvider>
  </Provider>,
  document.getElementById("root"),
);
