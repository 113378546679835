import { combineReducers } from "redux";
import auth from "./auth/reducer";
import usersOutside from "./usersOutside/reducer";
import usersInside from "./usersInside/reducer";
import shops from "./shops/reducer";
import brands from "./brands/reducer";
import categories from "./categories/reducer";
import products from "./products/reducer";
import sizes from "./sizes/reducer";
import articles from "./articles/reducer";
import complaints from "./complaints/reducer";
import moderationArticles from "./moderationArticles/reducer";
import moderationLooks from "./moderationLooks/reducer";
import search from "./search/reducer";
import translates from "./translates/reducer";

const rootReducer = combineReducers({
  auth,
  usersOutside,
  usersInside,
  shops,
  brands,
  categories,
  products,
  sizes,
  articles,
  complaints,
  moderationArticles,
  moderationLooks,
  search,
  translates,
});

export default rootReducer;
