import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withApiService } from "../../../components/hoc";
import compose from "../../../helpers/compose";
import { getSizes, removeSizes } from "../../../redux/sizes/actions";

import { withRouter } from "react-router-dom";

import "./SizesPage.scss";
import { ReactComponent as EditIcon } from "../../../assets/images/editIcon.svg";
import { ReactComponent as PlusIcon } from "../../../assets/images/plusIcon.svg";
import { Button, CheckBox } from "../../../components/FormItems";
import { Table } from "../../../components/Tables";
import Modal from "../../../components/Modal";
import RemoveSizesForm from "../RemoveSizesForm";
const SizesPage = ({
  history,
  sizes,
  sizesTotalCount,
  loading,
  getSizes,
  removeSizes,
}) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [filter, setFilter] = useState({ limit: 10, offset: 0 });

  const [showRemoveForm, setShowRemoveForm] = useState(false);

  useEffect(() => {
    getSizes(filter);
  }, []);
  const updateFilter = changedFields => {
    setFilter(prevState => {
      return { ...prevState, ...changedFields };
    });
    getSizes({ ...filter, ...changedFields });
  };
  const columns = [
    {
      title: "",
      dataIndex: "",
      width: "5%",
      render: rowData => {
        return (
          <CheckBox
            valuesList={selectedRows}
            onChange={selectedValues => {
              setSelectedRows(selectedValues);
            }}
            value={rowData}
          />
        );
      },
    },
    {
      title: "Название",
      dataIndex: "name",
      width: "90%",
      sorter: true,
    },
    {
      title: "",
      dataIndex: "",
      width: "5%",
      render: rowData => {
        return (
          <EditIcon
            style={{ fill: "#002534" }}
            onClick={() => {
              history.push(`/sizes/${rowData.id}`);
            }}
          />
        );
      },
    },
  ];

  return (
    <div className='page-content'>
      <Modal
        displayModal={showRemoveForm}
        onCloseModal={() => {
          setShowRemoveForm(false);
        }}
      >
        <RemoveSizesForm
          onRemove={() => {
            removeSizes(selectedRows, filter);
            setSelectedRows([]);
          }}
        />
      </Modal>

      <div className='table-filters mb2'>
        <div />
        <div className='table-filters'>
          <Button
            filled={true}
            minHeight={40}
            onPress={() => {
              history.push(`/sizes/new`);
            }}
          >
            <PlusIcon style={{ marginRight: 3, marginBottom: -2 }} /> Добавить
            сетку
          </Button>
          <div className='mr2'></div>
          <Button
            disabled={!selectedRows.length > 0}
            minHeight={40}
            minWidth={160}
            onPress={() => {
              setShowRemoveForm(true);
            }}
          >
            Удалить
          </Button>
        </div>
      </div>
      <Table
        loading={loading}
        dataSource={sizes}
        columns={columns}
        onClickRow={() => {}}
        highlightRow={rowData => {
          return selectedRows.findIndex(item => item.id === rowData.id) > -1;
        }}
        pagination={{
          total: sizesTotalCount,
          offset: filter.offset,
          limit: filter.limit,
        }}
        onChange={(pagination, sorter) => {
          let changedFields = {
            ...pagination,
            ...sorter,
          };
          updateFilter(changedFields);
        }}
      />
    </div>
  );
};

const mapStateToProps = ({ sizes }) => ({
  sizes: sizes.sizes,
  sizesTotalCount: sizes.sizesTotalCount,
  loading: sizes.loading,
});
const mapDispatchToProps = (dispatch, { apiService, filter, sizes }) => {
  return bindActionCreators(
    {
      getSizes: getSizes(apiService, filter),
      removeSizes: removeSizes(apiService, sizes, filter),
    },
    dispatch,
  );
};

export default compose(
  withApiService(),
  connect(mapStateToProps, mapDispatchToProps),
)(withRouter(SizesPage));
