import {
  FETCH_GET_ARTICLES_REQUEST,
  FETCH_GET_ARTICLES_FAILURE,
  FETCH_GET_ARTICLES_SUCCESS,
  FETCH_REMOVE_ARTICLES_REQUEST,
  FETCH_REMOVE_ARTICLES_FAILURE,
  FETCH_REMOVE_ARTICLES_SUCCESS,
  FETCH_CREATE_ARTICLE_REQUEST,
  FETCH_CREATE_ARTICLE_FAILURE,
  FETCH_CREATE_ARTICLE_SUCCESS,
  FETCH_UPDATE_ARTICLE_REQUEST,
  FETCH_UPDATE_ARTICLE_FAILURE,
  FETCH_UPDATE_ARTICLE_SUCCESS,
  FETCH_MASS_UPDATE_ARTICLES_REQUEST,
  FETCH_MASS_UPDATE_ARTICLES_FAILURE,
  FETCH_MASS_UPDATE_ARTICLES_SUCCESS,
} from "./types";

const initialState = {
  articles: [],
  articlesTotalCount: 0,
  loading: false,
  error: null,
};

const articles = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GET_ARTICLES_REQUEST:
    case FETCH_MASS_UPDATE_ARTICLES_REQUEST:
    case FETCH_REMOVE_ARTICLES_REQUEST:
    case FETCH_CREATE_ARTICLE_REQUEST:
    case FETCH_UPDATE_ARTICLE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_GET_ARTICLES_SUCCESS:
      return {
        ...state,
        loading: false,
        articles: action.payload.entities,
        articlesTotalCount: action.payload.count,
      };

    case FETCH_REMOVE_ARTICLES_SUCCESS:
    case FETCH_MASS_UPDATE_ARTICLES_SUCCESS:
    case FETCH_CREATE_ARTICLE_SUCCESS:
    case FETCH_UPDATE_ARTICLE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case FETCH_GET_ARTICLES_FAILURE:
    case FETCH_MASS_UPDATE_ARTICLES_FAILURE:
    case FETCH_REMOVE_ARTICLES_FAILURE:
    case FETCH_CREATE_ARTICLE_FAILURE:
    case FETCH_UPDATE_ARTICLE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};

export default articles;
