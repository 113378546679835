import React, { useState, useEffect } from "react";
import "./Search.scss";
import { ReactComponent as SearchIcon } from "../../../assets/images/searchIcon.svg";

const Search = ({
  label,
  placeholder,
  onSelect,
  selectedValue = {},
  searchResults = [],
  searchRequest,
  requestStartSumbolIndex = 3,
}) => {
  const [searchValue, setSearchValue] = useState(
    selectedValue.name ? selectedValue.name : "",
  );

  useEffect(() => {
    if (selectedValue.name === "null") {
      onSelect({});
      setSearchValue("");
    }
  }, [selectedValue]);
  return (
    <div className='Search'>
      <div className='inputBlock'>
        <input
          value={searchValue}
          placeholder={placeholder}
          onChange={e => {
            if (e.target.value.length >= requestStartSumbolIndex) {
              searchRequest(e.target.value);
            }
            setSearchValue(e.target.value);
          }}
        />
        <SearchIcon width='20px' height='20px' />
      </div>
      <ul
        style={
          searchResults.length > 0 &&
          searchValue.length >= requestStartSumbolIndex
            ? {}
            : { display: "none" }
        }
      >
        {searchResults.map(item => {
          return (
            <li
              key={"searchUserList" + item.id}
              onClick={() => {
                onSelect(item);
                setSearchValue(item.toString());
                // Костыль что бы очистить список
                searchRequest("awdawdawdawldawdawdawdawdawdawdawd");
              }}
            >
              {item.toString()}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Search;
