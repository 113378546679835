import React, { useRef, useState } from "react";
import "./SelectInput.scss";
import { ReactComponent as ArrowIcon } from "../../../assets/images/arrowDown.svg";
import { ReactComponent as CloseIcon } from "../../../assets/images/closeIcon.svg";

const SelectInput = ({
  placeholder,
  onSelect,
  selectedValue = {},
  options = [],
  width = "auto",
  icon = <ArrowIcon className='SelectInput__arrow' />,
}) => {
  const Select = useRef();
  const [focus, setFocus] = useState(false);

  return (
    <div
      style={{ width: width }}
      tabIndex='-1'
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();

        if (focus) {
          Select.current.blur();
        } else {
          setFocus(true);
        }
      }}
      onBlur={e => {
        e.preventDefault();
        e.stopPropagation();

        setFocus(false);
      }}
      ref={Select}
      className='SelectInput'
    >
      <div className='inputBlock'>
        <input
          disabled
          value={selectedValue.name || ""}
          placeholder={placeholder}
          readOnly
          onChange={e => {}}
        />
        {selectedValue.name ? (
          <CloseIcon
            className='clearBtn'
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();

              onSelect({});
              Select.current.blur();
            }}
          />
        ) : (
          icon
        )}
      </div>
      <ul>
        {options.map(item => {
          return (
            <li
              key={"selectItem" + item.name + item.id}
              onClick={() => {
                onSelect(item);
                Select.current.blur();
              }}
            >
              {item.name}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SelectInput;
