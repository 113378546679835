import React from "react";

import "./LoadingScreen.scss";

const LoadingScreen = () => {
  return (
    <div className='LoadingScreen'>
      <div className='ldsSpinner'>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default LoadingScreen;
