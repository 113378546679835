import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withApiService } from "../../../components/hoc";
import compose from "../../../helpers/compose";
import {
  getArticles,
  createArticle,
  updateArticle,
  removeArticles,
  massUpdateArticles,
} from "../../../redux/articles/actions";

import { getProducts } from "../../../redux/products/actions";

import { getLanguages } from "../../../redux/translates/actions";

import "./ArticlesPage.scss";
import {
  Button,
  CheckBox,
  Search,
  Select,
} from "../../../components/FormItems";
import { Table } from "../../../components/Tables";
import getFormattedDate from "../../../helpers/getFormattedDate";
import { ReactComponent as PlusIcon } from "../../../assets/images/plusIcon.svg";

import Modal from "../../../components/Modal";
import EditOrAddArticleForm from "../EditOrAddArticleForm";
import RemoveArticlesForm from "../RemoveArticlesForm";

const ArticlesPage = ({
  articles,
  articlesTotalCount,
  loading,
  languages = [],
  getArticles,
  createArticle,
  updateArticle,
  removeArticles,
  massUpdateArticles,
  getLanguages,
  getProducts,
  searchProducts,
}) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [filter, setFilter] = useState({ limit: 10, offset: 0 });

  const [selectedStatus, setSelectedStatus] = useState({});

  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedRowForEdit, setSelectedRowForEdit] = useState({});
  const [showRemoveForm, setShowRemoveForm] = useState(false);

  const updateFilter = changedFields => {
    setFilter(prevState => {
      return { ...prevState, ...changedFields };
    });
    getArticles({ ...filter, ...changedFields });
  };

  useEffect(() => {
    getArticles(filter);
    getLanguages();
  }, []);
  const columns = [
    {
      title: "",
      dataIndex: "",
      width: "5%",
      render: rowData => {
        return (
          <CheckBox
            valuesList={selectedRows}
            onChange={selectedValues => {
              setSelectedRows(selectedValues);
            }}
            value={rowData}
          />
        );
      },
    },
    {
      title: "Статья",
      dataIndex: "name",
      width: "22%",
      sorter: true,
      render: rowData => {
        return (
          <span
            className='underline-text'
            onClick={() => {
              setSelectedRowForEdit(rowData);
              setShowEditModal(true);
            }}
          >
            {rowData.name}
          </span>
        );
      },
    },
    {
      title: "Дата создания",
      dataIndex: "createdAt",
      width: "20%",
      sorter: true,
      render: rowData => {
        return getFormattedDate(rowData.createdAt / 1000);
      },
    },
    {
      title: "Дата присвоения статуса",
      dataIndex: "updatedAt",
      width: "20%",
      sorter: true,
      render: rowData => {
        return getFormattedDate(rowData.updatedAt / 1000);
      },
    },
    {
      title: "Статус",
      dataIndex: "status",
      width: "13%",
      sorter: true,
      render: rowData => {
        if (rowData.verificationStatus === 0) {
          return <span className='red-text'>Модерация</span>;
        } else if (rowData.verificationStatus === 1) {
          return <span className='red-text'>На редактировании</span>;
        } else {
          return <span className='green-text'>Одобрена</span>;
        }
      },
    },
  ];

  return (
    <div className='page-content'>
      <Modal
        maxWidth={900}
        onCloseModal={() => {
          setShowEditModal(false);
          setSelectedRowForEdit({});
        }}
        displayModal={showEditModal}
      >
        <EditOrAddArticleForm
          articleData={selectedRowForEdit}
          updateArticle={articleData => {
            updateArticle(articleData, filter);
          }}
          createArticle={articleData => {
            createArticle(articleData, filter);
          }}
          getProducts={getProducts}
          searchProducts={searchProducts}
        />
      </Modal>
      <Modal
        displayModal={showRemoveForm}
        onCloseModal={() => {
          setShowRemoveForm(false);
        }}
      >
        <RemoveArticlesForm
          onRemove={() => {
            removeArticles(selectedRows, filter);
            setSelectedRows([]);
          }}
        />
      </Modal>
      <div className='table-filters mb2'>
        <div className='table-filters'>
          <Search
            placeholder='Поиск по автору'
            onSelect={value => {}}
            searchResults={[]}
            searchRequest={val => {
              updateFilter({ username: val, offset: 0 });
            }}
            selectedValue={{}}
            requestStartSumbolIndex={0}
          />

          <div className='mr2'></div>
        </div>
        <div className='table-filters'>
          <Select
            placeholder='Статус'
            width={"200px"}
            onSelect={value => {
              setSelectedStatus(value);
            }}
            selectedValue={selectedStatus}
            options={[
              { id: 0, name: "Модерация" },
              { id: 1, name: "На редактировании" },
              { id: 2, name: "Одобрена" },
            ]}
          />
          <div className='mr2'></div>
          <Button
            filled={true}
            minHeight={40}
            minWidth={160}
            disabled={
              selectedRows.length <= 0 || selectedStatus.id === undefined
            }
            onPress={() => {
              massUpdateArticles(selectedRows, selectedStatus.id, filter);
              setSelectedRows([]);
            }}
          >
            Изменить статус
          </Button>
          <div className='mr2'></div>
          <Button
            filled={true}
            minHeight={40}
            onPress={() => {
              setShowEditModal(true);
              setSelectedRowForEdit({});
            }}
          >
            <PlusIcon style={{ marginRight: 3, marginBottom: -2 }} /> Добавить
            статью
          </Button>
          <div className='mr2'></div>
          <Button
            disabled={!selectedRows.length > 0}
            minHeight={40}
            minWidth={160}
            onPress={() => {
              setShowRemoveForm(true);
            }}
          >
            Удалить
          </Button>
        </div>
      </div>
      <Table
        loading={loading}
        dataSource={articles}
        columns={columns}
        onClickRow={() => {}}
        highlightRow={rowData => {
          return selectedRows.findIndex(item => item.id === rowData.id) > -1;
        }}
        pagination={{
          total: articlesTotalCount,
          offset: filter.offset,
          limit: filter.limit,
        }}
        onChange={(pagination, sorter) => {
          let changedFields = {
            ...pagination,
            ...sorter,
          };
          updateFilter(changedFields);
        }}
      />
    </div>
  );
};

const mapStateToProps = ({ articles, translates, products }) => ({
  articles: articles.articles,
  articlesTotalCount: articles.articlesTotalCount,
  loading: articles.loading,
  languages: translates.languages,
  searchProducts: products.products,
});
const mapDispatchToProps = (
  dispatch,
  { apiService, filter, articles, status, articleData, searchProductName },
) => {
  return bindActionCreators(
    {
      getProducts: getProducts(apiService, searchProductName),
      getArticles: getArticles(apiService, filter),
      createArticle: createArticle(apiService, articleData, filter),
      updateArticle: updateArticle(apiService, articleData, filter),
      removeArticles: removeArticles(apiService, articles, filter),
      massUpdateArticles: massUpdateArticles(
        apiService,
        articles,
        status,
        filter,
      ),
      getLanguages: getLanguages(apiService),
    },
    dispatch,
  );
};

export default compose(
  withApiService(),
  connect(mapStateToProps, mapDispatchToProps),
)(ArticlesPage);
