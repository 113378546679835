import {
  FETCH_GET_SHOPS_REQUEST,
  FETCH_GET_SHOPS_FAILURE,
  FETCH_GET_SHOPS_SUCCESS,
  FETCH_REMOVE_SHOPS_REQUEST,
  FETCH_REMOVE_SHOPS_FAILURE,
  FETCH_REMOVE_SHOPS_SUCCESS,
  FETCH_CREATE_SHOP_REQUEST,
  FETCH_CREATE_SHOP_FAILURE,
  FETCH_CREATE_SHOP_SUCCESS,
  FETCH_UPDATE_SHOP_REQUEST,
  FETCH_UPDATE_SHOP_FAILURE,
  FETCH_UPDATE_SHOP_SUCCESS,
} from "./types";

const initialState = {
  shops: [],
  shopsTotalCount: 0,
  loading: false,
  error: null,
};

const shops = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GET_SHOPS_REQUEST:
    case FETCH_REMOVE_SHOPS_REQUEST:
    case FETCH_CREATE_SHOP_REQUEST:
    case FETCH_UPDATE_SHOP_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_GET_SHOPS_SUCCESS:
      return {
        ...state,
        loading: false,
        shops: action.payload.entities,
        shopsTotalCount: action.payload.count,
      };

    case FETCH_REMOVE_SHOPS_SUCCESS:
    case FETCH_CREATE_SHOP_SUCCESS:
    case FETCH_UPDATE_SHOP_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case FETCH_GET_SHOPS_FAILURE:
    case FETCH_REMOVE_SHOPS_FAILURE:
    case FETCH_CREATE_SHOP_FAILURE:
    case FETCH_UPDATE_SHOP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};

export default shops;
