import React from "react";
import "./TableRow.scss";

const TableRow = ({
  rowIndex,
  rowItem,
  onClickRow,
  columns,
  highlightRow = () => {},
}) => {
  return (
    <tr
      onClick={() => onClickRow(rowItem)}
      style={highlightRow(rowItem) ? { backgroundColor: "#f7f7f7" } : {}}
    >
      {columns.map((headerRowItem, headerRowIndex) => {
        return (
          <td
            key={
              "gridTableTdI" +
              rowIndex +
              "Id" +
              rowItem.id +
              "s" +
              headerRowIndex +
              headerRowItem.title
            }
            style={headerRowItem.width ? { width: headerRowItem.width } : {}}
          >
            {headerRowItem.render
              ? headerRowItem.render(rowItem, rowIndex)
              : rowItem[headerRowItem.dataIndex]}
          </td>
        );
      })}
    </tr>
  );
};

export default TableRow;
