export const FETCH_GET_SIZES_REQUEST = "FETCH_GET_SIZES_REQUEST";
export const FETCH_GET_SIZES_FAILURE = "FETCH_GET_SIZES_FAILURE";
export const FETCH_GET_SIZES_SUCCESS = "FETCH_GET_SIZES_SUCCESS";

export const FETCH_REMOVE_SIZES_REQUEST = "FETCH_REMOVE_SIZES_REQUEST";
export const FETCH_REMOVE_SIZES_FAILURE = "FETCH_REMOVE_SIZES_FAILURE";
export const FETCH_REMOVE_SIZES_SUCCESS = "FETCH_REMOVE_SIZES_SUCCESS";

export const FETCH_CREATE_SIZE_REQUEST = "FETCH_CREATE_SIZE_REQUEST";
export const FETCH_CREATE_SIZE_FAILURE = "FETCH_CREATE_SIZE_FAILURE";
export const FETCH_CREATE_SIZE_SUCCESS = "FETCH_CREATE_SIZE_SUCCESS";

export const FETCH_UPDATE_SIZE_REQUEST = "FETCH_UPDATE_SIZE_REQUEST";
export const FETCH_UPDATE_SIZE_FAILURE = "FETCH_UPDATE_SIZE_FAILURE";
export const FETCH_UPDATE_SIZE_SUCCESS = "FETCH_UPDATE_SIZE_SUCCESS";
