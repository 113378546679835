import React, { PureComponent } from "react";
import SidebarMenuItemTitle from "../SidebarMenuItemTitle";

import "./SidebarSubMenu.scss";

export default class SidebarSubMenu extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpened:
        this.props.openSidebarIds.indexOf(this.props.itemId) > -1
          ? true
          : false,
    };
  }

  handleToogle = (itemId, onSelect) => {
    this.setState(state => {
      return {
        ...state,
        isOpened: !state.isOpened,
      };
    });
    onSelect(itemId);
  };

  render() {
    const { isOpened } = this.state;
    const { label, itemId, children, onSelect, openSidebarIds } = this.props;

    // Смотрим кто контролирует открытие и закрытие,
    // и если у нас нет функции на контроль то значит контролим блок внутренним стейтом
    let toogleController =
      onSelect + "" === "() => {}"
        ? isOpened
        : openSidebarIds.indexOf(this.props.itemId) > -1;
    const sumMenuContentClass = toogleController
      ? "Sidebarmenu__submenu Sidebarmenu__submenu--active sidebarmenu-submenu"
      : "Sidebarmenu__submenu sidebarmenu-submenu";

    return (
      <li className={sumMenuContentClass}>
        <SidebarMenuItemTitle
          onClick={() => this.handleToogle(itemId, onSelect)}
          label={label}
          hasArrow={true}
          itemId={itemId}
        />
        <ul className='sidebarmenu-submenu__content'>{children}</ul>
      </li>
    );
  }
}
