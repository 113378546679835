import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withApiService } from "../../components/hoc";
import compose from "../../helpers/compose";
import { getSize } from "../../redux/sizes/actions";

import { withRouter } from "react-router-dom";

import "./SizesDetailPageWatchOnly.scss";
import { TextInput, Button } from "../../components/FormItems";
import { EditableTable } from "../../components/Tables";

const SizesDetailPageWatchOnly = ({
  sizeData,
  loading,
  getSize,
  match,
  history,
}) => {
  const [sizeFormData, setSizeFormData] = useState({});

  useEffect(() => {
    if (match.params.id !== "new") {
      getSize(match.params.id);
    }
  }, []);

  const formatServerSizesDataToTable = (rows = []) => {
    let formatedData = [];
    let tmpRow = [];

    rows.forEach(rowData => {
      tmpRow = [rowData.name];
      rowData.values.forEach(rowCell => {
        tmpRow.push(rowCell);
      });
      formatedData.push(tmpRow);
      tmpRow = [];
    });

    return formatedData;
  };

  const [sizeTableData, setSizeTableData] = useState(
    formatServerSizesDataToTable(sizeData.rows),
  );

  useEffect(() => {
    if (sizeData) {
      setSizeTableData(
        formatServerSizesDataToTable(
          sizeData.rows && sizeData.rows.length > 0
            ? sizeData.rows
            : [{ name: "", values: [] }],
        ),
      );
      // Если это не шаблон меняем данные
      if (!sizeData.gender && !sizeData.item_type) {
        setSizeFormData(sizeData);
      }
    }
  }, [sizeData]);

  const updateSizeFormData = changedFields => {
    setSizeFormData(prevData => ({ ...prevData, ...changedFields }));
  };

  return (
    <div className='SizesDetailPage'>
      <div className='formItems mb2'>
        <TextInput
          type='text'
          name='name'
          placeholder='Название'
          onChange={value => {
            updateSizeFormData({ name: value });
          }}
          value={sizeFormData["name"]}
        />
      </div>
      <EditableTable
        tableWidth={"max-content"}
        tableDataset={sizeTableData}
        onChange={newTableDataset => {
          setSizeTableData(newTableDataset);
        }}
        showOnAddRow={false}
        showOnAddCol={false}
        showOnRemoveRow={false}
        showOnRemoveCol={false}
        showOnRevChanges={false}
      />
      <div className='mt4'></div>
      <div className='controlButtons'>
        <Button
          onPress={() => {
            history.push("/sizes");
          }}
        >
          Назад
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = ({ sizes }) => ({
  sizeData: sizes.sizes[0] || {},
  loading: sizes.loading,
});
const mapDispatchToProps = (dispatch, { apiService, sizeId }) => {
  return bindActionCreators(
    {
      getSize: getSize(apiService, sizeId),
    },
    dispatch,
  );
};

export default compose(
  withApiService(),
  connect(mapStateToProps, mapDispatchToProps),
)(withRouter(SizesDetailPageWatchOnly));
