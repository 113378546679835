import {
  FETCH_GET_USERS_OUTSIDE_REQUEST,
  FETCH_GET_USERS_OUTSIDE_FAILURE,
  FETCH_GET_USERS_OUTSIDE_SUCCESS,
  FETCH_UPDATE_USERS_OUTSIDE_STATUS_REQUEST,
  FETCH_UPDATE_USERS_OUTSIDE_STATUS_FAILURE,
  FETCH_UPDATE_USERS_OUTSIDE_STATUS_SUCCESS,
} from "./types";

export const getUsersOutsideRequest = () => {
  return { type: FETCH_GET_USERS_OUTSIDE_REQUEST };
};
export const getUsersOutsideFailure = error => {
  return { type: FETCH_GET_USERS_OUTSIDE_FAILURE, payload: error };
};
export const getUsersOutsideSuccess = usersOutsideData => {
  return { type: FETCH_GET_USERS_OUTSIDE_SUCCESS, payload: usersOutsideData };
};

export const updateUsersOutsideStatusRequest = () => {
  return { type: FETCH_UPDATE_USERS_OUTSIDE_STATUS_REQUEST };
};
export const updateUsersOutsideStatusFailure = error => {
  return { type: FETCH_UPDATE_USERS_OUTSIDE_STATUS_FAILURE, payload: error };
};
export const updateUsersOutsideStatusSuccess = () => {
  return { type: FETCH_UPDATE_USERS_OUTSIDE_STATUS_SUCCESS };
};
