import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withApiService } from "../../../components/hoc";
import compose from "../../../helpers/compose";
import {
  getSearchCategories,
  getSearchSizes,
  getSearchColors,
  getSearchMaterials,
  getSearchBrands,
  getSearchShops,
} from "../../../redux/search/actions";
import showMessage from "../../../components/showMessage";
import {
  mapProductTypeIdToName,
  mapGenderIdToName,
} from "../../../helpers/mapper";
import "./EditOrAddProductForm.scss";

import {
  Form,
  TextInput,
  SearchInput,
  SelectInput,
  NumInput,
  ImageInput,
  CheckBox,
  Button,
} from "../../../components/FormItems";

import Stepper from "../../../components/Stepper";

const getAvailableTranslates = (translates, languages) => {
  let tmpAvailableLanguages = [];
  let formatTranslates = translates ? translates : [];

  languages.map(language => {
    let findTranslate = formatTranslates.find(
      translate => translate.languageCode === language.code,
    );
    if (!findTranslate) {
      tmpAvailableLanguages.push({ ...language, languageCode: language.code });
    }
  });
  return tmpAvailableLanguages;
};

const changeSelectedTranslate = (
  translates,
  currentTranslate,
  nextTranslateIndex,
) => {
  // Находим индекс текущего перевода в списке пеереводов
  let formatTranslates = translates ? translates : [];

  let currentTranslateIndex = formatTranslates.findIndex(
    translate => translate.languageCode === currentTranslate.languageCode,
  );

  if (formatTranslates[currentTranslateIndex + nextTranslateIndex]) {
    return { ...formatTranslates[currentTranslateIndex + nextTranslateIndex] };
  } else {
    return nextTranslateIndex > 0
      ? formatTranslates[0]
      : formatTranslates[formatTranslates.length - 1];
  }
};

const addNewTranslateToFormTranslates = (addededTranslate, formTranslates) => {
  return [
    ...formTranslates,
    { data: "", languageCode: addededTranslate.languageCode },
  ];
};

const onChangeTranslatedField = (value, translate, formTranslates) => {
  // Находим перевод который нужно изменить
  let changedTranslateIndex = formTranslates.findIndex(
    formTranslate => formTranslate.languageCode === translate.languageCode,
  );

  if (formTranslates[changedTranslateIndex]) {
    formTranslates[changedTranslateIndex].data = value;
    return [...formTranslates];
  } else {
    return [
      ...formTranslates,
      { languageCode: translate.languageCode, data: value },
    ];
  }
};

const EditOrAddProductForm = ({
  onCloseModal,
  productData = {},
  categories,
  sizeTables,
  userRoleId,
  colors,
  materials,
  brands,
  shops,
  languages = [],
  getSearchCategories,
  getSearchSizes,
  getSearchColors,
  getSearchMaterials,
  getSearchBrands,
  getSearchShops,
  updateProduct,
  createProduct,
}) => {
  const [formValues, setFormValues] = useState({
    name: [{ languageCode: "en", data: "" }],
    material: {},
    color: {},
    price: [{ languageCode: "en", data: "" }],
    shop: {},
    category: {},
    description: [{ languageCode: "en", data: "" }],
    brand: {},
    productSizes: [],
    productType: {},
    gender: {},
    sizeGrid: {},
    productUri: "",
    galleryImages: [],
    productLook: {},
    productPreview: {},
  });
  const [productAddededImages, setProductAddededImages] = useState([]);
  const [productRemovedImages, setProductRemovedImages] = useState([]);
  const [flag, setFlag] =  useState(false)
  const isEdit = productData.id ? true : false;

  useEffect(() => {
    setFlag(true)
    setFormValues({
      name:
        productData.name && productData.name[0]
          ? productData.name
          : [{ languageCode: "en", data: "" }],

      material: productData.material || { name: "null" },
      color: productData.color || { name: "null" },
      price:
        productData.price && productData.price[0]
          ? productData.price
          : [{ languageCode: "en", data: "" }],
      shop: productData.shop || { name: "null" },
      category: productData.category || { name: "" },
      description:
        productData.description && productData.description[0]
          ? productData.description
          : [{ languageCode: "en", data: "" }],
      brand: productData.brand || { name: "null" },
      productSizes:
        productData.availableSizes && productData.availableSizes.length > 0
          ? productData.availableSizes.map(size => ({ id: size.size, url: size.url, languageCode: size.languageCode }))
          : [],
      productType: {
        id: productData.itemType,
        name: mapProductTypeIdToName[productData.itemType],
      },
      gender: {
        id: productData.gender,
        name: mapGenderIdToName[productData.gender],
      },
      sizeGrid: productData.sizeGrid || { name: "" },
      productUri: productData.shopSiteUrl || "",
      galleryImages: productData.galleryImages ? productData.galleryImages : [],
      productLook: productData.tryOnTemplate || {},
      productPreview: productData.photo || {},
    });
    setProductRemovedImages([]);
    setProductAddededImages([]);
  }, [productData]);

  const updateProductForm = changedFormValues => {
    setFlag(false);
    setFormValues(prevValues => ({
      ...prevValues,
      ...changedFormValues,
    }));
  };
  const isValidURL = string => {
    let res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    return (res !== null)
  };

  const updateProductSizeForm = ({size, url, languageCode}) => {
    let productSizeIndex = formValues.productSizes.findIndex(item => item.id === size)
      updateProductForm({
        productSizes:
          formValues.productSizes
              .slice(0, productSizeIndex)
              .concat(formValues.productSizes.slice(productSizeIndex + 1))
              .concat({id: size, url: url, languageCode: languageCode})
        ,
      });
  };

  const updateProductImagesArray = image => {
    let imageIndex = formValues.galleryImages.findIndex(
      item => item === image || (item.image && item.image === image),
    );
    if (imageIndex > -1) {
      updateProductForm({
        galleryImages: [
          ...formValues.galleryImages.slice(0, imageIndex),
          ...formValues.galleryImages.slice(imageIndex + 1),
        ],
      });

      let indexInAdded = productAddededImages.findIndex(() => item =>
        item === image || (item.image && item.image === image),
      );

      // Если была удалена картинка присланная с сервера
      if (formValues.galleryImages[imageIndex].id) {
        // Добавляем ее в список удаленных
        setProductRemovedImages([
          formValues.galleryImages[imageIndex],
          ...productRemovedImages,
        ]);
      }

      // Убираем ее из списка добавленных
      if (indexInAdded > -1) {
        setProductAddededImages([
          ...productAddededImages.slice(0, imageIndex),
          ...productAddededImages.slice(imageIndex + 1),
        ]);
      }
    } else {
      updateProductForm({
        galleryImages: [image, ...formValues.galleryImages],
      });

      setProductAddededImages([image, ...productAddededImages]);
    }
  };
  const [selectedTranslate, setSelectedTranslate] = useState({
    languageCode: "en",
  });
  const [selectedTranslateToAdd, setSelectedTranslateToAdd] = useState({});
  // Подготавливаем список языков которые можно добавить
  let availableToAddTranslates = getAvailableTranslates(
    formValues["name"],
    languages,
  );

  let selectedTranslateName = languages.find(
    language => language.code === selectedTranslate.languageCode,
  );

  // Подготовка значения с нужным переводом
  let selectedTranslateProductName = formValues["name"].find(
    name => name.languageCode === selectedTranslate.languageCode,
  );
  let selectedTranslateProductDescription = formValues["description"].find(
    description => description.languageCode === selectedTranslate.languageCode,
  );

  let selectedTranslateProductPrice = formValues["price"].find(
    price => price.languageCode === selectedTranslate.languageCode,
  );

  return (
    <Stepper
      onNextStep={() => {
        setSelectedTranslate(
          changeSelectedTranslate(formValues["name"], selectedTranslate, 1),
        );
      }}
      onPrevStep={() => {
        setSelectedTranslate(
          changeSelectedTranslate(formValues["name"], selectedTranslate, -1),
        );
      }}
    >
      <div className='EditOrAddProductForm'>
        <Form title=''>
          <div className='translate-flex-row'>
            <div className='translate-flex-col'>
              <div className='translate-title'>
                Добавление / редактирование товара
              </div>
              <div className='translate-count'>
                Переводов: {formValues["name"].length}
              </div>
              <div className='translate-language'>
                Выбран:{" "}
                {selectedTranslateName ? selectedTranslateName.name : ""}
              </div>
            </div>
            <div className='translate-flex-row'>
              <SelectInput
                placeholder='Перевод'
                onSelect={value => setSelectedTranslateToAdd(value)}
                selectedValue={selectedTranslateToAdd}
                options={availableToAddTranslates}
              />
              <div className='mr2'></div>
              <Button
                disabled={!selectedTranslateToAdd.name}
                filled={true}
                onPress={() => {
                  // Добавляем перевод в список переводов
                  updateProductForm({
                    name: addNewTranslateToFormTranslates(
                      selectedTranslateToAdd,
                      formValues["name"],
                    ),
                  });
                  updateProductForm({
                    description: addNewTranslateToFormTranslates(
                      selectedTranslateToAdd,
                      formValues["description"],
                    ),
                  });
                  // Сбрасываем выбранный для добавления язык
                  setSelectedTranslateToAdd({});

                  // Переключаемся на последний перевод
                }}
              >
                Добавить перевод
              </Button>
            </div>
          </div>
          <div className='gridCol3 mt3 mb3'>
            <div className='inputsBlock'>
              <TextInput
                type='text'
                name='name'
                placeholder='Название'
                onChange={value => {
                  updateProductForm({
                    name: onChangeTranslatedField(
                      value,
                      selectedTranslate,
                      formValues["name"],
                    ),
                  });
                }}
                value={
                  selectedTranslateProductName
                    ? selectedTranslateProductName.data
                    : ""
                }
              />
              <div className='mb2'></div>
              <SearchInput
                languageCode={selectedTranslate.languageCode}
                placeholder='Магазин'
                onSelect={value => {
                  updateProductForm({ shop: value });
                }}
                searchResults={shops}
                searchRequest={val => {
                  getSearchShops(val, userRoleId);
                }}
                selectedValue={formValues["shop"]}
              />
              <div className='mb2'></div>
              <SelectInput
                placeholder='Тип товара'
                onSelect={value => {
                  updateProductForm({ productType: value });
                }}
                selectedValue={formValues["productType"]}
                options={[
                  { id: 1, name: "Аксессуары" },
                  { id: 2, name: "Одежда" },
                  { id: 3, name: "Обувь" },
                  { id: 4, name: "Головные уборы" },
                ]}
              />
              <div className='mb2'></div>
              <SearchInput
                  languageCode={selectedTranslate.languageCode}
                  placeholder='Материал'
                  onSelect={value => {
                    updateProductForm({ material: value });
                  }}
                  searchResults={materials}
                  searchRequest={val => {
                    getSearchMaterials(val);
                  }}
                  selectedValue={formValues["material"]}
              />
            </div>
            <div className='inputsBlock'>
              <TextInput
                type='text'
                name='description'
                placeholder='Описание'
                onChange={value => {
                  updateProductForm({
                    description: onChangeTranslatedField(
                      value,
                      selectedTranslate,
                      formValues["description"],
                    ),
                  });
                }}
                value={
                  selectedTranslateProductDescription
                    ? selectedTranslateProductDescription.data
                    : ""
                }
              />
              <div className='mb2'></div>
              <SearchInput
                languageCode={selectedTranslate.languageCode}
                placeholder='Бренд'
                onSelect={value => {
                  updateProductForm({ brand: value });
                }}
                searchResults={brands}
                searchRequest={val => {
                  getSearchBrands(val);
                }}
                selectedValue={formValues["brand"]}
              />
              <div className='mb2'></div>
              <SelectInput
                placeholder='Гендер'
                onSelect={value => {
                  updateProductForm({ gender: value });
                }}
                selectedValue={formValues["gender"]}
                options={[
                  { id: 1, name: "Женский" },
                  { id: 2, name: "Мужской" },
                  { id: 3, name: "Детский" },
                ]}
              />
              <div className='mb2'></div>
              <TextInput
                  type='text'
                  name='productUri'
                  placeholder='URL товара'
                  onChange={value => {
                    updateProductForm({ productUri: value });
                  }}
                  value={formValues["productUri"]}
              />
            </div>
            <div className='inputsBlock'>
              <NumInput
                  type='text'
                  name='price'
                  placeholder='Цена'
                  onChange={value => {
                    updateProductForm({
                      price: onChangeTranslatedField(
                          value,
                          selectedTranslate,
                          formValues["price"],
                      ),
                    });
                  }}
                  value={
                    selectedTranslateProductPrice
                        ? selectedTranslateProductPrice.data
                        : ""
                  }
              />
              <div className='mb2'></div>
              <SearchInput
                  languageCode={selectedTranslate.languageCode}
                  placeholder='Цвет'
                  onSelect={value => {
                    updateProductForm({ color: value });
                  }}
                  searchResults={colors}
                  searchRequest={val => {
                    getSearchColors(val);
                  }}
                  selectedValue={formValues["color"]}
              />
              <div className='mb2'></div>
              {formValues.gender &&
              formValues.gender.id !== undefined &&
              formValues.productType &&
              formValues.productType.id !== undefined ? (
                  <SearchInput
                      languageCode={selectedTranslate.languageCode}
                      placeholder='Категория'
                      onSelect={value => {
                        updateProductForm({ category: value });
                      }}
                      searchResults={categories}
                      searchRequest={val => {
                        getSearchCategories(val);
                      }}
                      selectedValue={formValues["category"]}
                  />
              ) : (
                  ""
              )}
              <div className='mb2'></div>
              {formValues.gender &&
              formValues.gender.id !== undefined &&
              formValues.productType &&
              formValues.productType.id !== undefined ? (
                  <SearchInput
                      languageCode={selectedTranslate.languageCode}
                      placeholder='Размерная сетка'
                      onSelect={value => {
                        updateProductForm({ sizeGrid: value, productSizes: [] });
                      }}
                      searchResults={sizeTables}
                      searchRequest={val => {
                        getSearchSizes(val);
                      }}
                      selectedValue={formValues["sizeGrid"]}
                  />
              ) : (
                  ""
              )}
            </div>
          </div>
          <div className='translate-subtitle'>
            Добавление ссылки в магазине на размер
          </div>
          <div className='gridCol2 mt3 mb3'>
          {formValues.sizeGrid && formValues.sizeGrid.rows
              ? formValues.sizeGrid.rows[0].values.map((cellData, index) => {
                return (
                  <div className='sizeInputsBlock'>
                    <div className='sizeInputsBlock'>
                      <CheckBox
                          valuesList={formValues["productSizes"]}
                          onChange={selectedValues => {
                            updateProductForm({
                              productSizes: selectedValues,
                            });
                          }}
                          value={{
                            id: cellData,
                            url: null,
                            languageCode: formValues.productSizes[formValues.productSizes.findIndex(item => item.id === cellData)] ?
                                formValues.productSizes[formValues.productSizes.findIndex(item => item.id === cellData)].languageCode
                                : formValues.productSizes[0] ? formValues.productSizes[0].languageCode : null
                          }}
                      />{" "}
                      <div className='ml4'></div>
                      <span style={{ textTransform: "uppercase" }}>
                              {cellData}
                      </span>
                    </div>
                    <div className='ml4'></div>
                    <TextInput
                        type='text'
                        name='URLSize'
                        disabled={formValues.productSizes[formValues.productSizes.findIndex(item => item.id === cellData)] === undefined}
                        placeholder='Введите URL размера на сайте магазина'
                        onChange={value => {
                          updateProductSizeForm({
                            size: formValues.productSizes[formValues.productSizes.findIndex(item => item.id === cellData)] ?
                                formValues.productSizes[formValues.productSizes.findIndex(item => item.id === cellData)].id
                                : null,
                            url: value,
                            languageCode:
                                formValues.productSizes[formValues.productSizes.findIndex(item => item.id === cellData)] ?
                                    formValues.productSizes[formValues.productSizes.findIndex(item => item.id === cellData)].languageCode
                                    : null
                          });
                        }}
                        onBlur={value => {
                          if (value.length !== 0 && !isValidURL(value)){
                            updateProductSizeForm({
                              size: formValues.productSizes[formValues.productSizes.findIndex(item => item.id === cellData)] ?
                                  formValues.productSizes[formValues.productSizes.findIndex(item => item.id === cellData)].id
                                  : null,
                              url: null,
                              languageCode:
                                  formValues.productSizes[formValues.productSizes.findIndex(item => item.id === cellData)] ?
                                      formValues.productSizes[formValues.productSizes.findIndex(item => item.id === cellData)].languageCode
                                      : null
                            });
                            showMessage({status: 'error', text: 'Введите корректный урл!'})
                            }
                          }
                        }
                        value={
                          formValues.productSizes[formValues.productSizes.findIndex(
                              item => item.id === cellData)] ?
                              formValues.productSizes[formValues.productSizes.findIndex(item => item.id === cellData)].url
                              : null
                        }
                    />
                  </div>
                );
              })
              : ""}
          </div>

          <div className='gridCol2 mb4'>
            <div className='gridCol2 galleryBlock'>
              <ImageInput
                addTitle='Добавить превью'
                value={
                  formValues.productPreview && formValues.productPreview.image
                }
                onChange={(imageData = {}) => {
                  if (imageData === formValues.productPreview.image) {
                    setProductRemovedImages([
                      formValues.productPreview,
                      ...productRemovedImages,
                    ]);

                    updateProductForm({
                      productPreview: {},
                    });
                  } else {
                    updateProductForm({
                      productPreview: imageData.file ? imageData : {},
                    });
                  }
                }}
              />

              {formValues.galleryImages
                ? formValues.galleryImages.map(image => {
                    return (
                      <ImageInput
                        value={image.image}
                        onChange={imageData =>
                          updateProductImagesArray(imageData)
                        }
                      />
                    );
                  })
                : ""}
              <ImageInput
                value={"null"}
                onChange={imageData => updateProductImagesArray(imageData)}
              />
            </div>
            <div className='gridCol2'>
              <ImageInput
                addTitle='Добавить фото-образ
            для примерки'
                value={formValues.productLook && formValues.productLook.image}
                onChange={(imageData = {}) => {
                  if (imageData === formValues.productLook.image) {
                    setProductRemovedImages([
                      formValues.productLook,
                      ...productRemovedImages,
                    ]);
                    console.log(productRemovedImages, 'productRemovedImages')
                    updateProductForm({
                      productLook: {},
                    });
                  } else {
                    updateProductForm({
                      productLook: imageData.file ? imageData : {},
                    });
                    console.log(formValues.productLook, 'productLookformValues.productLook')
                  }
                }}
              />
            </div>
          </div>

          <div className='controlsBlock'>
            <Button
              minWidth={360}
              filled={true}
              disabled={
                !formValues.name ||
                !formValues.description ||
                !formValues.productType ||
                !formValues.gender ||
                !formValues.category ||
                !formValues.shop ||
                !formValues.brand ||
                !formValues.material ||
                !formValues.color ||
                !formValues.sizeGrid ||
                !formValues.price
              }
              onPress={() => {
                isEdit
                  ? updateProduct({
                      id: productData.id,
                      name: formValues.name,
                      description: formValues.description,
                      itemType: formValues.productType.id,
                      gender: formValues.gender.id,
                      categoryId: formValues.category.id,
                      shopId: formValues.shop.id,
                      brandId: formValues.brand.id,
                      materialId: formValues.material.id,
                      colorId: formValues.color.id,
                      sizeGrid: formValues.sizeGrid.id,
                      price: formValues.price,
                      availableSizes: formValues.productSizes.map(sizes => new Object({size: sizes.id, url: sizes.url, languageCode: sizes.languageCode})),
                      shopSiteUrl: formValues.productUri,
                      productRemovedImages: productRemovedImages || [],
                      productAddededImages: productAddededImages || [],
                      productLookImage: formValues.productLook || {},
                      productPreview: formValues.productPreview || {},
                    })
                  : createProduct({
                      name: formValues.name,
                      description: formValues.description,
                      itemType: formValues.productType.id,
                      gender: formValues.gender.id,
                      categoryId: formValues.category.id,
                      shopId: formValues.shop.id,
                      brandId: formValues.brand.id,
                      materialId: formValues.material.id,
                      colorId: formValues.color.id,
                      sizeGrid: formValues.sizeGrid.id,
                      price: formValues.price,
                      availableSizes: formValues.productSizes.map(sizes => new Object({size: sizes.id, url: sizes.url, languageCode: sizes.languageCode})),
                      shopSiteUrl: formValues.productUri,
                      productAddededImages: productAddededImages || [],
                      productLookImage: formValues.productLook || {},
                      productPreview: formValues.productPreview || {},
                    });
                setProductRemovedImages([]);
                setProductAddededImages([]);
                onCloseModal();
              }}
            >
              Сохранить
            </Button>
            <div className='mt2'></div>
            <Button
              minWidth={360}
              onPress={() => {
                onCloseModal();
              }}
            >
              Отменить
            </Button>
          </div>
        </Form>
      </div>
    </Stepper>
  );
};

const mapStateToProps = ({ search }) => ({
  categories: search.categories,
  sizeTables: search.sizeTables,
  colors: search.colors,
  materials: search.materials,
  brands: search.brands,
  shops: search.shops,
});
const mapDispatchToProps = (
  dispatch,
  { apiService, searchValue, userRoleId },
) => {
  return bindActionCreators(
    {
      getSearchCategories: getSearchCategories(apiService, searchValue),
      getSearchSizes: getSearchSizes(apiService, searchValue),
      getSearchColors: getSearchColors(apiService, searchValue),
      getSearchMaterials: getSearchMaterials(apiService, searchValue),
      getSearchBrands: getSearchBrands(apiService, searchValue),
      getSearchShops: getSearchShops(apiService, searchValue, userRoleId),
    },
    dispatch,
  );
};

export default compose(
  withApiService(),
  connect(mapStateToProps, mapDispatchToProps),
)(EditOrAddProductForm);
