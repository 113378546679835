import React from "react";

import "./RemoveCategoryForm.scss";
import { Button, Form } from "../../../components/FormItems";

const RemoveCategoryForm = ({ onCloseModal, removeCategory }) => {
  return (
    <div className='RemoveCategoryForm'>
      <Form title='Удаление категории'>
        <div className='description mt2 mb2'>
          У этой категории есть дочерние категории, она будет удалена вместе с
          ними.
        </div>
        <Button
          filled={true}
          onPress={() => {
            removeCategory();
            onCloseModal();
          }}
        >
          Удалить категорию
        </Button>
        <div className='mt2'></div>
        <Button
          onPress={() => {
            onCloseModal();
          }}
        >
          Отменить
        </Button>
      </Form>
    </div>
  );
};

export default RemoveCategoryForm;
