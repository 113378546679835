import {
  getUsersOutsideRequest,
  updateUsersOutsideStatusRequest,
  getUsersOutsideFailure,
  updateUsersOutsideStatusFailure,
  getUsersOutsideSuccess,
  updateUsersOutsideStatusSuccess,
} from "./actionCreators";

const getUsersOutside = api => filter => dispatch => {
  dispatch(getUsersOutsideRequest());
  api
    .getUsersOutside(filter)
    .then(res => {
      console.log(res, "res getUsersOutside");
      dispatch(getUsersOutsideSuccess(res.data));
    })
    .catch(err => {
      console.log(err, "err getUsersOutside");
      dispatch(
        getUsersOutsideFailure({
          status: "error",
          text: `Возникла ошибка с кодом ${
            err.response ? err.response.status : "???"
          } `,
        }),
      );
    });
};

const toggleUsersOutsideStatus = api => (
  users = [],
  filter = {},
) => dispatch => {
  dispatch(updateUsersOutsideStatusRequest());

  api
    .toggleUsersOutside(users.map(user => user.id))
    .then(res => {
      console.log(res, "res toggleUsersOutside");
      dispatch(updateUsersOutsideStatusSuccess());

      getUsersOutside(api)(filter)(dispatch);
    })
    .catch(err => {
      console.log(err, "err toggleUsersOutside");
      dispatch(
        updateUsersOutsideStatusFailure({
          status: "error",
          text: `Возникла ошибка с кодом ${
            err.response ? err.response.status : "???"
          } `,
        }),
      );
    });
};

const exportUsersOutside = api => () => dispatch => {
  api
    .exportUsersOutside()
    .then(res => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = window.document.createElement("a");
      link.href = url;
      link.setAttribute("download", "users.xlsx");
      window.document.body.appendChild(link);
      link.click();
    })
    .catch(err => {
      console.log(err, "err exportUsersOutside");
    });
};
export { getUsersOutside, toggleUsersOutsideStatus, exportUsersOutside };
