import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { withApiService } from "../../components/hoc";
import compose from "../../helpers/compose";
import { handleLogin } from "../../redux/auth/actions";

import { Form, TextInput, Button } from "../../components/FormItems";
import { ReactComponent as Logo } from "../../assets/images/Logo.svg";
import showMessage from "../../components/showMessage";
import "./AuthPage.scss";

const AuthPage = ({ loading, error, handleLogin }) => {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (error) {
      console.log(error, "error");
      showMessage(error);
    }
  }, error);
  return (
    <div className='AuthPage'>
      <Logo />
      <Form title='Вход' width={430}>
        <div className='AuthPage__inputsBlock'>
          <TextInput
            type='text'
            name='login'
            placeholder='Логин'
            onChange={value => {
              setLogin(value);
            }}
            value={login}
          />
          <TextInput
            type='password'
            name='password'
            placeholder='Пароль'
            onChange={value => {
              setPassword(value);
            }}
            value={password}
          />
        </div>
        <Button
          filled={true}
          disabled={!(login && password)}
          onPress={() => {
            handleLogin({ login, password });
          }}
        >
          Далее
        </Button>
      </Form>
    </div>
  );
};

const mapStateToProps = ({ auth }) => ({
  loading: auth.loading,
  error: auth.error,
});
const mapDispatchToProps = (
  dispatch,
  { apiService, login, password, callback = () => {} },
) => {
  return bindActionCreators(
    {
      handleLogin: handleLogin(apiService, { login, password }, callback),
    },
    dispatch,
  );
};

export default compose(
  withApiService(),
  connect(mapStateToProps, mapDispatchToProps),
)(AuthPage);
