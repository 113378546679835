export const FETCH_GET_MODERATION_ARTICLES_REQUEST =
  "FETCH_GET_MODERATION_ARTICLES_REQUEST";
export const FETCH_GET_MODERATION_ARTICLES_FAILURE =
  "FETCH_GET_MODERATION_ARTICLES_FAILURE";
export const FETCH_GET_MODERATION_ARTICLES_SUCCESS =
  "FETCH_GET_MODERATION_ARTICLES_SUCCESS";

export const FETCH_REMOVE_MODERATION_ARTICLES_REQUEST =
  "FETCH_REMOVE_MODERATION_ARTICLES_REQUEST";
export const FETCH_REMOVE_MODERATION_ARTICLES_FAILURE =
  "FETCH_REMOVE_MODERATION_ARTICLES_FAILURE";
export const FETCH_REMOVE_MODERATION_ARTICLES_SUCCESS =
  "FETCH_REMOVE_MODERATION_ARTICLES_SUCCESS";

export const FETCH_CREATE_MODERATION_ARTICLE_REQUEST =
  "FETCH_CREATE_MODERATION_ARTICLE_REQUEST";
export const FETCH_CREATE_MODERATION_ARTICLE_FAILURE =
  "FETCH_CREATE_MODERATION_ARTICLE_FAILURE";
export const FETCH_CREATE_MODERATION_ARTICLE_SUCCESS =
  "FETCH_CREATE_MODERATION_ARTICLE_SUCCESS";

export const FETCH_UPDATE_MODERATION_ARTICLE_REQUEST =
  "FETCH_UPDATE_MODERATION_ARTICLE_REQUEST";
export const FETCH_UPDATE_MODERATION_ARTICLE_FAILURE =
  "FETCH_UPDATE_MODERATION_ARTICLE_FAILURE";
export const FETCH_UPDATE_MODERATION_ARTICLE_SUCCESS =
  "FETCH_UPDATE_MODERATION_ARTICLE_SUCCESS";

export const FETCH_MASS_UPDATE_MODERATION_ARTICLES_REQUEST =
  "FETCH_MASS_UPDATE_MODERATION_ARTICLES_REQUEST";
export const FETCH_MASS_UPDATE_MODERATION_ARTICLES_FAILURE =
  "FETCH_MASS_UPDATE_MODERATION_ARTICLES_FAILURE";
export const FETCH_MASS_UPDATE_MODERATION_ARTICLES_SUCCESS =
  "FETCH_MASS_UPDATE_MODERATION_ARTICLES_SUCCESS";
