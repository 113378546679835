import {
  getLanguagesRequest,
  getLanguagesFailure,
  getLanguagesSuccess,
  getTranslateRequest,
  getTranslateFailure,
  getTranslateSuccess,
  updateTranslateRequest,
  updateTranslateFailure,
  updateTranslateSuccess,
} from "./actionsCreators";

const getLanguages = api => () => dispatch => {
  dispatch(getLanguagesRequest());
  api
    .getLanguages()
    .then(res => {
      console.log(res, "res getLanguages");
      dispatch(getLanguagesSuccess(res.data));
    })
    .catch(err => {
      console.log(err, "err getLanguages");
      dispatch(getLanguagesFailure(err));
    });
};

const getTranslates = api => filter => dispatch => {
  dispatch(getTranslateRequest());
  api
    .getTranslations(filter)
    .then(res => {
      console.log(res, "res getTranslations");
      dispatch(getTranslateSuccess(res.data));
    })
    .catch(err => {
      console.log(err, "err getTranslations");
      dispatch(getTranslateFailure(err));
    });
};

const updateTranslates = api => translatesData => dispatch => {
  dispatch(updateTranslateRequest());
  api
    .updateTranslations(translatesData)
    .then(res => {
      console.log(res, "res updateTranslations");
      dispatch(updateTranslateSuccess(res.data));
    })
    .catch(err => {
      console.log(err, "err updateTranslations");
      dispatch(updateTranslateFailure(err));
    });
};

export { getLanguages, getTranslates, updateTranslates };
