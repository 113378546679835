import React, { useState, useEffect } from "react";

import "./EditArticleForm.scss";
import { SelectInput, Button } from "../../../components/FormItems";
import { mapComplaintStatusIdToName } from "../../../helpers/mapper";
import getFormattedDate from "../../../helpers/getFormattedDate";

const EditArticleForm = ({
  articleData = {},
  status = {},
  onCloseModal,
  updateComplaint,
}) => {
  const {
    id,
    images = [],
    products = [],
    name,
    author,
    updatedAt,
    tags,
    rating,
    text,
  } = articleData;

  const [editStatus, setEditStatus] = useState({
    id: status,
    name: mapComplaintStatusIdToName[status],
  });

  useEffect(() => {
    setEditStatus({
      id: status,
      name: mapComplaintStatusIdToName[status],
    });
  }, [articleData]);

  return (
    <div className='EditArticleForm'>
      {images && images.length > 0 ? (
        <div className='imagesBlock mb2'>
          {images.map(item => {
            return (
              <div
                className='largeImageWrapper'
                style={{
                  backgroundImage: item
                    ? `url(${window.location.origin + item.image})`
                    : "",
                }}
              ></div>
            );
          })}
        </div>
      ) : (
        ""
      )}

      <div className='gridRow2'>
        {products && products.length > 0
          ? products.map(item => {
              return item.images && item.images[0] ? (
                <div
                  className='smallImageWrapper'
                  style={{
                    backgroundImage: item
                      ? `url(${window.location.origin + item.images[0].image})`
                      : "",
                  }}
                ></div>
              ) : (
                ""
              );
            })
          : ""}
      </div>
      <div className='flexRow'>
        <div className='title'>{name}</div>
        <div className='flexRow'>
          <SelectInput
            width={220}
            placeholder='Статус'
            onSelect={value => {
              setEditStatus(value);
            }}
            selectedValue={editStatus}
            options={[
              { id: 0, name: "Новая" },
              { id: 1, name: "Принята" },
              { id: 2, name: "Отклонена" },
            ]}
          />
          <div className='date-text ml2'>
            {getFormattedDate(updatedAt / 1000)}
          </div>
        </div>
      </div>
      <div className='author'>{author ? author.nickname : ""}</div>
      <div className='text mt1'>{text}</div>
      <div className='likes mt2 mb1'>
        Оценка: <b>{rating}</b>
      </div>
      <div className='tags'>
        {tags ? tags.map(tag => <div className='tag'>{tag.name}</div>) : ""}
      </div>
      <div className='controlsBlock'>
        <Button
          minWidth={260}
          filled={true}
          disabled={editStatus.id === status}
          onPress={() => {
            updateComplaint({ id, status: editStatus.id });
            onCloseModal();
          }}
        >
          Сохранить
        </Button>
        <div className='mt2'></div>
        <Button
          minWidth={260}
          onPress={() => {
            onCloseModal();
          }}
        >
          Отменить
        </Button>
      </div>
    </div>
  );
};

export default EditArticleForm;
