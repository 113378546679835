import {
  getUsersInsideRequest,
  removeUsersInsideRequest,
  createUserInsideRequest,
  updateUserInsideRequest,
  getUsersInsideFailure,
  removeUsersInsideFailure,
  createUserInsideFailure,
  updateUserInsideFailure,
  getUsersInsideSuccess,
  removeUsersInsideSuccess,
  createUserInsideSuccess,
  updateUserInsideSuccess,
} from "./actionCreators";

const getUsersInside = api => filter => dispatch => {
  dispatch(getUsersInsideRequest());
  api
    .getUsersInside(filter)
    .then(res => {
      console.log(res, "res getUsersInside");
      dispatch(getUsersInsideSuccess(res.data));
    })
    .catch(err => {
      console.log(err, "err getUsersInside");
      dispatch(
        getUsersInsideFailure({
          status: "error",
          text: `Возникла ошибка с кодом ${
            err.response ? err.response.status : "???"
          } `,
        }),
      );
    });
};

const createUserInside = api => (userData, filter) => dispatch => {
  dispatch(createUserInsideRequest());

  let formattedUserData = {
    username: userData.username,
    password: userData.password,
    role: userData.role.id,
  };

  api
    .createUserInside(formattedUserData)
    .then(res => {
      console.log(res, "res createUserInside");
      dispatch(createUserInsideSuccess());

      getUsersInside(api)(filter)(dispatch);
    })
    .catch(err => {
      console.log(err, "err createUserInside");
      dispatch(
        createUserInsideFailure({
          status: "error",
          text: `Возникла ошибка с кодом ${
            err.response ? err.response.status : "???"
          } `,
        }),
      );
    });
};

const updateUserInside = api => (userData, filter) => dispatch => {
  dispatch(updateUserInsideRequest());

  let formattedUserData = {
    id: userData.id,
    username: userData.username,
    password: userData.password,
    role: userData.role.id,
  };

  api
    .updateUserInside(formattedUserData)
    .then(res => {
      console.log(res, "res updateUserInside");
      dispatch(updateUserInsideSuccess());

      getUsersInside(api)(filter)(dispatch);
    })
    .catch(err => {
      console.log(err, "err updateUserInside");
      dispatch(
        updateUserInsideFailure({
          status: "error",
          text: `Возникла ошибка с кодом ${
            err.response ? err.response.status : "???"
          } `,
        }),
      );
    });
};

const removeUsersInside = api => (users = [], filter) => dispatch => {
  dispatch(removeUsersInsideRequest());
  api
    .removeUsersInside(users.map(user => user.id))
    .then(res => {
      console.log(res, "res removeUsersInside");
      dispatch(removeUsersInsideSuccess());

      getUsersInside(api)(filter)(dispatch);
    })
    .catch(err => {
      console.log(err, "err removeUsersInside");
      dispatch(
        removeUsersInsideFailure({
          status: "error",
          text: `Возникла ошибка с кодом ${
            err.response ? err.response.status : "???"
          } `,
        }),
      );
    });
};

export {
  getUsersInside,
  createUserInside,
  updateUserInside,
  removeUsersInside,
};
