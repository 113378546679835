import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withApiService } from "../../../components/hoc";
import compose from "../../../helpers/compose";
import {
  getModerationLooks,
  updateModerationLook,
  massUpdateModerationLooks,
} from "../../../redux/moderationLooks/actions";

import "./ModerationLookPage.scss";
import {
  Button,
  CheckBox,
  Search,
  Select,
} from "../../../components/FormItems";
import { Table } from "../../../components/Tables";
import getFormattedDate from "../../../helpers/getFormattedDate";

import Modal from "../../../components/Modal";
import EditLookForm from "../EditLookForm";

const ModerationLookPage = ({
  moderationLooks,
  moderationLooksTotalCount,
  loading,
  getModerationLooks,
  updateModerationLook,
  massUpdateModerationLooks,
}) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [filter, setFilter] = useState({ limit: 10, offset: 0 });

  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedRowForEdit, setSelectedRowForEdit] = useState({});

  const [selectedStatus, setSelectedStatus] = useState({});

  useEffect(() => {
    getModerationLooks(filter);
  }, []);

  const updateFilter = changedFields => {
    setFilter(prevState => {
      return { ...prevState, ...changedFields };
    });
    getModerationLooks({ ...filter, ...changedFields });
  };
  const columns = [
    {
      title: "",
      dataIndex: "",
      width: "5%",
      render: rowData => {
        return (
          <CheckBox
            valuesList={selectedRows}
            onChange={selectedValues => {
              setSelectedRows(selectedValues);
            }}
            value={rowData}
          />
        );
      },
    },
    {
      title: "Образ",
      dataIndex: "name",
      width: "22%",
      sorter: true,
      render: rowData => {
        return (
          <span
            className='underline-text'
            onClick={() => {
              setSelectedRowForEdit(rowData);
              setShowEditModal(true);
            }}
          >
            {rowData.name}
          </span>
        );
      },
    },
    {
      title: "Автор",
      dataIndex: "nickname",
      width: "20%",
      sorter: true,
      render: rowData => {
        return rowData.author ? rowData.author.nickname : "";
      },
    },
    {
      title: "Дата присвоения статуса",
      dataIndex: "updatedAt",
      width: "20%",
      sorter: true,
      render: rowData => {
        return getFormattedDate(rowData.updatedAt / 1000);
      },
    },
    {
      title: "Статус",
      dataIndex: "status",
      width: "13%",
      sorter: true,
      render: rowData => {
        if (rowData.verificationStatus === 1) {
          return <span className='red-text'>Модерация</span>;
        } else if (rowData.verificationStatus === 2) {
          return <span className='green-text'>Одобрена</span>;
        } else {
          return <span className='red-text'>На редактировании</span>;
        }
      },
    },
  ];
  return (
    <div className='page-content'>
      <Modal
        maxWidth={900}
        onCloseModal={() => {
          setShowEditModal(false);
          setSelectedRowForEdit({});
        }}
        displayModal={showEditModal}
      >
        <EditLookForm
          lookData={selectedRowForEdit}
          updateModerationLook={lookData => {
            updateModerationLook(lookData, filter);
          }}
        />
      </Modal>

      <div className='table-filters mb2'>
        <Search
          placeholder='Имя автора образа'
          onSelect={value => {}}
          searchResults={[]}
          searchRequest={val => {
            updateFilter({ nickname: val });
          }}
          selectedValue={{}}
          requestStartSumbolIndex={0}
        />
        <div className='table-filters'>
          <Select
            placeholder='Статус'
            width={"200px"}
            onSelect={value => {
              setSelectedStatus(value);
            }}
            selectedValue={selectedStatus}
            options={[
              { id: 1, name: "Модерация" },
              { id: 2, name: "Одобрена" },
              { id: 3, name: "На редактировании" },
            ]}
          />
          <div className='mr2'></div>
          <Button
            filled={true}
            minHeight={40}
            minWidth={160}
            disabled={!(selectedRows.length > 0)}
            onPress={() => {
              massUpdateModerationLooks(
                selectedRows,
                selectedStatus.id,
                filter,
              );
              setSelectedRows([]);
            }}
          >
            Изменить статус
          </Button>
        </div>
      </div>
      <Table
        loading={loading}
        dataSource={moderationLooks}
        columns={columns}
        onClickRow={() => {}}
        highlightRow={rowData => {
          return selectedRows.findIndex(item => item.id === rowData.id) > -1;
        }}
        pagination={{
          total: moderationLooksTotalCount,
          offset: filter.offset,
          limit: filter.limit,
        }}
        onChange={(pagination, sorter) => {
          let changedFields = {
            ...pagination,
            ...sorter,
          };
          updateFilter(changedFields);
        }}
      />
    </div>
  );
};

const mapStateToProps = ({ moderationLooks }) => ({
  moderationLooks: moderationLooks.moderationLooks,
  moderationLooksTotalCount: moderationLooks.moderationLooksTotalCount,
  loading: moderationLooks.loading,
});
const mapDispatchToProps = (
  dispatch,
  { apiService, filter, moderationLookData, selectedModerationLooks, status },
) => {
  return bindActionCreators(
    {
      getModerationLooks: getModerationLooks(apiService, filter),
      updateModerationLook: updateModerationLook(
        apiService,
        moderationLookData,
        filter,
      ),
      massUpdateModerationLooks: massUpdateModerationLooks(
        apiService,
        selectedModerationLooks,
        status,
        filter,
      ),
    },
    dispatch,
  );
};

export default compose(
  withApiService(),
  connect(mapStateToProps, mapDispatchToProps),
)(ModerationLookPage);
