import React, { useState, useRef, useEffect } from "react";

import "./EditableTableCell.scss";

const EditableTableCell = ({
  editableColData = "",
  onChangeCell,
  highlight = false,
  withBorders = true,
  width = "180px",
}) => {
  const textareaLineHeight = 20;
  const textareaRef = useRef();
  const [textareaRows, setTextareaRows] = useState("1");

  useEffect(() => {
    if (textareaRef.current) {
      setTextareaRows(
        ~~(textareaRef.current.scrollHeight / textareaLineHeight),
      );
    }
  }, [textareaRef]);

  return (
    <td
      className={highlight ? "highlight" : ""}
      style={withBorders ? { width } : { border: "0", width }}
    >
      <textarea
        ref={textareaRef}
        className='EditableCell'
        style={withBorders ? {} : { border: "0" }}
        value={editableColData}
        rows={textareaRows}
        onChange={e => {
          const minRows = 1;
          const maxRows = 10;
          const previousRows = e.target.rows;

          e.target.rows = minRows; // reset rows

          const currentRows = ~~(e.target.scrollHeight / textareaLineHeight);

          if (currentRows === previousRows) {
            e.target.rows = currentRows;
          }

          if (currentRows >= maxRows) {
            e.target.rows = maxRows;
            e.target.scrollTop = e.target.scrollHeight;
          }

          setTextareaRows(currentRows < maxRows ? currentRows : maxRows);
          onChangeCell(e.target.value);
        }}
      />
    </td>
  );
};

export default EditableTableCell;
